import { TriageResponse } from './../triage/triageResponse.model';
import { BusinessCaseResponse } from './../authorization/businessCaseResponse.model';
import { Serializable } from '../deserialize.service';
import { Triage } from '../triage/triage.model';

export class AuthorityCases implements Serializable<AuthorityCases> {
    public id: number;
    public authorityId: string;
    public created: Date;
    public appUserId: number;
    public appUserName: string;
    public cases: BusinessCaseResponse[];
    public triage: Triage[];
    public caseId: string;

    deserialize(input: any) {
        this.id = input.id;
        this.authorityId = input.authorityId;
        this.created = input.created;
        this.appUserId = input.appUserId;
        this.appUserName = input.appUserName;
        this.cases = input.cases;
        this.triage = input.triages;
        this.caseId = input.caseId;
        return this;
    }
}
