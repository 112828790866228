import { HomeComponent } from './../../home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TphNewUserComponent } from './tph-new-user/tph-new-user.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxPermissionsModule } from 'ngx-permissions';
// import { LayoutModule } from './../../../layouts/layout.module';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelephonyComponent } from './telephony.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: '',
                component: TelephonyComponent,
            },
        ],
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        // LayoutModule,
        // NgxPermissionsModule,
        DragDropModule,
        // ComponentModule,
        // ChartsModule,
        // NgSelectModule,
        // NgbModule,
        FormsModule,
        ReactiveFormsModule,
        // SharedModule,
    ],
    declarations: [TelephonyComponent, TphNewUserComponent],
    exports: [TelephonyComponent],
})
export class TelephonyModule {}
