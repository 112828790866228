import { DocumentSource } from './../../../../shared/enums/documentSource.enum';
import { NotificationOrigin } from './../../../../shared/enums/notificationOrigin.enum';
import { TriageService } from './../../../../shared/services/triage.service';
import { HelperService } from './../../../../shared/services/helper.service';
import { NotificationsService } from './../../../../shared/services/notifications.service';
import { StorageService } from './../../../../shared/services/storage.service';
import { AuthorizationService } from './../../../../shared/services/authorization.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { map, takeUntil } from 'rxjs/operators';
import { merge, Subject, concat } from 'rxjs';
import _ from 'lodash';

@Component({
    selector: 'app-history-popup',
    templateUrl: './history-popup.component.html',
    styleUrls: ['./history-popup.component.scss'],
})
export class HistoryPopupComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        public authRest: AuthorizationService,
        public storage: StorageService,
        public notificationsService: NotificationsService,
        public caseRest: AuthorizationService,
        public helper: HelperService,
        public triageRest: TriageService,
    ) {}
    public timeline: any[] = [];
    public caseId: string;
    public triageId: number;
    public type: 'CASE' | 'TRIAGE' | 'CASE_FROM_TRIAGE';
    public loading: boolean = true;
    private onDestroy$ = new Subject<void>();

    ngOnInit() {
        let tmp = [];
        let bsId = this.storage.getBusinessSubject().id;
        //glede na type definiraš observable od katerega boš dobil timeline podatke
        let observable = this.setGetFunctions(this.type, bsId);

        observable
            .pipe(
                map((res) => {
                    if (_.isNil(res)) {
                        res = [];
                    }
                    tmp.push(res);
                    this.loading = false;
                    return (tmp = _.flatten(tmp));
                }, takeUntil(this.onDestroy$)),
            )
            .subscribe(
                (res) => {
                    this.timeline = res;
                },
                (err) => {
                    this.sortByDates();
                },
                () => {
                    this.sortByDates();
                },
            );
    }
    public sortByDates() {
        let result;
        let dateTypes = ['eventDate', 'commentAdded', 'documentAdded', 'sentTime'];
        return (this.timeline = this.timeline.sort(function(a, b) {
            dateTypes.forEach((type) => {
                if (!_.isNil(a[type])) {
                    dateTypes.forEach((type2) => {
                        if (!_.isNil(b[type2])) {
                            result = moment(a[type]).isBefore(moment(b[type2])) ? 1 : -1;
                        }
                    });
                }
            });
            return result ? result : 0;
        }));
    }
    public setGetFunctions(type: 'CASE' | 'TRIAGE' | 'CASE_FROM_TRIAGE', bsId: number) {
        //klici za avtorizacijo
        const caseCalls = merge(
            this.authRest.getCaseHistoryLog(bsId, this.caseId).pipe(
                map((data) => {
                    return data
                        ? data.map((el) => {
                              el.origin = NotificationOrigin.HISTORYLOGS;
                              return el;
                          })
                        : [];
                }),
            ),
            this.caseRest.getCaseContractorComments(bsId, this.caseId).pipe(
                map((data) => {
                    return data
                        ? data.map((el) => {
                              el.origin = NotificationOrigin.CASECONTRACTORCOMMENTS;
                              return el;
                          })
                        : [];
                }),
            ),
            this.caseRest.getCaseContractorDocuments(bsId, this.caseId).pipe(
                map((data) => {
                    return data
                        ? data.map((el) => {
                              el.origin = NotificationOrigin.CASECONTRACTORDOCUMENTS;
                              return el;
                          })
                        : [];
                }),
            ),
            this.caseRest.getCaseComments(bsId, this.caseId).pipe(
                map((data) => {
                    return data
                        ? data.map((el) => {
                              el.origin = NotificationOrigin.CASECOMMENTS;
                              return el;
                          })
                        : [];
                }),
            ),
            this.caseRest.getCaseDocuments(bsId, this.caseId).pipe(
                map((data) => {
                    return data
                        ? data.map((el) => {
                              el.origin = NotificationOrigin.CASEDOCUMENTS;
                              return el;
                          })
                        : [];
                }),
            ),
            this.caseRest.getCaseCustomerDocuments(bsId, this.caseId).pipe(
                map((data) => {
                    data = data.filter((doc) => doc.documentSource != DocumentSource.NETWORK);
                    return data
                        ? data.map((el) => {
                              el.origin = NotificationOrigin.CASECUSTOMERDOCUMENTS;
                              return el;
                          })
                        : [];
                }),
            ),
            this.authRest
                .getCaseTriageCommunication(bsId, {
                    caseId: this.caseId,
                })
                .pipe(
                    map((data) => {
                        return data
                            ? data.map((el) => {
                                  el.origin = NotificationOrigin.AUTHORIZATIONCOMMUNICATION;
                                  return el;
                              })
                            : [];
                    }),
                ),
        );
        //klici za eTriažo
        const triageCalls = merge(
            this.triageRest.getTriageHistoryLog(bsId, this.triageId).pipe(
                map((data) => {
                    return data
                        ? data.map((el) => {
                              el.origin = NotificationOrigin.TRIAGEHISTORYLOG;
                              return el;
                          })
                        : [];
                }),
            ),
            this.triageRest.getTriageContractorDocuments(bsId, this.triageId).pipe(
                map((data) => {
                    return data
                        ? data.map((el) => {
                              el.origin = NotificationOrigin.TRIAGECONTRACTORDOCUMENTS;
                              return el;
                          })
                        : [];
                }),
            ),
            this.triageRest.getTriageDocuments(bsId, this.triageId).pipe(
                map((data) => {
                    return data
                        ? data.map((el) => {
                              el.origin = NotificationOrigin.TRIAGEDOCUMENTS;
                              return el;
                          })
                        : [];
                }),
            ),
            this.triageRest.getTriageContractorComments(bsId, this.triageId).pipe(
                map((data) => {
                    return data
                        ? data.map((el) => {
                              el.origin = NotificationOrigin.TRIAGECONTRACTORCOMMENTS;
                              return el;
                          })
                        : [];
                }),
            ),
            this.triageRest.getTriageComments(bsId, this.triageId).pipe(
                map((data) => {
                    return data
                        ? data.map((el) => {
                              el.origin = NotificationOrigin.TRIAGECOMMENTS;
                              return el;
                          })
                        : [];
                }),
            ),
            this.authRest
                .getCaseTriageCommunication(bsId, {
                    triageId: this.triageId,
                })
                .pipe(
                    map((data) => {
                        return data
                            ? data.map((el) => {
                                  el.origin = NotificationOrigin.TRIAGECOMMUNICATION;
                                  return el;
                              })
                            : [];
                    }),
                ),
        );
        //define observable
        switch (type) {
            case 'TRIAGE':
                return triageCalls;
            case 'CASE':
                return caseCalls;
            case 'CASE_FROM_TRIAGE':
                return concat(triageCalls, caseCalls);
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
