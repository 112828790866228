<!-- begin::Header -->
<header
    class="m-grid__item m-header"
    data-minimize="minimize"
    data-minimize-offset="200"
    data-minimize-mobile-offset="200"
    appunwraptag=""
>
    <div class="m-container m-container--responsive m-container--fluid m-container--full-height">
        <div class="m-stack m-stack--ver m-stack--desktop  m-header__wrapper">
            <!-- begin::Brand -->
            <div class="m-stack__item m-brand m-brand--mobile">
                <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                    <div class="m-stack__item m-stack__item--middle m-brand__logo">
                        <a routerLink="/customers" class="m-brand__logo-wrapper">
                            <img alt="" src="assets/app/media/img/logos/logo-white.svg" width="205px"/>
                        </a>
                    </div>
                </div>
            </div>
            <!-- end::Brand -->
            <!-- begin::Topbar -->

            <div
                id="m_header_menu"
                class="m-header-menu m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-light m-aside-header-menu-mobile--submenu-skin-light"
            >
                <ul class="m-menu__nav  m-menu__nav--submenu-arrow">
                    <li
                        *ngFor="let menu of mainMenu"
                        class="m-menu__item  "
                        routerLinkActive="m-menu__item--active"
                        [routerLinkActiveOptions]="{ exact: false }"
                        aria-haspopup="true"
                    >
                        <a routerLink="{{ menu?.link }}" class="m-menu__link" *ngIf="menu.title !== 'x'">
                            <span class="m-menu__item-here"></span>
                            <span class="m-menu__link-text">
                                <span class="text-nowrap">{{ menu?.title }}</span>
                            </span>
                        </a>
                        <a routerLink="{{ menu.link }}" class="m-menu__link" *ngIf="menu?.title === 'x'">
                            <span class="m-menu__item-here"></span>
                            <span class="m-menu__link-text">
                                <i class="la la-arrow-left" style="font-size: 3rem" *ngIf="menu?.title === 'x'"></i>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">
                    <div *ngIf="!isProduction" class="">
                        <span class="mf-notificiation-of-test-instance"><span>Testna</span><br/>instanca</span>
                    </div>
                    <div class="m-stack__item m-topbar__nav-wrapper">
                        <ul class="m-topbar__nav m-nav m-nav--inline">
                            <li
                                class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width"
                                *ngIf="isTelephonyModule && checkPermission('telephony_isVisible')"
                            >
                                <a class="m-nav__link m-dropdown__toggle" (click)="openPhone()">
                                    <span class="m-nav__link-icon">
                                        <span class="m-nav__link-icon-wrapper">
                                            <i class="la la-phone"></i>
                                        </span>
                                    </span>
                                </a>
                            </li>
                            <li
                                class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width"
                                routerLinkActive="m-menu__item--active"
                                [routerLinkActiveOptions]="{ exact: false }"
                            >
                                <a class="m-nav__link m-dropdown__toggle" [routerLink]="['/settings']">
                                    <span class="m-nav__link-icon">
                                        <span class="m-nav__link-icon-wrapper">
                                            <i class="la la-gear"></i>
                                        </span>
                                    </span>
                                </a>
                            </li>
                            <li
                                *ngIf=" checkPermission('notifications_isVisible')"
                                head-header-notifications
                                class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width"
                                data-dropdown-toggle="click"
                                data-dropdown-persistent="true"
                            ></li>
                            <li
                                class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                                data-dropdown-toggle="click"
                            >
                                <a href="#" class="m-nav__link m-dropdown__toggle">
                                    <div class="m-topbar__username">
                                        <div
                                            class="btn btn-sm m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill"
                                        >
                                            {{ shortName }}
                                        </div>
                                    </div>
                                    <i class="m-nav__link-arrow la la-angle-down"></i>
                                </a>
                                <div class="m-dropdown__wrapper">
                                    <span
                                        class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
                                    ></span>
                                    <div class="m-dropdown__inner">
                                        <div class="m-dropdown__header m--align-center">
                                            <div class="m-card-user m-card-user--skin-dark">
                                                <div class="m-card-user__pic">
                                                    <i class="la la-male m--font-light" style="font-size: 40px"></i>
                                                    <!--                                                         <img src="./assets/app/media/img/users/user4.jpg" class="m&#45;&#45;img-rounded m&#45;&#45;marginless" alt="" /> -->
                                                </div>
                                                <div class="m-card-user__details">
                                                    <span class="m-card-user__name m--font-weight-500">
                                                        {{ loggedingUser?.name }}
                                                    </span>
                                                    <div class="mt-1">
                                                        <span>
                                                            {{ storage?.getBusinessSubject()?.name }}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span *ngIf="roles[0] === 'ROLE_BOOKING'">Uporabnik</span>
                                                        <span *ngIf="roles[0] === 'ROLE_BOOKING_MASTER'">Skrbnik</span>
                                                    </div>
                                                    <!-- <div href="" class="m-card-user__email m--font-weight-300 m-link" *ngFor="let role of userRoles">
                                                        {{translateRole[role]}} &nbsp;
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-dropdown__body">
                                            <div class="m-dropdown__content">
                                                <ul class="m-nav m-nav--skin-light">
                                                    <!-- <li class="m-nav__section m--hide">
                                                        <span class="m-nav__section-text">
                                                            Section
                                                        </span>
                                                    </li> -->
                                                    <!-- <li class="m-nav__item">
                                                        <a routerLink="/settings/profile" class="m-nav__link">
                                                            <i class="m-nav__link-icon la la-cog"></i>
                                                            <span class="m-nav__link-title">
                                                                <span class="m-nav__link-wrap">
                                                                    <span class="m-nav__link-text">
                                                                        Nastavitve
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </li> -->
                                                    <li class="m-nav__item">
                                                        <a
                                                            href="https://podpora.medifit.si/asistent"
                                                            target="_blank"
                                                            class="m-nav__link"
                                                        >
                                                            <i class="m-nav__link-icon la la-external-link"></i>
                                                            <span class="m-nav__link-title">
                                                                <span class="m-nav__link-wrap">
                                                                    <span
                                                                        class="m-nav__link-text"
                                                                        i18n="@@8866640254992384"
                                                                    >
                                                                        Pomoč
                                                                    </span>
                                                                    <!-- <span class="m-nav__link-badge">
                                                                                <span class="m-badge m-badge--success">
                                                                                    2
                                                                                </span>
                                                                            </span> -->
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </li>

                                                    <li class="m-nav__item">
                                                        <a
                                                            href="https://www.medifit.si/dokumenti/splosni-pogoji-aplikacije-asistent/"
                                                            target="_blank"
                                                            class="m-nav__link"
                                                        >
                                                            <i class="m-nav__link-icon la la-external-link"></i>
                                                            <span class="m-nav__link-title">
                                                                <span class="m-nav__link-wrap">
                                                                    <span class="m-nav__link-text" i18n>
                                                                        Splošni pogoji
                                                                    </span>
                                                                    <!-- <span class="m-nav__link-badge">
                                                                                <span class="m-badge m-badge--success">
                                                                                    2
                                                                                </span>
                                                                            </span> -->
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </li>

                                                    <li class="m-nav__separator m-nav__separator--fit"></li>
                                                    <li class="m-nav__item">
                                                        <a
                                                            routerLink="/snippets/pages/user/login-1"
                                                            class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                                            routerLink="/logout"
                                                            i18n="@@567884c3-0fc6-5330-9c73-1a8b6afe3920"
                                                            (click)="logout()"
                                                        >
                                                            Odjava
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="m-dropdown__footer border-top py-3 border-light">
                                            <small>
                                                2022 &copy; asistent by
                                                <a href="https://medifit.si" class="m-link">
                                                    Medifit
                                                </a>
                                                v{{ version }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- end::Topbar -->
        </div>
    </div>
</header>
<!-- end::Header -->
