import { Serializable } from '../deserialize.service';
import { Triage } from '../triage/triage.model';
import { Comment } from '../comment.model';
import { Document } from '../document.model';
import { BusinessUserService } from '../../services/business-user.service';
import { ServiceBUContractor } from '../service/serviceBUContractor.model';

export class AuthorityCaseTriage extends Triage implements Serializable<AuthorityCaseTriage> {
    public comments: Comment[];
    public documents: Document[];
    public contractorComments: Comment[];
    public contractorDocuments: Document[];

    public bsService: ServiceBUContractor;

    deserialize(input: any) {
        this.created = input.created;
        this.id = input.id;
        this.caseAuthority = input.caseAuthority;
        this.contractor = input.contractor;
        this.customer = input.customer;
        this.canceledTime = input.canceledTime;
        this.concludedTime = input.concludedTime;
        this.appUserName = input.appUserName;
        this.status = input.status;
        this.serviceId = input.serviceId;
        this.serviceName = input.serviceName;
        this.price = input.price;

        if (input.comments) {
            this.comments = input.comments.map((comment) => {
                return new Comment().deserialize(comment);
            });
        }

        if (input.documents) {
            this.documents = input.documents.map((document) => {
                return new Document().deserialize(document);
            });
        }

        if (input.contractorComments) {
            this.contractorComments = input.contractorComments.map((comment) => {
                return new Comment().deserialize(comment);
            });
        }

        if (input.contractorDocuments) {
            this.contractorDocuments = input.contractorDocuments.map((document) => {
                return new Document().deserialize(document);
            });
        }

        if (input.bsService) {
            this.bsService = new ServiceBUContractor().deserialize(input.bsService);
        }
        return this;
    }
}
