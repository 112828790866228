import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { ClearState, SetEnableBooking } from '../_actions/base.actions';

export class BaseStateModel {
    enabledBooking: boolean;
}
const baseStateDefaults: BaseStateModel = {
    enabledBooking: true,
};

@State<BaseStateModel>({
    name: 'baseState',
    defaults: baseStateDefaults,
})
@Injectable({
    providedIn: 'root',
})
export class BaseState implements NgxsOnInit {
    constructor() {}

    ngxsOnInit(ctx: StateContext<BaseStateModel>) {
        console.log('Base state INIT');
    }
    @Selector()
    static getEnabledBooking(state: BaseStateModel): boolean {
        return state.enabledBooking;
    }
    @Action(SetEnableBooking)
    SetEnableBooking({ patchState }: StateContext<BaseStateModel>, { enabledBooking }: SetEnableBooking) {
        patchState({
            enabledBooking: enabledBooking,
        });
    }

    @Action(ClearState)
    public ClearState(ctx: StateContext<BaseStateModel>, {}: ClearState) {
        ctx.patchState(baseStateDefaults);
    }
}
