import { AuthorityCase } from './../authorityCase/authorityCase.model';
import { Slot } from './../slot.model';
import { PreReservation } from './../preReservation.model';
import { Contractor } from './../contractor.model';
import { Serializable } from '../deserialize.service';
import { Customer } from '../customer.model';
import { Service } from '../service/service.model';
import { User } from '../user.model';
import * as _ from 'lodash';
import { AuthorityType } from '../../enums/authorityType.enum';
import { AuthorityCaseStatus } from '../../enums/authorityCaseStatus.enum';
import { Reservation } from '../reservation.model';
import { ReservationStatus } from './reservationStatus.model';

export class AuthorizationOrder implements Serializable<AuthorizationOrder> {
    caseId: string;
    triageId: number;
    user: User;
    customer: Customer;
    services: Service[];
    numOfVisits: number;
    orderedServices: Service[];
    contractor: Contractor;
    caseDate: Date;
    selectedSeparatedDate: Date;
    canceled: boolean;
    coveragePercentage: number;
    coverageValue: number;
    preReservation: PreReservation;
    slot: Slot;
    authorityType: AuthorityType;
    caseAuthority: AuthorityCase;
    caseStatuses: ReservationStatus[];
    appUserName: string;
    status: string;
    coveragePrice: number;
    totalPrice: number;
    customerPrice: number;
    customerPercent: number;
    contractorData: any;
    waitingQueue: boolean;
    originalService: Service;
    authFromTriageData: any;
    externalBsUserId: string;
    maxServices: number;
    separatedConcluded: boolean;
    additionalData: any;
    claimFile: string;

    deserialize(input) {
        // debugger;
        if (input.caseAuthority) {
            this.caseAuthority = new AuthorityCase().deserialize(input.caseAuthority);
        }

        if (input.slot) {
            this.slot = input.slot;
        }
        if (input.caseId) {
            this.caseId = input.caseId;
        }

        if (input.triageId) {
            this.triageId = input.triageId;
        }

        // this.contractorId = input.contractorId;
        if (input.customer) {
            this.customer = new Customer().deserialize(input.customer);
        }
        if (input.services) {
            this.services = input.services.map((el) => new Service().deserialize(el));
        }
        this.numOfVisits = input.numOfVisits;
        if (input.user) {
            this.user = new User().deserialize(input.user);
        }

        if (input.orderedServices) {
            this.orderedServices = input.orderedServices.map((el) => new Service().deserialize(el));
        } else {
            this.orderedServices = [];
        }

        // if (_.get(input, 'contractor.id', 0) !== 0) {
        if (!_.isNil(input.contractor)) {
            this.contractor = new Contractor().deserialize(input.contractor);
        }
        if (!_.isNil(input.contractorData)) {
            this.contractorData = input.contractorData;
        }
        if (!_.isNil(input.waitingQueue)) {
            this.waitingQueue = input.waitingQueue;
        }
        if (!_.isNil(input.authFromTriageData)) {
            this.authFromTriageData = input.authFromTriageData;
        }

        if (input.caseDate) {
            this.caseDate = new Date(input.caseDate);
        }

        if (input.selectedSeparatedDate) {
            this.selectedSeparatedDate = new Date(input.selectedSeparatedDate);
        }

        if (input.preReservation) {
            this.preReservation = new PreReservation().deserialize(input.preReservation);
        }
        this.originalService = input.originalService;
        this.canceled = input.canceled;
        this.coveragePercentage = input.coveragePercentage;

        this.coveragePrice = input.coveragePrice;
        this.totalPrice = input.totalPrice;
        this.customerPrice = input.customerPrice;
        this.customerPercent = input.customerPercent;
        this.separatedConcluded = input.separatedConcluded;

        this.coverageValue = input.coveragePercentage;
        this.authorityType = input.authorityType;
        this.selectedSeparatedDate = input.selectedSeparatedDate;

        if (input.caseStatuses) {
            this.caseStatuses = input.caseStatuses.map((el) => new ReservationStatus().deserialize(el));
        }

        this.appUserName = input.appUserName;
        this.status = input.status;
        this.externalBsUserId = input.externalBsUserId;
        this.maxServices = input.maxServices; // ce pride iz urlja, kjer je ze vnaprej doloceno stevilo storitev oz. terminov

        if (input.additionalData) {
            this.additionalData = input.additionalData;
        }
        this.claimFile = input.claimFile;

        return this;
    }

    // public getAuthorizationRequest(): AuthorizationRequest {
    //     debugger;
    //     return new AuthorizationRequest().deserialize({
    //         externalBsUserId: this.user.id,
    //         case_id: this.caseId,
    //         customer: this.customer,
    //         service: this.services
    //     });
    // }
}
