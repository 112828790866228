import { AuthorityCases } from './../model/authorityCase/authorityCases.model';
import { HttpClientService } from './http-client.service';
import { HelperService } from './helper.service';
import { Observable } from 'rxjs';
import { AuthorityCaseRequest } from './../model/authorityCase/authorityCaseRequest.model';
import { Injectable } from '@angular/core';
import { AuthorityCase, Comment } from '../model';
import { AuthorityType } from '../enums/authorityType.enum';
import { AuthorizationService } from './authorization.service';
import { TriageService } from './triage.service';
import { AuthorizationTriage } from '../model/authorityCase/authorizationTriage.model';

@Injectable({
    providedIn: 'root',
})
export class AuthorityCaseService {
    constructor(private helper: HelperService, private httpClient: HttpClientService) {}

    //createCaseAuthority()
    public createCaseAuthority(bsid: number, caseAuthority: AuthorityCaseRequest): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/caseauthority`, caseAuthority);
    }

    //getAuthorityCases()
    public getAuthorityCases(bsid: number): Observable<AuthorityCases> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/authoritycases`);
    }

    public getAuthorityCasesDetails(bsid: number, authorityId: number): Observable<AuthorityCases> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/authority/${authorityId}/cases`);
    }

    public getAuthorizationsTriages(
        bsid: number,
        limit: number = 30,
        page: number = 0,
        orderBy: 'date' | 'caseid' | 'triageid' | 'customer' | 'contractor' | 'service' | 'appuser' = 'date',
        orderDirection: 0 | 1 = 1,
        search: {
            id?: string;
            customer?: string;
            service?: string;
            contractor?: string;
            'app-user'?: string;
        } = {},
        customerId: number
    ): Observable<{ result: AuthorizationTriage[]; resultSize: number }> {
        const offset = page * limit;

        let order = orderDirection === 0 ? '+' : '-';
        order += orderBy;
        order = encodeURIComponent(order);

        let searchString = '';

        for (const searchKey in search) {
            if (search[searchKey]) {
                searchString += '&' + searchKey + '=' + encodeURIComponent(search[searchKey]);
            }
        }
        let dataUrl = this.helper.getAssistanceUrl(2) +
        `/bs/${bsid}/cases-triages?limit=${limit}&offset=${offset}&order=${order}${searchString}`;
        if(customerId) dataUrl += `&customerid=${customerId}`

        return this.httpClient.get(dataUrl);
    }
}
