import { ConfirmPrereservations } from './../model/authorityCase/confirmPrereservations.model';
import { PreReservationId } from './../model/authorityCase/preReservationId.model';
import { UpdatePrereservationRequest } from './../model/authorityCase/updatePrereservationRequest.model';
import { AddToWaitingQueueRequest, PreReservation } from './../model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ReservationService {
    constructor(private helper: HelperService, private httpClient: HttpClientService) {}

    //eA
    public confirmPrereservations(data: ConfirmPrereservations): Observable<any> {
        return this.httpClient.post(this.helper.getErpUrl() + `/confirmPrereservations`, data);
    }

    public sendPreResevation(data: any, bpi: string): Observable<any> {
        return this.httpClient.post(this.helper.getErpUrl() + `/prereservation/bpi/${bpi}`, data);
    }

    public cancelPreResevation(preres: PreReservation): Observable<any> {
        return this.httpClient.delete(
            this.helper.getErpUrl() + '/prereservation/' + preres.aggregateId + '/' + preres.prereservationId,
        );
    }

    //X21
    public sendResevation(p: PreReservation, bpi: string): Observable<any> {
        return this.httpClient.post(this.helper.getErpUrl() + `/reservation/bpi/${bpi}`, p);
    }

    public updateReservation(prereservation: string, data: any, type?: string): Observable<any> {
        let dataUrl = this.helper.getErpUrl() + '/reservations/' + prereservation;
        if (type) {
            dataUrl += `/bpi/${type}`;
        }
        return this.httpClient.put(dataUrl, data);
    }
    public updatePrereservation(bpi: string, data: UpdatePrereservationRequest): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + `/updatePrereservation/bpi/${bpi}`, data);
    }

    //addServicesToWaitingQueue()

    public addServicesToWaitingQueue(data: AddToWaitingQueueRequest): Observable<any> {
        if (_.has(data, 'contractor.bpiType')) {
            if (data.customer.medifitId) {
                data.customer.id = data.customer.medifitId + '';
            }
            return this.httpClient.post(this.helper.getErpUrl() + `/waitingqueue/bpi/${data.contractor.bpiType}`, data);
        } else {
            return this.httpClient.post(this.helper.getErpUrl() + `/waitingqueue`, data);
        }
    }
}
