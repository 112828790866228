import { HistoryLogDetails } from './history-log-details.model';
import { Serializable } from './deserialize.service';
import * as moment from 'moment';

export class HistoryLog implements Serializable<HistoryLog> {
    bsId: number;
    caseId: string;
    triageId: number;
    eventDate: moment.Moment;
    eventType: string;
    createdBy: string;
    prereservationId: string;
    description: HistoryLogDetails;
    createdByName: string;
    origin: string;
    confirmedByName: string;
    isTwoFA: boolean;

    deserialize(input) {
        this.bsId = input.bsId;
        this.caseId = input.caseId;
        this.triageId = input.triageId;
        this.eventDate = moment(input.eventDate);
        this.eventType = input.eventType;
        this.createdBy = input.createdBy;
        this.prereservationId = input.prereservationId;
        this.description = input.description;
        this.createdByName = input.createdByName;
        this.origin = input.origin;
        this.confirmedByName = input.confirmedByName;
        this.isTwoFA = input.isTwoFA;
        return this;
    }
}
