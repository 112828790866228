import { HttpHeaders } from '@angular/common/http';
import { BsGuiSettings } from './../model/guiSettings/bsGuiSettings.model';
import { GuiSettings } from './../model/guiSettings/guiSettings.model';
import { StorageService } from './storage.service';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
        private storage: StorageService,
        private readonly location: Location,
    ) {}

    public getGlobalSettings(): Observable<any> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + '/app/settings/');
    }

    public getBSGuiSettings(bsid: number): Observable<BsGuiSettings> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/app/settings/bs/${bsid}`);
    }

    public getUserGuiSettings(bsid: number): Observable<GuiSettings> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/app/settings/${bsid}`);
    }

    public updateGuiSettings(bsid: number, guiSettings: GuiSettings): boolean {
        let res: boolean = true;
        this.httpClient.put(this.helper.getAssistanceUrl(2) + `/app/settings/${bsid}`, guiSettings).subscribe(
            () => {
                this.storage.setGuiSettings(guiSettings);
                res = true;
            },
            () => {
                res = false;
            },
        );
        return res;
    }

    //changePwd()
    public changePwd(data: any): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + '/user/pwd', data);
    }

    public sendMessageToSlack(slackObj: any): void {
        this.httpClient
            .post(this.helper.getAssistanceUrl(2) + `/notifications/monitoring/hischeck`, slackObj)
            .subscribe();
    }
    public setUserPricesListsNotifications(value: boolean, bsid): Observable<any> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl(3) + `/bs/${bsid}/pricelistchangednotification/${value}`,
        );
    }
    public getUserPriceListsNotificationsEnabled(bsid): Observable<any> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/bs/${bsid}/pricelistchangednotification`);
    }

    public auditTrace(bsid, payload?: string): Observable<any> {
        if (!payload) {
            payload = this.location.path();
        }
        return this.httpClient.post(this.helper.getRootUrl(3) + `/revision/api/bs/${bsid}`, payload, {
            // responseType: 'text',
            // contentType: 'application/json',
            headers: new HttpHeaders().set('Content-Type', 'application/json'),
        });
    }
}
