import { Serializable } from './deserialize.service';
import { NotificationEnum } from '../enums/notification.enum';

export class NotificationData implements Serializable<NotificationData> {
    //
    bsId?: number;
    caseId?: string;
    triageId?: string;
    caseInternalId?: string;
    contractorName?: string;
    newFrom?: number;
    type?: NotificationEnum;
    appUserName?: string;
    comment?: string;
    notificationId?: number;

    deserialize(input) {
        // debugger;
        this.bsId = parseInt(input.bsId);
        this.caseId = input.caseId;
        this.caseInternalId = input.caseInternalId;
        this.contractorName = input.contractorName;
        this.newFrom = input.newFrom;
        this.type = input.type;
        this.appUserName = input.appUserName;
        this.comment = input.comment;
        this.notificationId = input.notificationId;
        return this;
    }
}

// channel:"EMAIL"
// customerId:43
// customerName:"Nina Test"
// description:"Obvestilo o rezervaciji"
// queueId:"01ab4f1b-cf1d-42d4-b680-de6949bb6faf"
// sentTime:"2018-03-21T12:14:04.265"
// status:"SENT"
