<div
    class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2"
    id="m_login"
    style="background-image: url(./assets/app/media/img//bg/bg-3.jpg);"
    appunwraptag=""
>
    <div class="m-grid__item m-grid__item--fluid	m-login__wrapper">
        <div class="m-login__container">
            <div class="m-login__logo">
                <a href="#">
                    <img src="./assets/app/media/img/logos/logo-white.svg" width="300" />
                    <!-- christmas -->
                    <!-- <img src="./assets/app/media/img/logos/logo-white-c.svg" width="300" /> -->
                </a>
            </div>
            <div *ngIf="!isProduction" style="text-align: center;color:#FDB737;font-size: 2rem;font-weight: bold">
                TESTNA INSTANCA
            </div>
            <div class="m-login__signin">
                <!-- <div class="m-login__head">
                    <h3 class="m-login__title">
                        Prijava za zavarovalnico
                    </h3>
                </div> -->
                <form (ngSubmit)="f.form.valid && signin()" #f="ngForm" class="m-login__form m-form">
                    <ng-template #alertSignin></ng-template>

                    <div class="form-group m-form__group" *ngIf="!is2FA">
                        <input
                            class="form-control m-input"
                            type="text"
                            placeholder="Uporabniško ime"
                            name="username"
                            autocomplete="off"
                            [(ngModel)]="model.email"
                            #email="ngModel"
                            i18n-placeholder="@@d94adcf7-00a8-5a05-8547-ce21425360f6"
                        />
                    </div>
                    <div class="form-group m-form__group" *ngIf="!is2FA">
                        <input
                            class="form-control m-input m-login__form-input--last"
                            type="password"
                            placeholder="Geslo"
                            name="password"
                            [(ngModel)]="model.password"
                            #password="ngModel"
                            i18n-placeholder="@@a733d4d8-0736-5ee9-9a24-9a649a2d6e38"
                        />
                    </div>
                    <ng-container *ngIf="is2FA">
                        <div class="form-group m-form__group">
                            <input
                                class="form-control m-input m-login__form-input--last"
                                type="text"
                                placeholder="2FA"
                                name="2FA"
                                [(ngModel)]="model.auth2fa"
                                #auth2fa="ngModel"
                                i18n-placeholder="@@app.login.2fa.input.placeholder"
                            />
                        </div>
                        <div class="col m--align-center">
                            <a
                                class="m-link"
                                href="#"
                                i18n="@@app.public.login.button.cancel.2fa"
                                (click)="is2FA = false"
                            >
                                Prekliči 2FA
                            </a>
                        </div>
                    </ng-container>
                    <div class="row m-login__form-sub">
                        <!-- <div class="col m--align-left m-login__form-left">
                            <label class="m-checkbox  m-checkbox--focus">
                                <input type="checkbox" name="remember" [(ngModel)]="model.remember" #remember="ngModel">
                                Zapomni se
                                <span></span>
                            </label>
                        </div> -->
                        <div class="col m--align-right m-login__form-right">
                            <a
                                href="javascript:;"
                                id="m_login_forget_password"
                                class="m-link"
                                i18n="@@app.public.login.button.forgetPassword.label"
                            >
                                Pozabljeno geslo ?
                            </a>
                        </div>
                    </div>

                    <div class="form-group m-form__group mt-5 text-center" *ngIf="isCaptcha">
                        <app-captcha
                            #loginCaptcha
                            (captchaSolved)="captchaLoginSolver($event)"
                            additionalText="Zahtevano je bilo dodatno preverjanje uporabnika."
                            *ngIf="!isCaptchaSolved"
                        ></app-captcha>
                        <i class=" la-4x la la-check-circle text-success" *ngIf="isCaptchaSolved"></i>
                    </div>

                    <div class="m-login__form-action">
                        <button
                            id="m_login_signin_submit"
                            class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                            i18n="@@app.public.login.button.login.label"
                        >
                            Prijava
                        </button>
                    </div>
                </form>
            </div>
            <!-- <div class="m-login__signup">
                <div class="m-login__head">
                    <h3 class="m-login__title">
                        Sign Up
                    </h3>
                    <div class="m-login__desc">
                        Enter your details to create your account:
                    </div>
                </div>
                <form class="m-login__form m-form" action="">
                    <div class="form-group m-form__group">
                        <input class="form-control m-input" type="text" placeholder="Fullname" name="fullname" />
                    </div>
                    <div class="form-group m-form__group">
                        <input
                            class="form-control m-input"
                            type="text"
                            placeholder="Email"
                            name="email"
                            autocomplete="off"
                        />
                    </div>
                    <div class="form-group m-form__group">
                        <input class="form-control m-input" type="password" placeholder="Password" name="password" />
                    </div>
                    <div class="form-group m-form__group">
                        <input
                            class="form-control m-input m-login__form-input--last"
                            type="password"
                            placeholder="Confirm Password"
                            name="rpassword"
                        />
                    </div>
                    <div class="row form-group m-form__group m-login__form-sub">
                        <div class="col m--align-left">
                            <label class="m-checkbox m-checkbox--focus">
                                <input type="checkbox" name="agree" /> I Agree the
                                <a href="#" class="m-link m-link--focus">
                                    terms and conditions
                                </a>
                                .
                                <span></span>
                            </label>
                            <span class="m-form__help"></span>
                        </div>
                    </div>
                    <div class="m-login__form-action">
                        <button
                            id="m_login_signup_submit"
                            class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air  m-login__btn"
                        >
                            Sign Up
                        </button>
                        &nbsp;&nbsp;
                        <button
                            id="m_login_signup_cancel"
                            class="btn btn-outline-focus m-btn m-btn--pill m-btn--custom  m-login__btn"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div> -->
            <div class="m-login__forget-password">
                <div class="m-login__head">
                    <h3 class="m-login__title" i18n="@@c551daac-d3ba-5042-91cd-b428dbdc24a7">
                        Pozabljeno geslo ?
                    </h3>
                    <div class="m-login__desc" i18n="@@681f8ece-2814-5fee-af27-1eca37ae9329">
                        Vpišite vaše uporabniško ime:
                    </div>
                </div>
                <form (ngSubmit)="f.form.valid && forgotPass()" #f="ngForm" class="m-login__form m-form">
                    <div class="form-group m-form__group">
                        <input
                            class="form-control m-input"
                            type="text"
                            placeholder="Uporabniško ime"
                            name="username"
                            id="m_email"
                            autocomplete="off"
                            [(ngModel)]="model.email"
                        />
                    </div>

                    <div class="form-group m-form__group mt-3 text-center">
                        <app-captcha
                            *ngIf="!isPasswordResetCaptchaSolved"
                            #passwordResetCaptcha
                            (captchaSolved)="captchaPasswordResetSolver($event)"
                        ></app-captcha>
                        <i class=" la-4x la la-check-circle text-success" *ngIf="isPasswordResetCaptchaSolved"></i>
                    </div>

                    <div class="m-login__form-action">
                        <button
                            id="m_login_forget_password_submit"
                            class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air  m-login__btn m-login__btn--primaryr"
                        >
                            Pošlji
                        </button>
                        &nbsp;&nbsp;
                        <button
                            id="m_login_forget_password_cancel"
                            class="btn btn-outline-focus m-btn m-btn--pill m-btn--custom m-login__btn"
                            i18n="@@cec05b59-4fbb-5cc6-84d3-e48d532d0352"
                        >
                            Prekliči
                        </button>
                    </div>
                </form>
            </div>
            <!-- <div class="m-login__account">
                <span class="m-login__account-msg">
                    Don't have an account yet ?
                </span>
                &nbsp;&nbsp;
                <a href="javascript:;" id="m_login_signup" class="m-link m-link--light m-login__account-link">
                    Sign Up
                </a>
            </div> -->
        </div>

        <div class="text-center m--font-primary">v {{ version }}</div>

        <!-- <div style="position: relative; background-color: blue; width: 600px; height: 800px;">

            <div style="position: absolute; bottom: 5px; background-color: green">
                <div class="text-center">v {{version}}</div>
            </div>
        </div> -->
    </div>
</div>
<input
    class="mf-h-input"
    type="text"
    placeholder="nameuser"
    name="nameuser"
    autocomplete="off"
    [(ngModel)]="model.username"
    #nameuser="ngModel"
    autocorrect="off"
    autocapitalize="none"
/>
