import { Serializable } from './../../../../../shared/model/deserialize.service';

export class TphCallEnded implements Serializable<TphCallEnded> {
    duration: string;
    callId: number;
    deserialize(input) {
        this.duration = input.duration;

        this.callId = input.callId;
        if (input.call_id) {
            this.callId = input.call_id;
        } else {
            this.callId = input.callId;
        }
        return this;
    }
}
