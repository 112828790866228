<div class="row">
    <div class="col-4 offset-4">
        <div *ngIf="newTask" class="modal-header">
            <h4 class="modal-title" i18n="@@1172733782130688">Dodajanje novega opravila</h4>
        </div>
        <div *ngIf="!newTask" class="modal-header">
            <h4 class="modal-title" i18n="@@8286276941774848">Urejanje opravila</h4>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-8 col-lg-4 mr-auto ml-auto">
        <div class="modal-body">
            <form [formGroup]="addTaskForm" novalidate (ngSubmit)="onSubmitNewTask()">
                <div class="m-list-search">
                    <div class="m-list-search__results">
                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la-i-cursor la" ngbTooltip="Naziv"> </i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                <input
                                    formControlName="description"
                                    type="text"
                                    class="form-control m-input"
                                    placeholder="Naziv"
                                    i18n-placeholder="@@0f0a4316-c80a-5546-9152-b9cd872308f9"
                                />
                                <div
                                    *ngIf="(addTaskForm?.controls)['description'].errors && formSubmited"
                                    class="m--font-danger pl-2"
                                >
                                    <small
                                        i18n="@@2522043878735872"
                                        [hidden]="!(addTaskForm?.controls)['description'].errors && formSubmited"
                                        >Naziv je obvezno polje.</small
                                    >
                                </div>
                            </span>
                        </div>

                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la-calendar la" ngbTooltip="Rok opravila"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                <input
                                    type="text"
                                    class="form-control m-input mf-t-datum"
                                    name="daterangeInput"
                                    daterangepicker
                                    [options]="calendarOptions"
                                    placeholder="Rok"
                                    autocapitalize="off"
                                    autocomplete="off"
                                    formControlName="dueDate"
                                    (selected)="setDate($event)"
                                />
                                <div
                                    *ngIf="(addTaskForm?.controls)['dueDate'].errors && formSubmited"
                                    class="m--font-danger pl-2"
                                >
                                    <small
                                        i18n="@@866945486290944"
                                        [hidden]="!(addTaskForm?.controls)['dueDate'].errors && formSubmited"
                                        >Rok je obvezno polje.</small
                                    >
                                </div>
                            </span>
                        </div>

                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la-user-plus la" ngbTooltip="Odgovoren"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                <input
                                    id="odgovoren"
                                    type="text"
                                    class="form-control m-input"
                                    formControlName="employee"
                                    [ngbTypeahead]="searchEmployee"
                                    [resultTemplate]="resultTemplateEmployee"
                                    [inputFormatter]="employeeFormatter"
                                    tabindex="3"
                                    placeholder="Odgovoren"
                                />

                                <ng-template #resultTemplateEmployee let-r="result" let-t="term">
                                    <strong> {{ r.name }} </strong>
                                </ng-template>
                            </span>
                        </div>
                        <div *ngIf="newTask && !editCustomer" class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la-user la" ngbTooltip="Stranka"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                <input
                                    id="stranka"
                                    type="text"
                                    class="form-control m-input"
                                    formControlName="customer"
                                    tabindex="3"
                                    [ngbTypeahead]="searchCustomer"
                                    [inputFormatter]="customerFormatter"
                                    [resultTemplate]="resultTemplateCustomer"
                                    placeholder="Stranka"
                                    [editable]="false"
                                />
                                <div
                                    *ngIf="(addTaskForm?.controls)['customer'].errors && formSubmited"
                                    class="m--font-danger pl-2"
                                >
                                    <small
                                        i18n="@@2522043878735872"
                                        [hidden]="!(addTaskForm?.controls)['customer'].errors && formSubmited"
                                        >Stranka je obvezno polje.</small
                                    >
                                </div>
                                <ng-template #resultTemplateCustomer let-r="result" let-t="term">
                                    <strong> {{ r.name }} {{ r.surname }}</strong>
                                </ng-template>
                            </span>
                        </div>
                        <div *ngIf="!newTask" class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la-user la" ngbTooltip="Stranka"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                <input
                                    id="stranka"
                                    type="text"
                                    class="form-control m-input"
                                    formControlName="customer"
                                    tabindex="3"
                                    placeholder="Stranka"
                                />
                            </span>
                        </div>
                        <div *ngIf="newTask && editCustomer" class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la-user la" ngbTooltip="Stranka"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                <input
                                    id="stranka"
                                    type="text"
                                    class="form-control m-input"
                                    formControlName="customer"
                                    tabindex="3"
                                    placeholder="Stranka"
                                />
                            </span>
                        </div>

                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la-comments la" ngbTooltip="Opomba"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                <textarea
                                    formControlName="details"
                                    class="form-control m-input"
                                    rows="2"
                                    placeholder="Opomba"
                                ></textarea>
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <!-- <button type="submit" class=" btn btn-outline-secondary m-btn m-btn--icon m-btn--pill btn-block">
                        <span>
                           
                            <span i18n="@@a0b55125-dad9-5b6a-a18e-5156ef860ad8">
                                Pošlji
                            </span>
                        </span>
                    </button> -->
                </div>
            </form>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-8 col-lg-4 mr-auto ml-auto">
        <div class="modal-footer">
            <button
                type="button"
                class="ml-auto mr-auto btn m-btn--pill btn-danger m-btn m-btn--custom"
                i18n="@@5787234913484800"
                (click)="close()"
            >
                Prekliči
            </button>
            <button
                *ngIf="newTask"
                type="submit"
                class="mr-auto btn m-btn--pill btn-brand m-btn m-btn--custom"
                tabindex="6"
                (click)="onSubmitNewTask()"
                i18n="@@332718221033472"
            >
                Dodaj
            </button>
            <button
                *ngIf="!newTask"
                type="submit"
                class="mr-auto btn m-btn--pill btn-brand m-btn m-btn--custom"
                tabindex="6"
                (click)="onSubmitNewTask('delete')"
                i18n="@@6194904910790656"
            >
                Zbriši
            </button>
            <button
                *ngIf="!newTask && !addTaskForm.pristine"
                type="submit"
                class="mr-auto btn m-btn--pill btn-success m-btn m-btn--custom"
                tabindex="6"
                (click)="onSubmitNewTask('save')"
                i18n="@@2110286196637696"
            >
                Shrani
            </button>
        </div>
    </div>
</div>
