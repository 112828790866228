import { Serializable } from './deserialize.service';

export class Business implements Serializable<Business> {
    bsid: number;
    iconPath: string;
    name: string;

    deserialize(input) {
        this.bsid = input.bsid;
        this.iconPath = input.iconPath;
        this.name = input.name;
        return this;
    }
}