import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-captcha',
    templateUrl: './captcha.component.html',
    styleUrls: ['captcha.component.scss'],
})
export class CaptchaComponent implements OnInit {
    public captchaImage: SafeResourceUrl;
    public selectChar: string;
    private guid: string;

    @Output()
    public captchaSolved: EventEmitter<any> = new EventEmitter();

    @Input()
    public additionalText: string = null;

    constructor(private _sanitizer: DomSanitizer, private authService: AuthService) {}

    ngOnInit() {
        this.loadCaptcha();
    }

    loadCaptcha(): void {
        this.captchaImage = null;
        this.selectChar = null;
        this.guid = null;
        this.authService.getCaptcha().subscribe((captcha) => {
            this.captchaImage = this._sanitizer.bypassSecurityTrustResourceUrl(
                'data:image/jpg;base64,' + captcha.captcha,
            );
            this.selectChar = captcha.selectChar;
            this.guid = captcha.guid;
        });
    }

    captchaSolver(event) {
        this.captchaSolved.emit({
            guid: this.guid,
            x: event.offsetX,
            y: event.offsetY,
        });
    }
}
