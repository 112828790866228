import { Serializable } from '../deserialize.service';

export class SharedDocumentPost implements Serializable<SharedDocumentPost> {
    public email: string;
    public name: string;
    public caseid: string;
    public documentIds: string[];

    deserialize(input: any) {
        this.caseid = input.caseid;
        this.name = input.name;
        this.email = input.email;
        this.documentIds = input.documentIds;
        return this;
    }
}
