import { Notification } from './../../../shared/model/notification.model';
import { HelperNotificationService } from './../../../shared/services/helper-notification.service';
import { StorageService } from './../../../shared/services/storage.service';
import { NotificationsService } from './../../../shared/services/notifications.service';
import { Component, OnInit, ViewEncapsulation, HostBinding, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { QuickSidebarService } from './quick-sidebar.service';
import * as _ from 'lodash';
import { OneSignalService } from '../../../shared/services/onesignal.service';

@Component({
    selector: 'app-quick-sidebar',
    templateUrl: './quick-sidebar.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./quick-sidebar.component.scss'],
})
export class QuickSidebarComponent implements OnInit, OnDestroy {
    public showAlert: boolean = false;
    public notificationsList: Notification[] = [];
    public notificationsListSlice: Notification[] = [];

    public numberOfNotifications: number = 0;
    public isOpen: boolean = false;
    public recent: boolean = true;
    public sliceIndex: number = 10;

    private notificationInterval;

    constructor(
        private notificationsService: NotificationsService,
        private storage: StorageService,
        private helperNotification: HelperNotificationService,
        private quickBarService: QuickSidebarService,
        private oneSignal: OneSignalService,
    ) {}

    ngOnInit() {
        if (_.has(this.storage.getBusinessSubject(), 'id')) {
            this.checkForNewNotifications();
        }
        this.quickBarService.change.subscribe((isOpen) => {
            this.isOpen = isOpen;
            if (this.isOpen === true) {
                this.loadAllNotifications();
            }
        });

        this.oneSignal.receivedNotifications.subscribe((notification) => {
            this.loadNotifications();
        });
    }

    ngOnDestroy() {
        if (this.notificationInterval) {
            clearInterval(this.notificationInterval);
        }
    }

    public loadNext(): void {
        this.sliceIndex += 10;
        this.notificationsListSlice = this.notificationsList.slice(0, this.sliceIndex);
    }

    public loadNotifications(): void {
        this.notificationsService
            .getNotifications(
                this.storage.getBusinessSubject().id,
                moment()
                    .subtract(2, 'day')
                    .format('YYYY-MM-DD'),
                moment()
                    .add(1, 'day')
                    .format('YYYY-MM-DD'),
            )
            .subscribe((res) => {
                this.manageLoadedEvents(res);
            });
    }

    public loadAllNotifications(): void {
        this.notificationsService
            .getNotifications(
                this.storage.getBusinessSubject().id,
                moment()
                    .subtract(365, 'day')
                    .format('YYYY-MM-DD'),
                moment()
                    .add(1, 'day')
                    .format('YYYY-MM-DD'),
            )
            .subscribe((res) => {
                this.manageLoadedEvents(res);
            });
    }

    private manageLoadedEvents(res: Notification[]): void {
        this.showAlert = false;
        this.notificationsList = res
            .map((oldNotification) => {
                // oldNotification.unread = true;
                return this.helperNotification.returnNotificationReadyForView(oldNotification);
            })
            .filter((notification) => {
                return notification !== null;
            });
        this.notificationsListSlice = this.notificationsList.slice(0, this.sliceIndex);
    }

    public reset() {
        this.notificationsList = [];
    }

    public markAllAsRead(): void {
        this.notificationsService.markAllNotificationsAsSeen(this.storage.getBusinessSubject().id).subscribe((res) => {
            this.loadAllNotifications();
        });
    }

    public checkForNewNotifications(): void {
        this.notificationInterval = setInterval(() => {
            this.notificationsService
                .getNotifications(
                    this.storage.getBusinessSubject().id,
                    moment().format('YYYY-MM-DD'),
                    moment()
                        .add(1, 'day')
                        .format('YYYY-MM-DD'),
                )
                .subscribe(
                    (res) => {
                        if (this.numberOfNotifications === 0) {
                            this.numberOfNotifications = res.length;
                            this.showAlert = false;
                        } else if (res.length !== this.numberOfNotifications) {
                            this.showAlert = true;
                        }
                    },
                    (error) => {
                        this.showAlert = false;
                    },
                );
        }, 90000);
    }

    public closeSidebar() {
        this.isOpen = !this.isOpen;
        this.quickBarService.toggle();
    }
}
