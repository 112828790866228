import { TelephonyModule } from './theme/pages/default/telephony/telephony.module';
import { environment } from './../environments/environment';
import { BaseCaseState } from './theme/pages/_base/base-case/_state/base-case.state';
import { NgxsModule } from '@ngxs/store';
import { ContractorAppModule } from './contractor-app/contractor-app.module';
import { TokenInterceptorService } from './token.interceptor.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ThemeComponent } from './theme/theme.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from './_services/script-loader.service';
import { ThemeRoutingModule } from './theme/theme-routing.module';
import { AuthModule } from './auth/auth.module';
import { ToastrModule } from 'ngx-toastr';
import { MomentModule } from 'ngx-moment';
import { IntercomModule } from 'ng-intercom';
import { PublicComponent } from './public/public.component';
import { AgmCoreModule } from '@agm/core';

// import { GoogleMapsAPIWrapper } from '@agm/core/services';

import { registerLocaleData } from '@angular/common';
import localeSl from '@angular/common/locales/sl';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import 'moment/locale/sl';
import { NgxPermissionsModule } from 'ngx-permissions';
import { RulesService } from './shared/services/rules.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { OneSignalService } from './shared/services/onesignal.service';
import { BaseState } from './theme/pages/_base/base-state/_state/base.state';

registerLocaleData(localeSl);
// export class CustomOption extends ToastOptions {
//     animate = 'flyRight';
//     newestOnTop = false;
//     showCloseButton = true;
//     positionClass = 'toast-top-center';
// }

@NgModule({
    declarations: [ThemeComponent, AppComponent, PublicComponent],
    imports: [
        NgbModule,
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ThemeRoutingModule,
        ContractorAppModule,
        AuthModule,
        SweetAlert2Module.forRoot(),

        // SweetAlert2Module.forRoot({
        //     buttonsStyling: false,
        //     confirmButtonClass: 'btn btn-success m-btn m-btn--custom',
        //     confirmButtonColor: null,
        //     cancelButtonClass: 'btn btn-secondary m-btn m-btn--custom',
        //     cancelButtonColor: null,
        // }),
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
        }),
        //AIzaSyDAq4rmQzieDtilQYesXbW-UOIWx0wOxyI
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyC0YldE3d5kdTwZri1SmfYEwESZ1yXN3P4',
        }),
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                // 'm': 59,
            },
        }),
        NgxsModule.forRoot([BaseCaseState, BaseState], { developmentMode: !environment.production }),
        NgxsStoragePluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        IntercomModule.forRoot({
            appId: environment.INTERCOM_KEY, // from your Intercom config
            updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
        }),
        NgxPermissionsModule.forRoot(),
        TelephonyModule,
    ],
    providers: [
        ScriptLoaderService,
        RulesService,
        // { provide: ToastOptions, useClass: CustomOption },
        { provide: LOCALE_ID, useValue: 'sl' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        OneSignalService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
