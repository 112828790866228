import { UrlDataBS1 } from './../shared/model/auth/BS1/url-data-bs1.model';
import { AdditionalData } from './../shared/model/authorization/additional-data';
import { IntegrationService } from './../shared/services/integration.service';
import { AuthorizationOrder } from './../shared/model/authorization/authorizationOrder.model';
import { AuthHelperService } from './auth-helper.service';
import { AuthorizationService } from './../shared/services/authorization.service';
import { AuthorityCase } from './../shared/model/authorityCase/authorityCase.model';
import { AuthorityCases } from './../shared/model/authorityCase/authorityCases.model';
import { AuthorizationTriage } from './../shared/model/authorityCase/authorizationTriage.model';
import { AuthorityCaseStatus } from './../shared/enums/authorityCaseStatus.enum';
import { AuthorityType } from './../shared/enums/authorityType.enum';
import { UrlData } from './../shared/model/auth/url-data.model';
import { AuthorityCaseRequest } from './../shared/model/authorityCase/authorityCaseRequest.model';
import {
    NewAuthorityCase,
    SetAuthorityType,
    SetStaus,
    OpenAuthorityCase,
    SetUrlData,
    SetAdditionalData,
    SetExternalUrlCase,
} from './../theme/pages/_base/base-case/_actions/base-case.actions';
import { Store } from '@ngxs/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { PublicService } from './../shared/services/public.service';
import { CommonService } from './../shared/services/common.service';
import { environment } from './../../environments/environment';
import { AuthorizationManage } from '../shared/model/authorization/authorizationManage.model';
import { BusinessSubjects } from './../shared/model/businessSubjects.model';
import { Subject, forkJoin, of, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './../shared/services/auth.service';
import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { AlertService } from './_services/alert.service';
import { AlertComponent } from './_directives/alert.component';
import { Helpers } from '../helpers';
import { HelperService } from '../shared/services/helper.service';
import { User } from '../shared/model/user.model';
import { StorageService } from '../shared/services/storage.service';
import { takeUntil, tap, map, switchMap, mergeMap, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { RulesService } from '../shared/services/rules.service';
import { GuiSettings } from '../shared/model/guiSettings/guiSettings.model';
import { LoginCustom } from './_helpers/login-custom';
import { OneSignalService } from '../shared/services/onesignal.service';
import { CaptchaComponent } from '../shared/components/captcha/captcha.component';
import { HttpClient } from '@angular/common/http';
import { SetEnableBooking } from '../theme/pages/_base/base-state/_actions/base.actions';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './templates/login-3.component.html',
    styles: [
        `
            @keyframes autofill {
                to {
                    color: #776e87;
                    background: #f7f6f9;
                }
            }

            @-webkit-keyframes autofill {
                to {
                    color: #776e87;
                    background: #f7f6f9;
                }
            }

            input:-webkit-autofill {
                animation-name: autofill;
                -webkit-animation-name: autofill;
                animation-fill-mode: both;
                -webkit-animation-fill-mode: both;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
    private destroy$: Subject<boolean> = new Subject<boolean>();
    model: any = {};
    loading = false;
    returnUrl: string;
    public version: string = this.helper.getAppVersion();
    public isProduction: boolean = environment.production;
    public is2FA: boolean = false;

    public isCaptcha: boolean = false;
    public isCaptchaSolved: boolean = true;
    public isPasswordResetCaptchaSolved: boolean = false;
    public passwordResetCaptchaSolution: any = null;

    @ViewChild('alertSignin', { read: ViewContainerRef, static: true })
    alertSignin: ViewContainerRef;
    @ViewChild('alertSignup', { read: ViewContainerRef, static: false })
    alertSignup: ViewContainerRef;
    @ViewChild('loginCaptcha', { static: false })
    loginCaptcha: CaptchaComponent;
    @ViewChild('passwordResetCaptcha', { static: false })
    passwordResetCaptcha: CaptchaComponent;

    // @ViewChild('alertForgotPass', { read: ViewContainerRef, static: false })
    // alertForgotPass: ViewContainerRef;

    constructor(
        private _router: Router,
        private _script: ScriptLoaderService,
        private _route: ActivatedRoute,
        // private _authService: AuthenticationService,
        private _alertService: AlertService,
        private cfr: ComponentFactoryResolver,
        private helper: HelperService,
        private storage: StorageService,
        private toast: ToastrService,
        private authRest: AuthService,
        private rules: RulesService,
        private commonRest: CommonService,
        private publicRest: PublicService,
        private oneSignal: OneSignalService,
        private permissionsService: NgxPermissionsService,
        public store: Store,
        private authorizationRest: AuthorizationService,
        private authHelper: AuthHelperService, // private integrationRest: IntegrationService,
        private httpService: HttpClient,
    ) {}

    ngOnInit() {
        this.permissionsService.flushPermissions();
        this.model.remember = true;
        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/login?returnUrl=%2Fcustomers';
        // this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/login?returnUrl=%2Findex';

        if (this._route.snapshot.queryParams['token']) {
            const token = this._route.snapshot.queryParams['token'];
            //this.storage.setPreventRedirect(true);
            if (token.length > 0 && this.authRest.tokenLogin(token)) {
                // this.storage.setExternalMode(true);
                this.afterLogin();
            }
        }

        this._script
            .loadScripts(
                'body',
                ['assets/vendors/base/vendors.bundle.js', 'assets/demo/demo5/base/scripts.bundle.js'],
                true,
            )
            .then(() => {
                Helpers.setLoading(false);
                LoginCustom.init();
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public signin(): void {
        let password: string = '';
        this.storage.deleteAll();
        this.storage.deleteUserData();
        if (this.model.username) {
            return;
        }

        if (!this.isCaptchaSolved) {
            this.toast.error('Dodatno preverjanje ni bilo uspešno!');
            return;
        }

        this.loading = true;
        Helpers.setLoading(true);
        if (this.is2FA) {
            password = `${this.model.password}##${this.model.auth2fa}`;
        } else {
            password = this.model.password;
        }
        this.authRest
            .login(this.model.email, password)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (data) => {
                    if (data) {
                        this.isCaptcha = false;
                        this.isCaptchaSolved = true;
                        this.afterLogin();
                    }
                },
                (error) => {
                    this.isCaptcha = false;
                    this.isCaptchaSolved = true;
                    Helpers.setLoading(false);
                    this.loading = false;
                    // this.showAlert('alertSignin');
                    if (_.get(error, 'error.error_description') === '2FA') {
                        this.is2FA = true;
                    } else if (error.error.error_description === 'CaptchaNeeded') {
                        this.isCaptcha = true;
                        this.isCaptchaSolved = false;
                        this.toast.error('Zahtevano je bilo dodatno preverjanje uporabnika!');
                    } else if (error.error.error === 'unauthorized' || error.error.error === 'invalid_grant') {
                        this.toast.error('Napačno uporabniško ime ali geslo!');

                        // this._alertService.error('Napačno uporabniško ime ali geslo!');
                    } else {
                        console.error(error);
                        this.toast.error('Napaka pri prijavi!');

                        // this._alertService.error('Napaka pri prijavi!');
                    }
                    Helpers.setLoading(false);
                    this.loading = false;
                },
            );
    }

    private afterLogin(): void {
        // this._router.navigate([this.returnUrl]);
        this.authRest
            .getUserData()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (user: User) => {
                    const loginUser: User = new User().deserialize(user);
                    this.storage.setUserData(new User().deserialize(user));

                    const business = new BusinessSubjects().deserialize(
                        _.get(loginUser, 'businessSubjects[0]', undefined),
                    );
                    let type = 0;
                    // if (business.id === 2) {
                    //     type = 1;
                    // }
                    this.storage.setBusinessSubject(business);
                    this.storage.setAppData({ version: this.version, type: type });
                    // this._router.navigate([this.returnUrl]);
                    if (user.businessSubjects.length > 0) {
                        const bsid: number = _.get(user, 'businessSubjects[0].id', -1);
                        forkJoin(
                            this.commonRest.getUserGuiSettings(bsid),
                            this.commonRest.getBSGuiSettings(bsid),

                            (userSettings: GuiSettings, bsSettings: GuiSettings) => ({
                                userSettings: userSettings,
                                bsSettings: bsSettings,
                            }),
                        ).subscribe(
                            (res) => {
                                const userRoles = this.storage.getUserRoles();
                                const bookingRoles = userRoles.filter((role) => {
                                    return role.indexOf('ROLE_BOOKING') !== -1;
                                });

                                if (userRoles && bookingRoles && bookingRoles.length > 0) {
                                    this.storage.setGuiSettings(res.userSettings);
                                    this.storage.setBsGuiSettings(res.bsSettings);
                                    this.rules.setRules();
                                    this.oneSignal.init(true);

                                    const majorVersion = parseInt(this.version.slice(0, this.version.indexOf('.')));
                                    //const preventRedirect = this.storage.isPreventRedirect();
                                    if (majorVersion === 2) {
                                        this.httpService.get('/assets/v3enabled.json').subscribe(
                                            (resp: any) => {
                                                if (!this.storage.getBusinessSubject()) {
                                                    return;
                                                }

                                                if (resp && resp.forceV3) {
                                                    const forceV3Enabled = resp.forceV3.find((itm) => {
                                                        return itm.bsId === this.storage.getBusinessSubject().id;
                                                    });

                                                    if (forceV3Enabled && forceV3Enabled.enabled) {
                                                        let redirectUrl = '/v3/';
                                                        if (_.has(this._route, 'snapshot.queryParams["token"]')) {
                                                            redirectUrl += `login?token=${this._route.snapshot.queryParams['token']}`;
                                                        }

                                                        if (_.has(this._route, 'snapshot.queryParams["data"]')) {
                                                            redirectUrl += `&data=${this._route.snapshot.queryParams['data']}`;
                                                        }

                                                        window.location.href = redirectUrl;
                                                        return;
                                                    }
                                                }
                                                this.navigatAfterLogin();
                                            },
                                            () => {
                                                this.navigatAfterLogin();
                                            },
                                        );
                                    } else {
                                        this.navigatAfterLogin();
                                    }
                                    // if (this.storage.isExternalMode()) {
                                    //     this.is2FA = false;
                                    //     this.returnUrl = '/authorization/details';
                                    // }
                                } else {
                                    this.toast.error('Nimate pravice za prijavo!');
                                }
                            },
                            (error) => {
                                console.log(error);
                                this._alertService.error('Ste brez pravic!');
                                this.toast.error('Nimate pravice za prijavo!');
                                this.loading = false;
                                Helpers.setLoading(false);
                            },
                        );
                    } else {
                        this.toast.error('Nimate pravice za prijavo!');
                        this.loading = false;
                        Helpers.setLoading(false);
                    }
                },
                (error) => {
                    this.toast.error('Napaka pri prijavi');
                    this.loading = false;
                    Helpers.setLoading(false);
                },
            );
    }

    // private initTawk(): void {
    //     const user: User = this.storage.getUserData();
    //     const bs: BusinessSubjects = this.storage.getBusinessSubject();
    //     if ((<any>window).Tawk_API) {
    //         (<any>window).Tawk_API.setAttributes(
    //             {
    //                 name: _.get(user, 'name', 'unknown user'),
    //                 email: _.get(user, 'email', 'unknown email'),
    //                 businessSubject: _.get(bs, 'name', 'unknown bs'),
    //                 // hash: 'd41e715c4bc7cc73ef795dd4e815981caa1f3aa7d9580c1df478595f031ce489',
    //             },
    //             function(error) {
    //                 if (error) {
    //                     console.error(`MF TAWK - error: ${error}`);
    //                 }
    //             },
    //         );
    //     }
    // }

    private navigatAfterLogin(): void {
        if (_.has(this._route, 'snapshot.queryParams["token"]')) {
            let urlData: UrlData | UrlDataBS1;
            let dataCall$: Observable<any>;
            const onlyHasLoginToken = () =>
                _.has(this._route, 'snapshot.queryParams["token"]') &&
                _.get(Object.entries(_.get(this._route, 'snapshot.queryParams')), 'length') == 1;
            //url with only login token -> direct login
            //url with token and additional data -> manageUrlData
            if (onlyHasLoginToken()) {
                dataCall$ = of({ directLogin: true });
            } else {
                dataCall$ = this.authHelper
                    .manageUrlData(this._route.snapshot.queryParams['data'], this._route.snapshot.queryParams['caseid'])
                    .pipe(
                        switchMap((urlDataManage) => {
                            urlData = urlDataManage;
                            if (_.get(urlDataManage, 'customer.mobilePhone')) {
                                urlData.customer.phone = _.get(urlDataManage, 'customer.mobilePhone');
                            }
                            return this.authorizationRest
                                .getSingleCase(
                                    this.storage.getBusinessSubject().id,
                                    urlData.case.id,
                                    _.get(urlData, 'case.claimFile', undefined),
                                )
                                .pipe(
                                    map((el: AuthorizationOrder[]) => {
                                        if (el.length > 0) {
                                            return el[0];
                                        }
                                        return undefined;
                                    }),
                                );
                        }),
                    );
            }

            dataCall$.pipe(takeUntil(this.destroy$)).subscribe(
                (authCase: AuthorizationOrder | { directLogin: boolean }) => {
                    if (_.get(authCase, 'directLogin')) {
                        if (this.storage.getBusinessSubject().id == 7) {
                            this.store.dispatch(new SetEnableBooking(false));
                        }
                        this._router.navigate([this.returnUrl]);
                        return;
                    }
                    if (authCase) {
                        //obstaja
                        const data: AuthorizationTriage = new AuthorizationTriage().deserialize(authCase);
                        this.store
                            .dispatch(new OpenAuthorityCase(data))
                            .pipe(mergeMap(() => this.store.dispatch(new SetUrlData(urlData))))
                            .subscribe(() => {
                                this.helper.navigate(['/authorization/details']);
                            });
                    } else {
                        //ne obstaja
                        let authorityCaseRequest = new AuthorityCaseRequest().deserialize({
                            customer: urlData.customer,
                            user: this.storage.getUserData(),
                            case: urlData.case,
                        });
                        //triglav -> dodaj authorityId iz urlData
                        if (this.storage.getBusinessSubject().id === 2) {
                            authorityCaseRequest.authorityId = _.get(urlData, 'case.coverMap');
                            delete authorityCaseRequest.user;
                        }

                        this.store
                            .dispatch([new NewAuthorityCase(authorityCaseRequest)])
                            .pipe(
                                tap(() => {
                                    this.store
                                        .dispatch([
                                            new SetUrlData(urlData),
                                            new SetAuthorityType(AuthorityType.CASE),
                                            new SetStaus(AuthorityCaseStatus.SELECTED),
                                            new SetAdditionalData(urlData.additionalData),
                                        ])
                                        .pipe(takeUntil(this.destroy$));
                                }),
                            )
                            .subscribe(
                                () => {
                                    this.helper.navigate(['/authorization/details']);
                                },
                                (error) => {
                                    if (error.errorType === 'missingPhone') {
                                        console.error('ERROR: Missing customer phone!');
                                    }
                                },
                            );
                    }
                },

                (err) => {
                    const errId: string = _.get(err, 'id');
                    switch (errId) {
                        case 'CASE_NOT_NUMBER':
                            this.helper.displayAlert(
                                'error',
                                'Id avtorizacije ni številka, avtorizacije se ni ustvarila.',
                                { type: 'error', showConfirmButton: false },
                            );
                            break;
                        case 'CUSTOMER_MISSING_TAXNUMBER':
                            this.helper.displayAlert(
                                'error',
                                'Stranka nima vnešene davčne številke, avtorizacija se ni ustvarila.',
                                { type: 'error', showConfirmButton: false },
                            );
                            break;
                        default:
                            // this.helper.fireSwal({
                            //     title: 'Napaka pri kreiranju avtorizacije.',
                            //     type: 'error',
                            // });
                            this.helper.displayAlert('error', 'Napaka pri kreiranju avtorizacije.', {
                                type: 'error',
                                showConfirmButton: false,
                            });
                            break;
                    }
                },
            );
        } else if (
            _.has(this._route, 'snapshot.queryParams["hasQueryParams"]') &&
            this._route.snapshot.queryParams['hasQueryParams'] === 'true'
        ) {
            this._router.navigate([this.returnUrl], {
                queryParams: {
                    hasQueryParams: null,
                },
                queryParamsHandling: 'merge',
            });
            this._router.navigateByUrl(this.returnUrl);
        } else {
            this._router.navigate([this.returnUrl]);
        }
    }

    public forgotPass() {
        this.loading = true;

        if (!this.model.email) {
            this.toast.error('Vnesite vaše uporabniško ime!');
            return;
        }

        if (!this.passwordResetCaptchaSolution) {
            this.toast.error('Napaka pri preverjanju slike!');
            return;
        }

        this.publicRest
            .forgottenPwd({
                username: this.model.email,
                guid: this.passwordResetCaptchaSolution.guid,
                x: this.passwordResetCaptchaSolution.x,
                y: this.passwordResetCaptchaSolution.y,
            })
            .subscribe(
                () => {
                    this.isPasswordResetCaptchaSolved = false;
                    this.passwordResetCaptchaSolution = null;
                    this.toast.success(
                        'Če med uporabniki obstaja račun z navedenim elektronskim naslovom, bo nanj poslana povezava za ponastavitev gesla.',
                    );
                    this.loading = false;
                    LoginCustom.displaySignInForm();
                    this.model = {};
                },
                (error) => {
                    this.isPasswordResetCaptchaSolved = false;
                    this.passwordResetCaptchaSolution = null;
                    if (error.error.errors[0].code === 'invalidCaptcha') {
                        this.toast.error('Napaka pri preverjanju slike!');
                    } else {
                        this.toast.error('Napaka pri spremembi gesla.');
                    }
                    // this.showAlert('alertForgotPass');
                    // this._alertService.error(error);
                    this.loading = false;
                },
            );
    }

    public captchaLoginSolver(event) {
        this.authRest.solveUserCaptcha(this.model.email, event.guid, event.x, event.y).subscribe(
            (response) => {
                if (!response) {
                    //this.loadCaptcha();
                    this.isCaptchaSolved = false;
                    this.loginCaptcha.loadCaptcha();
                } else {
                    this.isCaptchaSolved = true;
                }
            },
            (error) => {
                this.isCaptchaSolved = false;
            },
        );
    }

    public captchaPasswordResetSolver(event) {
        this.isPasswordResetCaptchaSolved = true;
        this.passwordResetCaptchaSolution = event;
    }

    // showAlert(target) {
    //     if (target) {
    //         this[target].clear();
    //         const factory = this.cfr.resolveComponentFactory(AlertComponent);
    //         const ref = this[target].createComponent(factory);
    //         ref.changeDetectorRef.detectChanges();
    //     }
    // }
}
