import { NotificationOrigin } from './../../../../../shared/enums/notificationOrigin.enum';
import { Document } from './../../../../../shared/model/document.model';
import { StorageService } from './../../../../../shared/services/storage.service';
import { AuthorizationService } from './../../../../../shared/services/authorization.service';
import { HelperService } from './../../../../../shared/services/helper.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-timeline-element',
    templateUrl: './timeline-element.component.html',
    styleUrls: ['./timeline-element.component.scss'],
})
export class TimelineElementComponent implements OnInit {
    constructor(public authRest: AuthorizationService, public storage: StorageService, public helper: HelperService) {}

    @Input() item: any;
    public collapsed: boolean = false;
    public NotificationOrigin: typeof NotificationOrigin = NotificationOrigin;
    ngOnInit() {}

    public downloadDocument(doc: Document): void {
        this.authRest.getSigenDocument(this.storage.getBusinessSubject().id, doc.documentId).subscribe((res) => {
            if (res) {
                this.helper.openDocumentViewer(res.document, res.document.type, doc.description, false, res.signature);
            }
        });
    }
}
