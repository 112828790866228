import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from '../shared/services/public.service';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService, HelperService, StorageService } from '../shared/services';
import {Helpers} from "../helpers";

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    // selector: '.m-grid__item.m-grid__item--fluid.m-grid.m-grid--ver-desktop.m-grid--desktop.m-body.m-page__container',
    templateUrl: './public.component.html',
    styleUrls: ['public.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PublicComponent implements OnInit {
    public guid: string;
    public hideTopBar: boolean = false;
    public url: string;
    constructor(
        private router: ActivatedRoute,
        private route: Router,
        private publicRest: PublicService,
        private toast: ToastrService,
        public storage: StorageService,
    ) {}

    ngOnInit() {
        this.router.queryParams.subscribe((params) => {
            if (!params.guid) {
                // alert('Manjkajoči podatek (guid)');
                return;
            }
            this.guid = params.guid;
        });

        this.checkUrl(this.route.url.split('?')[0]);

        this.publicRest.checkToken(this.guid).subscribe(
            (bsId) => {
                this.navigateBS(bsId);
            },
            (ex) => {
                this.toast.error('Napaka pri pridobivanju podatkov (guid)');
            },
        );
    }

    public navigateBS(bsId) {
        Helpers.setLoading(false);
        switch (bsId) {
            case '2':
                this.route.navigate(['/public/' + this.url, this.url == 'download-files' ? bsId : ''], {
                    queryParams: { guid: this.guid },
                });
                this.hideTopBar = true;
                break;
            default:
                this.route.navigate(['/public/' + this.url, bsId], { queryParams: { guid: this.guid } });
        }
    }

    public checkUrl(route) {
        switch (true) {
            case route.includes('case-details'):
                this.url = 'case-details';
                return;
            case route.includes('download-files'):
                this.url = 'download-files';
                return;
        }
    }
}
