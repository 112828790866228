import { Serializable } from '../deserialize.service';

export class OverriddenContractorAddress implements Serializable<OverriddenContractorAddress> {
    postName: string;
    postNumber: string;
    // streetName: string;
    // streetNumber: string;
    title: string;
    email: string;
    phone: string;
    address: string;
    externalId: String;
    generaliExternalId: String; //Generali
    tzzOsebaId: String; //Triglav
    tzzExternalId: String; //Triglav
    inisExternalId: string;
    generaliOsaId: string; //Generali

    deserialize(input) {
        // debugger;
        this.postName = input.postName;
        this.postNumber = input.postNumber;
        // this.streetName = input.streetName;
        // this.streetNumber = input.streetNumber;
        this.title = input.title;
        this.email = input.email;
        this.phone = input.phone;
        this.address = input.address;
        this.externalId = input.externalId;
        this.generaliExternalId = input.generaliExternalId;
        this.tzzOsebaId = input.tzzOsebaId;
        this.tzzExternalId = input.tzzExternalId;
        this.inisExternalId = input.inisExternalId;
        this.generaliOsaId = input.generaliOsaId;
        return this;
    }
}
