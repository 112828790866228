import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class RulesService {
    constructor(
        private storage: StorageService,
        private permissionsService: NgxPermissionsService,
        private rolesService: NgxRolesService,
    ) {}

    private allRules: any = {
        ROLE_BOOKING: [
            'customers_isVisible',
            'authorizations_isVisible',
            'contractors_isVisible',
            'tasks_isVisible',
            'eNarocanje_isVisible',
            'v3Link_isVisible',
            'notifications_isVisible',
            'telephony_isVisible',
            'settings_services_isVisible',
        ],
        ROLE_BOOKING_MASTER: [
            'setting_bsServices_canEdit',
            'setting_contractor_isVisible',
            'settings_notification_isVisible',
            'settings_surveys_isVisible',
            'settings_surveyResults_isVisible',
            'settings_halodoktor_isVisible',
            'customer_consent_edit_isEnabled',
            'settings_analytics2_isVisible',
            'settings_services_isVisible',
        ],
        ROLE_BOOKING_TASK_MANAGER: ['settings_surveyResults_isVisible', 'customer_consent_edit_isEnabled'],
        ROLE_BOOKING_ANALYTICS: ['settings_analytics_isVisible'],
        ROLE_BOOKING_METABASE_ANALYTICS: ['metabaseAnalytics_isVisible'],
    };

    public setRules(): void {
        this.allRules['ROLE_BOOKING_MASTER'] = [
            ...this.allRules['ROLE_BOOKING'],
            ...this.allRules['ROLE_BOOKING_MASTER'],
        ];

        const roles = this.storage.getUserRoles();
        if (roles === null) {
            return;
        }
        if (roles) {
            const userPermissions = [];
            roles.map((el) => {
                if (this.allRules[el]) {
                    userPermissions.push(...this.allRules[el]);
                }
            });
            this.permissionsService.loadPermissions(userPermissions);
        }
    }
}
