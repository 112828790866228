import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { StorageService } from './storage.service';
import { HelperService } from './helper.service';
import { Observable } from 'rxjs';
import { Customer, Task } from '../model';

@Injectable({
    providedIn: 'root',
})
export class TaskService {
    constructor(
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    public getAllTasks(bsId: number, concluded: boolean = false): Observable<Task[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + '/bs/' + bsId + '/tasks?concluded=' + concluded);
    }

    public getCustomerTasks(bsId: number, customerId: number): Observable<any> {
        return this.httpClient.get(
            this.helper.getAssistanceUrl(2) + '/bs/' + bsId + '/customers/' + customerId + '/tasks',
        );
    }
    public editCustomerTask(bsId: number, customerId: number, task: Task): Observable<any> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl(2) + '/bs/' + bsId + '/customers/' + customerId + '/tasks',
            task,
        );
    }

    public addCustomerTask(bsId: number, customerId: number, task: Task): Observable<any> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl(2) + '/bs/' + bsId + '/customers/' + customerId + '/tasks',
            task,
        );
    }
    public concludeTask(bsId: number, taskId: number): Observable<any> {
        return this.httpClient.put(this.helper.getAssistanceUrl(2) + '/bs/' + bsId + '/tasks/' + taskId + '/conclude');
    }
    public deleteTask(bsId: number, taskId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getAssistanceUrl(2) + '/bs/' + bsId + '/tasks/' + taskId);
    }
}
