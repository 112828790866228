import { HelperNotificationService } from './../../../shared/services/helper-notification.service';
import { NotificationsService } from './../../../shared/services/notifications.service';
import { StorageService } from './../../../shared/services/storage.service';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { Notification } from './../../../shared/model/notification.model';
import * as moment from 'moment';
@Injectable({
    providedIn: 'root',
})
export class QuickSidebarService {
    public isOpen = false;

    @Output() change: EventEmitter<boolean> = new EventEmitter();

    toggle(value?: string) {
        if (value === 'logout') {
            this.change.emit(false);
        } else {
            this.isOpen = !this.isOpen;
            this.change.emit(this.isOpen);
        }
    }
}
