import { SpecializationTemplate } from './../model/settings/specializationTemplate.model';
import { ServiceBUContractor } from './../model/service/serviceBUContractor.model';
import { HttpClientService } from './http-client.service';
import { HelperService } from './helper.service';
import { Service, User } from '../model';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ServiceUpdate } from '../model/service/serviceUpdate.model';
import { SpecialityTemplate } from '../model/specialityTemplate.model';
import _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class BusinessUserService {
    constructor(private HttpClient: HttpClientService, private helper: HelperService) {}

    //getServicesForBus()
    public getServicesForBus(buId: number): Observable<Service[]> {
        return this.HttpClient.get(this.helper.getAssistanceUrl(2) + '/bus/' + buId + '/services').pipe(
            map((contr) => {
                // debugger;
                return contr.map((subc) => new Service().deserialize(subc));
                // new Contractor().deserialize(el);
                // return [];
            }),
        );
    }

    public getAllSpecialityTemplates(bsId: number, contractorId?): Observable<SpecializationTemplate[]> {
        // http://10.230.33.13:5550/booking/asv2/assistance/api/bs/1/specialityTemplates
        let dataUrl = this.helper.getAssistanceUrl(3) + `/spec/bs/${bsId}/templates`;
        if (contractorId) {
            dataUrl += `?contractorId=${contractorId}`;
        }
        return this.HttpClient.get(dataUrl);
    }

    public saveSpecialityTemplate(bsId: number, data: SpecialityTemplate): Observable<any> {
        // @Post("/bs/{bsid}/specialityTemplates")
        debugger;
        return this.HttpClient.post(this.helper.getAssistanceUrl(3) + `/spec/bs/${bsId}/templates`, data);
    }
    public saveSpecialityTemplateServices(bsId: number, tempId: number, data): Observable<any> {
        // @Post("/bs/{bsid}/specialityTemplates/{tempId}/services")
        return this.HttpClient.post(
            this.helper.getAssistanceUrl(3) + `/spec/bs/${bsId}/templates/${tempId}/services`,
            data,
        );
    }

    public deleteSpecialityTemplateServices(bsId: number, tempId: number, data): Observable<any> {
        return this.HttpClient.put(
            this.helper.getAssistanceUrl(3) + `/spec/bs/${bsId}/templates/${tempId}/services/delete`,
            data,
        );
    }

    public deleteSpecialization(bsId: number, tempId: number): Observable<any> {
        // @Delete("/bs/{bsid}/specialityTemplates/{tempId}")
        return this.HttpClient.delete(this.helper.getAssistanceUrl(3) + `/spec/bs/${bsId}/templates/${tempId}`);
    }

    public getLeasedTerms(bsId: number): Observable<any> {
        return this.HttpClient.get(this.helper.getAssistanceUrl(3) + `/spec/bs/${bsId}/terms`);
    }

    public saveLeasedTerm(bsId: number, data): Observable<any> {
        return this.HttpClient.post(this.helper.getAssistanceUrl(3) + `/spec/bs/${bsId}/terms`, data);
    }

    public saveSpeciality(bsId: number, data): Observable<any> {
        return this.HttpClient.post(this.helper.getAssistanceUrl(3) + `/spec/bs/${bsId}/specialities`, data);
    }

    public updateSpeciality(bsId: number, specId, data): Observable<any> {
        return this.HttpClient.put(this.helper.getAssistanceUrl(3) + `/spec/bs/${bsId}/specialities/${specId}`, data);
    }

    public deleteSpeciality(bsId: number, specId): Observable<any> {
        return this.HttpClient.delete(this.helper.getAssistanceUrl(3) + `/spec/bs/${bsId}/specialities/${specId}`);
    }
    public getSpecialities(bsId: number, params = {}): Observable<any> {
        let dataUrl = this.helper.getAssistanceUrl(3) + `/spec/bs/${bsId}/specialities`;
        if (!_.isEmpty(params)) {
            let first = true;
            Object.entries(params).forEach((el) => {
                if (!_.isNil(el[1])) {
                    if (first) {
                        dataUrl += `?${el[0]}=${el[1]}`;
                        first = false;
                    } else {
                        dataUrl += `&${el[0]}=${el[1]}`;
                    }
                }
            });
        }
        return this.HttpClient.get(dataUrl);
    }

    public createServiceForBus(buId: number, data: Service): Observable<Service[]> {
        return this.HttpClient.post(this.helper.getAssistanceUrl(2) + `/bs/${buId}/services`, data);
    }

    public deleteServiceForBus(serviceId: number, bsid: number): Observable<any> {
        return this.HttpClient.delete(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/services/${serviceId}`);
    }

    //getContractorServicesForBus()
    public getContractorServicesForBus(bsid: number, contractorid: string): Observable<ServiceBUContractor[]> {
        return this.HttpClient.get(
            this.helper.getAssistanceUrl(2) + `/bus/${bsid}/contractors/${contractorid}/services`,
        );
    }

    public getBusinessEmployees(bsId: number): Observable<User[]> {
        return this.HttpClient.get(this.helper.getAssistanceUrl() + `/bs/${bsId}/employees`);
    }

    //updateContractorServicesForBus()
    public updateContractorServicesForBus(
        bsid: number,
        contractorid: string,
        data: ServiceBUContractor[],
    ): Observable<any> {
        return this.HttpClient.put(
            this.helper.getAssistanceUrl(2) + `/bus/${bsid}/contractors/${contractorid}/services`,
            data,
        );
    }

    // public getBSServices(bsid: number, serviceid: number): Observable<Service[]> {
    //     return this.HttpClient.get(
    //         this.helper.getAssistanceUrl() + `bs/${bsid}/services/${serviceid}/contractors`
    //     ).pipe(
    //         map(contr => {
    //             // debugger;
    //             return contr.map(subc => new Service().deserialize(subc));
    //             // new Contractor().deserialize(el);
    //             // return [];
    //         })
    //     );
    // }
}
