<!-- <header class="m-grid__item		m-header" data-minimize="minimize" data-minimize-offset="200" data-minimize-mobile-offset="200"
    appunwraptag="">
    <div class="m-header__top">
        <div class="m-container m-container--responsive m-container--xxl m-container--full-height m-page__container">
            <div class="m-stack m-stack--ver m-stack--desktop">
                <div class="m-stack__item m-brand">
                    <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                        <div class="m-stack__item m-stack__item--middle m-brand__logo">
                            <a routerLink="/index" class="m-brand__logo-wrapper">
                                <img alt="" src="./assets/demo/demo5/media/img/logo/logo1.svg" width="45px" />
                            </a>
                        </div>
                        <div class="m-stack__item m-stack__item--middle m-brand__tools">
                            <div class="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-left m-dropdown--align-push"
                                data-dropdown-toggle="click" aria-expanded="true">
                                <a href="#" class=" m-dropdown__toggle btn btn-outline-metal m-btn  m-btn--icon m-btn--pill">
                                    <span i18n="@@51479b0266bbc81a78c8fc766452bf4ef43ba570>
                                    Vaša ambulanta
                                    </span>
                                </a>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header> -->
<div class="h-100">
    <router-outlet></router-outlet>
</div>
<!-- <div class="m-brand__logo-wrapper d-flex pb-3 pt-3">
    <a href="https://eambulanta.si" target="_blank" class="m-auto">
        <img
            class="m-auto"
            alt="eAmbulanta - Programska rešitev za zasebne zdravstvene izvajalce"
            src="https://app.eambulanta.si/static/general/ea-logo-grey-color.png"
            width="140"
            height="100%"
            [ngbTooltip]="'Programska rešitev za zasebne zdravstvene izvajalce'"
        />
    </a>
</div> -->
