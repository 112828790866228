import { NoDblClickDirective } from './../../_directives/no-dbl-click.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// components
import { HeaderNavCaseComponent } from './header-nav-case/header-nav-case.component';
import { HomeComponent } from './../pages/home/home.component';
import { DefaultComponent } from '../pages/default/default.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { AsideLeftDisplayDisabledComponent } from '../pages/aside-left-display-disabled/aside-left-display-disabled.component';
import { QuickSidebarComponent } from './quick-sidebar/quick-sidebar.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { TooltipsComponent } from './tooltips/tooltips.component';
import { HeaderNotificationsComponent } from './header-nav/header-notifications/header-notifications.component';
import { HeaderNotificationElementComponent } from './header-nav/header-notification-element/header-notification-element.component';
// modules
import { ComponentsModule } from './../components/components.module';
// directives
import { HrefPreventDefaultDirective } from '../../_directives/href-prevent-default.directive';
import { UnwrapTagDirective } from '../../_directives/unwrap-tag.directive';
// services
import { NotificationsService } from './../../shared/services/notifications.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};
@NgModule({
    declarations: [
        DefaultComponent,
        HeaderNavComponent,
        AsideLeftDisplayDisabledComponent,
        QuickSidebarComponent,
        ScrollTopComponent,
        TooltipsComponent,
        HrefPreventDefaultDirective,
        UnwrapTagDirective,
        NoDblClickDirective,
        HomeComponent,
        HeaderNotificationsComponent,
        HeaderNavCaseComponent,
        HeaderNotificationElementComponent,
    ],
    exports: [
        DefaultComponent,
        HeaderNavComponent,
        AsideLeftDisplayDisabledComponent,
        QuickSidebarComponent,
        ScrollTopComponent,
        NoDblClickDirective,
        TooltipsComponent,
        HrefPreventDefaultDirective,
        HomeComponent,
        HeaderNavCaseComponent,
    ],
    providers: [
        NotificationsService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
    ],
    imports: [CommonModule, RouterModule, ComponentsModule, PerfectScrollbarModule],
})
export class LayoutModule {}
