import { Serializable } from './deserialize.service';

export class OrgUnit implements Serializable<OrgUnit> {
    externalId: string;
    name: string;
    subcontractors: any[];

    deserialize(input: any) {
        this.externalId = input.externalId;
        this.name = input.name;
        if (input.subcontractors) {
            this.subcontractors = input.subcontractors;
        }

        return this;
    }
}
