import { Serializable } from './deserialize.service';

export class BusinessSubjects implements Serializable<BusinessSubjects> {
    id: number;
    name: string;

    deserialize(input) {
        if (input) {
            this.id = input.id;
            this.name = input.name;
        }
        return this;
    }
}
