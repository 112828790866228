import { StorageService } from './../../../shared/services/storage.service';
import { environment } from './../../../../environments/environment';
import { Intercom } from 'ng-intercom';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
@Component({
    selector: '.m-grid__item.m-grid__item--fluid.m-grid.m-grid--ver-desktop.m-grid--desktop.m-body.m-page__container',
    templateUrl: './home.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
    constructor(private intercom: Intercom, private storage: StorageService) {
        // if (this.storage.getUserData().id === 9) {
        //     console.log('HJ loaded');
        //     (function(h, o, t, j, a, r) {
        //         h.hj =
        //             h.hj ||
        //             function() {
        //                 (h.hj.q = h.hj.q || []).push(arguments);
        //             };
        //         h._hjSettings = {
        //             hjid: 868323,
        //             hjsv: 6
        //         };
        //         a = o.getElementsByTagName('head')[0];
        //         r = o.createElement('script');
        //         r.async = 1;
        //         r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        //         a.appendChild(r);
        //     })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        // }
    }
    ngOnInit() {
        this.intercom.boot({
            app_id: environment.INTERCOM_KEY,
            name: this.storage.getUserData().name, // Full name
            email: this.storage.getUserData().email, // Email address
            created_at: moment().unix(), // Signup date as a Unix timestamp
            subcontractorId: this.storage.getUserData().subcontractorId,
            phone: this.storage.getUserData().phone,
            // Supports all optional configuration.
            widget: {
                activator: '#intercom',
            },
        });
    }
}
