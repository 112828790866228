import { UrlService } from './../auth/BS1/url-service.model';
import { Serializable } from '../deserialize.service';

export class Placeholder implements Serializable<Placeholder> {
    services: UrlService[];

    deserialize(input) {
        this.services = input.services;

        return this;
    }
}
