import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from './../model/notification.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    constructor(private helper: HelperService, private httpClient: HttpClientService) {}

    public getNotifications(bsid: number, from: string, to: string): Observable<Notification[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/notifications?from=${from}&to=${to}`);
    }

    public markNotificationAsSeen(bsid: number, notificationId: number) {
        return this.httpClient.put(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/notifications/${notificationId}`);
    }
    public markAllNotificationsAsSeen(bsid: number) {
        return this.httpClient.put(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/notifications`);
    }

    public test(): boolean {
        return true;
    }
}
