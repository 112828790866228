import { Serializable } from '../deserialize.service';

export class CustomerCommentEdit implements Serializable<CustomerCommentEdit> {
    public comment: string;
    public bsid: number;

    deserialize(input) {
        // debugger;
        this.comment = input.comment;
        this.bsid = input.bsid;
        return this;
    }
}
