import { ToastrService } from 'ngx-toastr';
import { AddCustomerDocument } from './../../../pages/_base/base-case/_actions/base-case.actions';
import { Store } from '@ngxs/store';
import { DocumentSign } from './../../../../shared/model/document/documentSign.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, ViewChild, ViewContainerRef } from '@angular/core';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-media-view-popup',
    templateUrl: './media-view-popup.component.html',
    styleUrls: ['./media-view-popup.component.scss'],
})
export class MediaViewPopupComponent implements OnInit {
    @Input()
    set setdocumentFile(data: any) {
        if (data) {
            if (data.type == 'text/plain') {
                var reader = new FileReader();
                const that = this;
                reader.onload = function() {
                    that.documentFile = reader.result;
                };
                reader.readAsText(data, 'ISO-8859-1');
            } else {
                this.documentFile = URL.createObjectURL(data);
                this.blobData = data;
                this.iframe.element.nativeElement.src = this.documentFile;
            }
        }
    }
    @Input()
    documentType: any;

    @Input()
    fileName: string;

    @Input()
    signature: DocumentSign;

    //iframe for printing
    @ViewChild('print', { read: ViewContainerRef, static: true })
    iframe: any;

    public blobData: any;
    public documentFile: any;
    //if authTemplateFile is present --> append to case when printing/saving
    public authTemplateFile: File;
    constructor(public activeModal: NgbActiveModal, private store: Store, private mfToast: ToastrService) {}

    ngOnInit() {}

    public downloadFile(): void {
        FileSaver.saveAs(this.blobData, this.fileName);
        if (this.authTemplateFile) {
            //append document to case
            this.store.dispatch(new AddCustomerDocument(this.authTemplateFile)).subscribe();
        }
    }

    public printFile(): void {
        try {
            //Chrome
            this.iframe.element.nativeElement.contentWindow.focus();
            this.iframe.element.nativeElement.contentWindow.print();
            if (this.authTemplateFile) {
                //append document to case
                this.store.dispatch(new AddCustomerDocument(this.authTemplateFile)).subscribe();
            }
        } catch (err) {
            //Firefox
            this.mfToast.warning('Vaš brskalnik ne podpira tiskanja.');
            this.downloadFile();
        }
    }
}
