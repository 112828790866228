import { User } from './../model/user.model';
import { DocumentSign } from './../model/document/documentSign.model';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { NgxPermissionsService } from 'ngx-permissions';
import { Injectable, Inject } from '@angular/core';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaViewPopupComponent } from '../../theme/components/popup/media-view-popup/media-view-popup.component';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { Business } from '../model/business.model';
import { Contractor } from '../model/contractor.model';

@Injectable()
export class HelperService {
    public hiddeHeader: BehaviorSubject<any> = new BehaviorSubject(false);
    public menuList: BehaviorSubject<any> = new BehaviorSubject({});
    public disabledNavigation: boolean = false;
    public API_DATE_PATERN: string = 'YYYY-MM-DD';

    private dateSelectorLocale: any = {
        format: 'DD.MM.YYYY',
        cancelLabel: 'Prekliči',
        applyLabel: 'Potrdi',
        customRangeLabel: 'Izberi ročno',
        daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
        monthNames: [
            'Januar',
            'Februar',
            'Marec',
            'April',
            'Maj',
            'Junij',
            'Julij',
            'Avgust',
            'September',
            'Oktober',
            'November',
            'December',
        ],
        firstDay: 1,
    };

    constructor(private storage: StorageService, private modalService: NgbModal, public route: Router) {}

    getAuthUrl(): string {
        //'http://10.50.40.210:8080/auth/oauth/token
        return environment.auth.protocol + environment.auth.rootUrl + environment.auth.port + environment.auth.url;
    }

    getCaptchaUrl(): string {
        return environment.auth.protocol + environment.auth.rootUrl + environment.auth.port + environment.captcha.url;
    }

    getAccessUrl(): string {
        //http://localhost:8092/booking/v2/assistance/api/public
        return (
            environment.access.protocol + environment.access.rootUrl + environment.access.port + environment.access.url
        );
    }

    getErpUrl(version: number = 1): string {
        //http://10.50.40.210:9090/dentist/api
        return (
            environment.erp['v' + version].protocol +
            environment.erp['v' + version].rootUrl +
            environment.erp['v' + version].apiPort +
            environment.erp['v' + version].apiUrl
        );
    }

    getAssistanceUrl(version: number = 1): string {
        //http://10.50.40.210:9090/dentist/api
        return (
            environment.assistance['v' + version].protocol +
            environment.assistance['v' + version].rootUrl +
            environment.assistance['v' + version].port +
            environment.assistance['v' + version].url
        );
    }

    getRootUrl(version: number = 1): string {
        //http://10.50.40.210:9090/dentist/api
        return (
            environment.root['v' + version].protocol +
            environment.root['v' + version].rootUrl +
            environment.root['v' + version].port +
            environment.root['v' + version].url
        );
    }

    getDocumentUrl(version: number = 1): string {
        //http://localhost:9090/booking/docapi
        return (
            environment.document['v' + version].protocol +
            environment.document['v' + version].rootUrl +
            environment.document['v' + version].port +
            environment.document['v' + version].url
        );
    }

    getAuthorizationUrl(version: number = 1): string {
        //http://10.50.40.210:9090/booking/authorization/api
        return (
            environment.authorization['v' + version].protocol +
            environment.authorization['v' + version].rootUrl +
            environment.authorization['v' + version].port +
            environment.authorization['v' + version].url
        );
    }

    getOdooUrl(): string {
        //http://10.50.40.210:9090/dentist/api
        return environment.odoo.protocol + environment.odoo.rootUrl + environment.odoo.port + environment.odoo.url;
    }

    getAirCallUrl(): string {
        return (
            environment.aircall.protocol +
            environment.aircall.rootUrl +
            environment.aircall.port +
            environment.aircall.url
        );
    }
    // getPrintServerUrl(): string {
    //     //http://localhost:9090/booking/papi
    //     return (
    //         environment.printserver.protocol +
    //         environment.printserver.rootUrl +
    //         environment.printserver.port +
    //         environment.printserver.url
    //     );
    // }

    // getTaskUrl(): string {
    //     //http://localhost:9090/booking/papi
    //     return environment.task.protocol + environment.task.rootUrl + environment.task.port + environment.task.url;
    // }

    // getAccountingUrl(): string {
    //     //http://localhost:9090/booking/aapi
    //     return (
    //         environment.accounting.protocol +
    //         environment.accounting.rootUrl +
    //         environment.accounting.port +
    //         environment.accounting.url
    //     );
    // }

    // getVisitUrl(): string {
    //     //http://localhost:9090/booking/visitapi
    //     return environment.visit.protocol + environment.visit.rootUrl + environment.visit.port + environment.visit.url;
    // }

    // getCommunicationUrl(): string {
    //     //http://localhost:9090/booking/communicationapi
    //     return (
    //         environment.communication.protocol +
    //         environment.communication.rootUrl +
    //         environment.communication.port +
    //         environment.communication.url
    //     );
    // }

    // getStatsUrl(): string {
    //     //http://localhost:9090/booking/communicationapi
    //     return (
    //         environment.statistics.protocol +
    //         environment.statistics.rootUrl +
    //         environment.statistics.port +
    //         environment.statistics.url
    //     );
    // }

    // getSurveysUrl(): string {
    //     //http://localhost:9090/booking/surveys
    //     return (
    //         environment.surveys.protocol +
    //         environment.surveys.rootUrl +
    //         environment.surveys.port +
    //         environment.surveys.url
    //     );
    // }

    // getAccessUrl(): string {
    //     //http://localhost:9090/booking/access
    //     return (
    //         environment.access.protocol + environment.access.rootUrl + environment.access.port + environment.access.url
    //     );
    // }

    uuid(): string {
        let i, random;
        let result = '';
        // for (i = 0; i < 32; i++)
        for (i = 0; i < 8; i++) {
            random = (Math.random() * 16) | 0;
            if (i === 8 || i === 12 || i === 16 || i === 20) {
                result += '-';
            }
            result += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
        }

        return result;
    }

    // public showMessage(text: string): void {
    //     alert('message helper');
    //     // this.snackBar.open(text, 'Obvestilo', {
    //     //     duration: 8000,
    //     //     verticalPosition: 'top'
    //     // });
    // }

    public momentToNgbDate(mdate: Moment): any {
        return {
            day: mdate.date(),
            month: mdate.month() + 1,
            year: mdate.year(),
        };
    }

    public displayAlert(title: string, content: string, settings?: any): Promise<any> {
        return swal.fire(
            Object.assign(
                {
                    title: title,
                    html: content,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Potrdi',
                    cancelButtonText: 'Prekliči',

                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success m-btn m-btn--custom m-btn--pill',
                    confirmButtonColor: null,
                    cancelButtonClass: 'btn btn-secondary m-btn m-btn--custom m-btn--pill',
                    cancelButtonColor: null,
                },
                settings,
            ),
        );
    }

    public precisionRound(number, precision) {
        const factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    }

    public getDateRangeSettngs(
        startDate: moment.Moment = moment().subtract(1, 'week'),
        endDate: moment.Moment = moment(),
        settings?: any,
    ): any {
        return Object.assign(
            {
                autoApply: true,
                locale: {
                    format: 'DD.MM.YYYY',
                    cancelLabel: 'Prekliči',
                    applyLabel: 'Potrdi',
                    customRangeLabel: 'Izberi ročno',
                    daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
                    monthNames: [
                        'Januar',
                        'Februar',
                        'Marec',
                        'April',
                        'Maj',
                        'Junij',
                        'Julij',
                        'Avgust',
                        'September',
                        'Oktober',
                        'November',
                        'December',
                    ],
                    firstDay: 1,
                },
                alwaysShowCalendars: false,
                ranges: {
                    Danes: [moment(), moment()],
                    'Zadnjih 7 dni': [moment().subtract(1, 'week'), moment()],
                    'Zadnji mesec': [moment().subtract(1, 'month'), moment()],
                },
                startDate: startDate,
                endDate: endDate,
            },
            settings,
        );
    }

    public getDateSettngs(): any {
        return {
            locale: this.dateSelectorLocale,
            singleDatePicker: true,
            autoApply: true,
            alwaysShowCalendars: false,
        };
    }

    public getDateSelectorSettngs(): any {
        return {
            locale: {
                format: 'DD.MM.YYYY',
                cancelLabel: 'Prekliči',
                applyLabel: 'Potrdi',
                customRangeLabel: 'Izberi ročno',
                daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
                monthNames: [
                    'Januar',
                    'Februar',
                    'Marec',
                    'April',
                    'Maj',
                    'Junij',
                    'Julij',
                    'Avgust',
                    'September',
                    'Oktober',
                    'November',
                    'December',
                ],
                firstDay: 1,
            },
            alwaysShowCalendars: true,
            singleDatePicker: true,
        };
    }

    public getDateRangeSelectorSettngs(): any {
        return {
            locale: {
                format: 'DD.MM.YYYY',
                cancelLabel: 'Prekliči',
                applyLabel: 'Potrdi',
                customRangeLabel: 'Izberi ročno',
                daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
                monthNames: [
                    'Januar',
                    'Februar',
                    'Marec',
                    'April',
                    'Maj',
                    'Junij',
                    'Julij',
                    'Avgust',
                    'September',
                    'Oktober',
                    'November',
                    'December',
                ],
                firstDay: 1,
            },
            alwaysShowCalendars: true,
            singleDatePicker: false,
        };
    }

    public getDateSelectorSettngsMinDate(): any {
        return {
            locale: {
                format: 'DD.MM.YYYY',
                cancelLabel: 'Prekliči',
                applyLabel: 'Potrdi',
                customRangeLabel: 'Izberi ročno',
                daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
                monthNames: [
                    'Januar',
                    'Februar',
                    'Marec',
                    'April',
                    'Maj',
                    'Junij',
                    'Julij',
                    'Avgust',
                    'September',
                    'Oktober',
                    'November',
                    'December',
                ],
                firstDay: 1,
            },
            alwaysShowCalendars: true,
            singleDatePicker: true,
            minDate: moment(),
        };
    }

    public getDateTimeSelectorSettngs(): any {
        return {
            locale: {
                format: 'DD.MM.YYYY HH:mm',
                cancelLabel: 'Prekliči',
                applyLabel: 'Potrdi',
                customRangeLabel: 'Izberi ročno',
                daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
                monthNames: [
                    'Januar',
                    'Februar',
                    'Marec',
                    'April',
                    'Maj',
                    'Junij',
                    'Julij',
                    'Avgust',
                    'September',
                    'Oktober',
                    'November',
                    'December',
                ],
                firstDay: 1,
            },
            timePicker: true,
            timePicker24Hour: true,
            timePickerIncrement: 5,
            alwaysShowCalendars: true,
            singleDatePicker: true,
        };
    }

    public parseJWT(token: any): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    public getAppVersion(): string {
        return environment.VERSION;
    }

    public isDifferentAppVersion(): boolean {
        if (!this.storage.getAppData()) {
            return false;
        }

        if (environment.VERSION === this.storage.getAppData().version) {
            return false;
        }
        return true;
    }

    public timeFrom24(minutes: number): string {
        return moment()
            .startOf('day')
            .add(minutes, 'minutes')
            .format('HH:mm');
    }

    public minFrom24(time: number): number {
        return moment(time, 'HH:mm').diff(moment().startOf('day'), 'minutes');
    }

    public numberToWeekDayName(dayNumber: number): string {
        switch (dayNumber) {
            case 1:
                return 'MONDAY';
            case 2:
                return 'TUESDAY';
            case 3:
                return 'WEDNESDAY';
            case 4:
                return 'THURSDAY';
            case 5:
                return 'FRIDAY';
            case 6:
                return 'SATURDAY';
            case 7:
                return 'SUNDAY';
        }
    }

    public weekDayNameToNumber(dayName: string): number {
        switch (dayName) {
            case 'MONDAY':
                return 1;
            case 'TUESDAY':
                return 2;
            case 'WEDNESDAY':
                return 3;
            case 'THURSDAY':
                return 4;
            case 'FRIDAY':
                return 5;
            case 'SATURDAY':
                return 6;
            case 'SUNDAY':
                return 7;
        }
    }

    public generateMailForContractor(contractor: Contractor, bs, user: User) {
        let mailString = '';
        if (!environment.production) {
            mailString += '<b> TESTNI KLIC (NI IZ PRODUKCIJE)!!!! </b> <br>';
        }
        mailString += `
        Zahtevek za povezovanje IZS na poslovni račun asistenta <br>
        ---------------------------------------------------------------------- <br>
        Izvajalec: <b> ${contractor.name} </b> <br>
        Zavarovalnica: <b> ${bs.name} </b> <br>
        Uporabnik v asistentu, ki zahteva povezavo IZS: <b> ${user.name} ${user.surname ? user.surname : ''}</b> (${
            user.email ? user.email : 'Nima definiranega emaila'
        }) <br> ---------------------------------------------------------------------- <br> 
        <b> Podatki izvajalca iz CRM: </b> <br>`;
        for (const [key, value] of Object.entries(contractor)) {
            if (key == 'his') {
                mailString += `${key}: ${JSON.stringify(value)} <br>`;
            } else {
                mailString += `${key}: ${value} <br>`;
            }
        }
        return mailString;
    }

    public parseMFError(error: any): any {
        if (error) {
            return error.errors.map((er) => {
                return er.msg;
            });
        }
        return '';
    }

    public checkStructError(data: any, code: string): boolean {
        if (data.error && data.error.errors && data.error.errors.length > 0) {
            if (data.error.errors[0].code === code) {
                return true;
            }
        }
        return false;
    }

    public getStructErrorCode(data: any): string {
        if (data.error && data.error.errors && data.error.errors.length > 0) {
            return data.error.errors[0].code;
        }
        return 'UNKNOWN CODE';
    }

    public getStructErrorMessage(data: any): string {
        if (data.error && data.error.errors && data.error.errors.length > 0) {
            return data.error.errors[0].msg;
        }
        return 'UNKNOWN MESSAGE';
    }

    public openDocumentViewer(data: any, type: any, filename: string, print?: boolean, signature?: DocumentSign): void {
        if (type === 'application/pdf' || type.includes('image') || type == 'text/plain') {
            const modalMedidaView = this.modalService.open(MediaViewPopupComponent, {
                size: 'lg',
                windowClass: 'mf-transparent-popup',
            });
            modalMedidaView.result.then(
                (result) => {},
                (newCustomer) => {},
            );
            modalMedidaView.componentInstance.setdocumentFile = data;
            modalMedidaView.componentInstance.fileName = filename;
            modalMedidaView.componentInstance.documentType = type;
            modalMedidaView.componentInstance.signature = signature;
            if (print) {
                modalMedidaView.componentInstance.printFile();
            }
        } else {
            console.log('Neznan format dokumenta, prenašam!');
            FileSaver.saveAs(data, filename);
        }
    }
    public openDocumentViewerAuthTemplate(data: any, type: any, filename: string, file: File): void {
        //razlika je da nosi sabo template file ki ga pripneš na avtorizacijo, ko daš print/download
        if (type === 'application/pdf' || type.includes('image')) {
            const modalMedidaView = this.modalService.open(MediaViewPopupComponent, {
                size: 'lg',
                windowClass: 'mf-transparent-popup',
            });
            modalMedidaView.result.then(
                (result) => {},
                (newCustomer) => {},
            );
            modalMedidaView.componentInstance.setdocumentFile = data;
            modalMedidaView.componentInstance.fileName = filename;
            modalMedidaView.componentInstance.documentType = type;
            modalMedidaView.componentInstance.authTemplateFile = file;
        } else {
            console.log('Neznan format dokumenta, prenašam!');
            FileSaver.saveAs(data, filename);
        }
    }

    public replaceSlahsForDolar(data: string): string {
        if (!data) {
            return data;
        }
        return data.split('/').join('$$$');
        //return data.replace('/', '$$$$$$');
    }

    public getNavigationPath(route: any[]): any[] {
        const prefix: string = _.get(this.storage.getAppData(), 'type', '');
        if (route.length > 0) {
            route[0] = `/${prefix}${route[0]}`;
        }
        return route;
    }

    public navigate(route: any[]): void {
        const newRoute = this.getNavigationPath(route);
        this.route.navigate(newRoute);
    }

    public randomNumber(from: number, to: number): number {
        return Math.floor(Math.random() * to) + from;
    }

    // filter za en input
    public filterArrayByInputAndTypes(data: any[], event: any, filterBy: string[]): any[] {
        const inputString: string[] = _.get(event, 'target.value', '')
            .toLowerCase()
            .trim(' ')
            .split(' ');

        data = data.filter((element) => {
            const filterString = JSON.stringify(
                filterBy.map((el) => {
                    return element[el];
                }),
            );
            return inputString.every((elementInput) => {
                return filterString.toLowerCase().indexOf(elementInput) !== -1 ? true : false;
            });
        });
        return data;
    }

    // filter za več inputov
    public filterArrayByCustomFilter(data: any[], filters: any[]) {
        filters = filters.filter((fil) => fil.value !== '');

        data = data.filter((element) => {
            return filters.every((fil) => {
                if (fil.subtypes === undefined) {
                    if (_.isNil(element[fil.type])) {
                        return null;
                    }
                    return element[fil.type]
                        .toString()
                        .toLowerCase()
                        .indexOf(fil.value) !== -1
                        ? true
                        : false;
                } else {
                    if (_.isNil(element[fil.type])) {
                        return false;
                    }
                    const filterString = JSON.stringify(
                        fil.subtypes.map((subfil) => {
                            if (fil.caseElementType === 'object') {
                                if (_.isNil(element[fil.type][subfil])) {
                                    return null;
                                }
                                return element[fil.type][subfil].toString().toLowerCase();
                            } else {
                                return element[fil.type].map((el) => {
                                    if (el[subfil]) {
                                        return el[subfil].toString().toLowerCase();
                                    }
                                    return '';
                                });
                            }
                        }),
                    );
                    const inputString: string[] = fil.value
                        .toString()
                        .toLowerCase()
                        .split(' ');
                    return inputString.every((inputElement) => {
                        return filterString.indexOf(inputElement) !== -1 ? true : false;
                    });
                }
            });
        });

        return data;
    }

    public generateSlackObj(
        errorType: string,
        bpi: string = '??',
        contractor: string = '??',
        service: string = '??',
        color: string = 'ffde00',
    ): any {
        //tilen: UNYVCA407
        //meta: UG4TA1DU5
        //denis: UA2LKRD9V
        //anze: U9YKT0VU4
        let slackData: any = {};
        let notifyPerson: string = '<@UNYVCA407>';
        if (environment.production) {
            notifyPerson = '<@UNYVCA407>, <@UA2LKRD9V>, <@U9YKT0VU4>';
        }

        try {
            let user: string = 'Unknown';
            if (this.storage.getUserData()) {
                user = this.storage.getUserData().name + ' (' + this.storage.getUserData().id + ')';
            }
            slackData = {
                blocks: [
                    {
                        type: 'section',
                        text: {
                            type: 'mrkdwn',
                            text: `${notifyPerson}, nekaj ne štima. Please help and save the day! :sunglasses:`,
                        },
                    },
                ],
                attachments: [
                    {
                        fallback: `Napaka: ${errorType}; BPI: ${bpi}`,
                        color: `#${color}`,
                        fields: [
                            {
                                title: 'Napaka',
                                value: `${errorType}`,
                                short: true,
                            },
                            {
                                title: 'BPI',
                                value: `${bpi}`,
                                short: true,
                            },
                        ],
                    },
                    {
                        fallback: `Partner: ${
                            this.storage.getBusinessSubject().name
                        }; Prijavljen uporabnik: ${user}; Izvajalec: ${contractor}; Storitev: ${service}`,
                        color: `#${color}`,
                        fields: [
                            {
                                title: 'Partner',
                                value: `${this.storage.getBusinessSubject().name}`,
                                short: true,
                            },
                            {
                                title: 'Prijavljen uporabnik',
                                value: `${user}`,
                                short: true,
                            },
                            {
                                title: 'Izvajalec',
                                value: `${contractor}`,
                                short: true,
                            },
                            {
                                title: 'Storitev',
                                value: `${service}`,
                                short: true,
                            },
                        ],
                    },
                ],
            };
        } catch (e) {
            return { text: errorType + ' ```' + e + '```' };
        }
        return slackData;
    }

    public getBusiness(bs: any): Business {
        //od enih komponent dobiš Business, od drugih pa samo bsId
        let tmp = bs.id;
        if (tmp === undefined) {
            tmp = bs;
        }
        switch (tmp) {
            case 1:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Generali',
                    iconPath: '../../../assets/app/media/img/bu/generali.svg',
                });
            case 2:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Triglav',
                    iconPath: '../../../assets/app/media/img/bu/triglav.svg',
                });
            case 3:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Merkur',
                    iconPath: '../../../assets/app/media/img/bu/merkur.svg',
                });
            case 4:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'M zavarovalnica',
                    iconPath: '../../../assets/app/media/img/bu/medifit.png',
                });
            default:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: `${bs.name}`,
                    iconPath: '../../../assets/app/media/img/bu/eambulanta.svg',
                });
        }
    }

    isIE() {
        const ua = navigator.userAgent.toLowerCase();
        //const ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; .NET CLR 2.0.50727; .NET CLR 3.0.30729; .NET CLR 3.5.30729; InfoPath.2; rv:11.0) like Gecko'.toLowerCase();

        const msie = ua.indexOf('msie ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        const trident = ua.indexOf('trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        const edge = ua.indexOf('edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    public deepCopy(obj: any): any {
        if (obj) {
            return JSON.parse(JSON.stringify(obj));
        }
        return obj;
    }
}

// slackData = {
//     blocks: [
//         {
//             type: 'section',
//             text: {
//                 type: 'mrkdwn',
//                 text: `${notifyPerson}, , nekaj ne štima. Please help and save the day! :sunglasses:`,
//             },
//         },
//         {
//             type: 'section',
//             text: {
//                 type: 'mrkdwn',
//                 text: `${errorType}`,
//             },
//         },
//         {
//             type: 'section',
//             text: {
//                 type: 'mrkdwn',
//                 text: `*BPI:* ${bpi}`,
//             },
//         },
//         {
//             type: 'section',
//             fields: [
//                 {
//                     type: 'mrkdwn',
//                     text: `*Partner:*\n ${this.storage.getBusinessSubject().name}`,
//                 },
//                 {
//                     type: 'mrkdwn',
//                     text: `*Prijavljen uporabnik:*\n ${user}`,
//                 },
//                 {
//                     type: 'mrkdwn',
//                     text: `*Izvajalec:* \n ${contractor}`,
//                 },
//                 {
//                     type: 'mrkdwn',
//                     text: `*Storitev:* \n ${service}`,
//                 },
//             ],
//         },
//     ],
// };
