import { CustomerService } from './../../../../shared/services/customer.service';
import { BusinessUserService } from './../../../../shared/services/business-user.service';
import { Customer } from './../../../../shared/model/customer.model';
import { TaskService } from './../../../../shared/services/task.service';
import { Task } from './../../../../shared/model/task.model';
import { User } from './../../../../shared/model/user.model';
import { HelperService } from './../../../../shared/services/helper.service';
import { StorageService } from './../../../../shared/services/storage.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { takeUntil, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import * as _ from 'lodash';

import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-edit-task-popup',
    templateUrl: './edit-task-popup.component.html',
    styles: [],
})
export class EditTaskPopupComponent implements OnInit {
    constructor(
        private fb: FormBuilder,
        private storage: StorageService,
        private helper: HelperService,
        private taskRest: TaskService,
        private toastr: ToastrService,
        public activeModal: NgbActiveModal,
        public businessRest: BusinessUserService,
        public custRest: CustomerService,
    ) {
        this.calendarOptions = this.helper.getDateSettngs();
        this.calendarOptions.autoUpdateInput = false;
        this.calendarOptions.minDate = moment();
    }
    public allCustomers: Customer[];
    public customer: Customer;
    public inputDelegator: User;
    public inputDate: any;
    public inputDescription: any;
    public inputDetails: any;
    public addTaskForm: FormGroup;
    public allEmployees: User[];
    public calendarOptions: any = {};
    public formSubmited = false;
    public tasks: Task[] = [];
    public data: any;
    public newTask: boolean = true;
    public id: any;
    public editCustomer: boolean = false;
    public dateValid: boolean = true;

    ngOnInit() {
        this.createTasksForm();
        this.businessRest.getBusinessEmployees(this.storage.getBusinessSubject().id).subscribe((users: User[]) => {
            this.allEmployees = users;
        });
        // this.getCustomerTasks();
        this.getCustomers();
    }

    private createTasksForm(): void {
        this.addTaskForm = this.fb.group({
            description: this.fb.control(
                { value: this.inputDescription != undefined ? this.inputDescription : '', disabled: false },
                Validators.required,
            ),
            dueDate: this.fb.control(
                {
                    value:
                        this.inputDate == undefined
                            ? moment()
                                  .add(1, 'day')
                                  .format('DD.MM.YYYY')
                            : moment(this.inputDate).format('DD.MM.YYYY'),
                    disabled: false,
                },
                Validators.required,
            ),
            employee: this.fb.control({
                value: this.inputDelegator != undefined ? this.inputDelegator : this.storage.getUserData(),
                disabled: false,
            }),
            details: this.fb.control({
                value: this.inputDetails != null ? this.inputDetails : '',
                disabled: false,
            }),
            customer: this.fb.control(
                {
                    value: this.customer != undefined ? this.customer.name + ' ' + this.customer.surname : '',
                    disabled: !this.newTask || this.editCustomer,
                },
                Validators.required,
            ),
        });
        debugger;
    }
    public setDate(event): void {
        this.addTaskForm.controls.dueDate.setValue(event.start.format('DD.MM.YYYY'));
        this.addTaskForm.markAsDirty();
    }
    public getCustomers() {
        this.custRest.getBusinessSubjectCustomer(this.storage.getBusinessSubject().id).subscribe((res) => {
            this.allCustomers = res;
        });
    }

    public searchEmployee = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map((term) =>
                term.length < 1
                    ? []
                    : this.allEmployees
                          .filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
                          .slice(0, 10),
            ),
        );
    public searchCustomer = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map((term) =>
                term.length < 1
                    ? []
                    : this.allCustomers
                          .filter((v) =>
                              v.name != null ? v.name.toLowerCase().indexOf(term.toLowerCase()) > -1 : false,
                          )
                          .slice(0, 10),
            ),
        );
    public employeeFormatter = (x: { name: string }) => x.name;
    // public customerFormatter = (x: { name: string; surname: string }) => x.name + ' ' + x.surname;
    public customerFormatter(x: { name: string; surname: string }) {
        if (x.name) {
            return x.name + ' ' + x.surname;
        }
    }
    // getCustomerTasks() {
    //     this.taskRest
    //         .getCustomerTasks(this.storage.getBusinessSubject().id, this.customer.medifitId)
    //         .pipe(
    //             map((results) => {
    //                 return results.sort((a, b) => (a.dueDate > b.dueDate ? 1 : b.dueDate > a.dueDate ? -1 : 0));
    //             }),
    //         )
    //         .subscribe((res) => {
    //             this.tasks = res;
    //         });
    // }
    public close(): void {
        this.activeModal.close();
    }
    public addCustomer(event: any) {
        console.log(event);
    }

    // public onSubmitNewTask(type?: any): void {
    //     debugger;
    //     this.formSubmited = true;
    //     if (this.addTaskForm.valid) {
    //         const formData = this.addTaskForm.value;
    //         formData.taskCreated = moment().toDate();
    //         // dodam  3 ure zaradi timezona... +2
    //         if (formData.dueDate) {
    //             formData.dueDate = moment(formData.dueDate, 'DD.MM.YYYY')
    //                 .add(3, 'hour')
    //                 .toDate();
    //         }
    //         debugger;
    //         const currentUser = this.storage.getUserData();
    //         formData.taskCreatedBy = currentUser.name;

    //         if (formData.employee) {
    //             formData.delegatedId = formData.employee.id;
    //             formData.delegatedName = formData.employee.name;
    //         } else {
    //             formData.delegatedId = currentUser.id;
    //             formData.delegatedName = currentUser.name;
    //         }
    //         // if (formData.customer) {
    //         //     this.customer.medifitId = formData.customer.medifitId;
    //         // }

    //         formData.status = 1;
    //         const data = {
    //             task: new Task().deserialize(formData),
    //             customer: formData['customer'],
    //         };
    //         //based on newTask do POST or PUT
    //         debugger;

    //         if (type === 'delete') {
    //             this.taskRest.deleteTask(this.storage.getBusinessSubject().id, this.id).subscribe(
    //                 () => {
    //                     data.task.status = 2;
    //                     this.toastr.success('Opravilo zbrisano.');
    //                 },
    //                 (error) => {
    //                     this.toastr.error('Napaka pri brisanju opravila.');
    //                 },
    //             );
    //             this.activeModal.close();
    //         } else {
    //             let dat: number = this.customer != undefined ? this.customer.medifitId : data.customer.medifitId;
    //             this.taskRest.addCustomerTask(this.storage.getBusinessSubject().id, dat, data.task).subscribe(
    //                 (res) => {
    //                     data.task.id = res;
    //                     this.toastr.success('Novo opravilo uspešno dodano.');
    //                     // this.getCustomerTasks();
    //                     this.addTaskForm.reset();
    //                 },
    //                 (error) => {
    //                     this.toastr.error('Napaka pri dodajanju opravila.');
    //                     debugger;

    //                     // if (error.error) {
    //                     //     this.toastr.error(this.helper.parseMFError(error.error));
    //                     // }
    //                 },
    //             );

    //             this.activeModal.dismiss(data);
    //             debugger;
    //         }
    //     }
    // }
    public onSubmitNewTask(type?: any): void {
        debugger;
        this.formSubmited = true;
        if (this.addTaskForm.valid) {
            const formData = this.addTaskForm.value;
            formData.taskCreated = moment().toDate();
            // dodam  3 ure zaradi timezona... +2
            if (formData.dueDate) {
                formData.dueDate = moment(formData.dueDate, 'DD.MM.YYYY')
                    .add(3, 'hour')
                    .toDate();
            }
            debugger;
            const currentUser = this.storage.getUserData();
            formData.taskCreatedBy = currentUser.name;

            if (formData.employee) {
                formData.delegatedId = formData.employee.id;
                formData.delegatedName = formData.employee.name;
            } else {
                formData.delegatedId = currentUser.id;
                formData.delegatedName = currentUser.name;
            }
            // if (formData.customer) {
            //     this.customer.medifitId = formData.customer.medifitId;
            // }

            formData.status = 1;
            const data = {
                task: new Task().deserialize(formData),
                customer: formData['customer'],
            };

            if (type === 'delete') {
                this.taskRest.deleteTask(this.storage.getBusinessSubject().id, this.id).subscribe(
                    (res) => {
                        data.task.status = 2;
                        this.toastr.success('Opravilo uspešno zbrisano.');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.toastr.error('Napaka pri brisanju opravila.');
                        this.activeModal.close();
                    },
                );
            } else if (type === 'save') {
                let dat: number = this.customer != undefined ? this.customer.medifitId : data.customer.medifitId;
                data.task.id = this.id;
                this.taskRest.editCustomerTask(this.storage.getBusinessSubject().id, dat, data.task).subscribe(
                    (res) => {
                        data.task.id = res;
                        this.toastr.success('Opravilo uspešno urejeno.');
                        this.addTaskForm.reset();
                        this.activeModal.close();
                    },
                    (error) => {
                        this.toastr.error('Napaka pri shranjevanju opravila.');
                        this.activeModal.close();
                    },
                );
            } else {
                let dat: number = this.customer != undefined ? this.customer.medifitId : data.customer.medifitId;
                this.taskRest.addCustomerTask(this.storage.getBusinessSubject().id, dat, data.task).subscribe(
                    (res) => {
                        data.task.id = res;
                        this.toastr.success('Novo opravilo uspešno dodano.');
                        // this.getCustomerTasks();
                        this.addTaskForm.reset();
                        this.activeModal.dismiss(data);
                    },
                    (error) => {
                        this.toastr.error('Napaka pri dodajanju opravila.');
                        this.activeModal.close();

                        // if (error.error) {
                        //     this.toastr.error(this.helper.parseMFError(error.error));
                        // }
                    },
                );
            }
        }
    }
}
