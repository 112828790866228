export enum AppModulesType {
    SEPARATED_CONTRACTORS = 'SEPARATED_CONTRACTORS',
    SHARED_DOCUMENTS_UNSAFECOMM = 'SHARED_DOCUMENTS_UNSAFECOMM',
    NOTIFICATION_SMS = 'NOTIFICATION_SMS',
    SURVEY_MODULE = 'SURVEY_MODULE',
    SURVEY_CUSTOM_MODULE = 'SURVEY_CUSTOM_MODULE',
    HALODOKTOR_STAT_MODULE = 'HALODOKTOR_STAT_MODULE',
    CUSTOMER_CONSENT_MODULE = 'CUSTOMER_CONSENT_MODULE',
    TELEPHONY = 'TELEPHONY',
    ANALYTICS_MODULE = 'ANALYTICS_MODULE',
    SEND_SMS_IF_BLANK_MAIL = 'SEND_SMS_IF_BLANK_MAIL',
    SEND_2FA_NOTIFICATIONS = 'SEND_2FA_NOTIFICATIONS',
    MISSING_SLOTS_NOTIFICATIONS = 'MISSING_SLOTS_NOTIFICATIONS',
}
