// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    VERSION: require('../../package.json').version,
    AUTHPASS: btoa('test:test'),
    FILE_UPLOAD_MAX_SIZE: 5097152,
    GUI_TYPE: '1',
    INTERCOM_KEY: 'qf1qluis',
    TAWK_KEY: '5f10115f5b59f94722bad505',
    auth: {
        protocol: 'http://',
        rootUrl: '10.230.33.13',
        port: ':8080',
        url: '/booking/oauth/token',
    },
    captcha: {
        url: '/booking/captcha',
    },
    erp: {
        v1: {
            protocol: 'http://',
            rootUrl: '10.230.33.13',
            apiPort: ':9090',
            apiUrl: '/booking/erp/api',
        },
        v2: {
            protocol: 'http://',
            rootUrl: '10.230.33.13',
            apiPort: ':9090',
            apiUrl: '/booking/erp/api',
        },
    },
    assistance: {
        v1: {
            protocol: 'http://',
            rootUrl: '10.230.33.13',
            port: ':9090',
            url: '/booking/assistance/api',
        },
        v2: {
            protocol: 'http://',
            rootUrl: '10.230.33.13',
            port: ':8092',
            url: '/booking/v2/assistance/api',
        },
        v3: {
            protocol: 'http://',
            rootUrl: '10.230.33.13',
            port: ':5550',
            url: '/booking/asv2/assistance/api',
        },
    },
    odoo: {
        protocol: 'http://',
        rootUrl: '10.230.33.13',
        port: ':5550',
        url: '/booking/asv2/odo/api',
    },
    document: {
        v1: {
            protocol: 'http://',
            rootUrl: '10.230.33.13',
            port: ':9090',
            url: '/booking/erp/docapi',
        },
        v2: {
            protocol: 'http://',
            rootUrl: '10.230.33.13',
            port: ':9090',
            url: '/booking/erp/docapi',
        },
    },
    authorization: {
        v1: {
            protocol: 'http://',
            rootUrl: '10.230.33.13',
            port: ':9090',
            url: '/booking/authorization/api',
        },
        v2: {
            protocol: 'http://',
            rootUrl: '10.230.33.13',
            port: ':9090',
            url: '/booking/authorization/api',
        },
    },
    access: {
        protocol: 'http://',
        rootUrl: '10.230.33.13',
        port: ':9090',
        url: '/booking/access',
    },
    onesignal: {
        appId: 'd22432e2-f638-4efa-8144-bbd3002ea78a',
        allowLocalhostAsSecureOrigin: true,
        defaultUrl: 'https://localhost:4300',
        defaultTitle: 'asistent | Medifit | DEV',
        safariWebId: 'web.onesignal.auto.3a869b75-1942-4792-8237-9de8041a4c17',
    },
    root: {
        v3: {
            protocol: 'http://',
            rootUrl: '10.230.33.13',
            port: ':5550',
            url: '/booking/asv2',
        },
    },
    aircall: {
        protocol: 'http://',
        rootUrl: '10.230.33.13',
        port: ':9190',
        url: '/booking/eav2/erp/aircallapi',
    },
};
