import { Serializable } from '../deserialize.service';

export class SharedDocument implements Serializable<SharedDocument> {
    documentAdded: Date;
    description: string;
    contentType: string;
    documentId: string;

    deserialize(input: any) {
        this.documentAdded = input.documentAdded;
        this.description = input.description;
        this.contentType = input.contentType;
        this.documentId = input.documentId;
        return this;
    }
}
