import { CommonService } from './../../../../shared/services/common.service';
import { ActiveModulesService } from './../../../../shared/services/active-modules.service';
import { Customer } from './../../../../shared/model/customer.model';

import { TphCallEnded } from './model/tph-call-ended.model';

import { mergeMap, map, tap, catchError } from 'rxjs/operators';
import { TphIncomingCall } from './model/tph-incoming-call.model';
import { NgxPermissionsService } from 'ngx-permissions';

import { State, Store } from '@ngxs/store';
import { TelephonyService } from './telephony.service';

import { Component, OnInit } from '@angular/core';
import AircallPhone from 'aircall-everywhere';

import { of, Observable } from 'rxjs';
import moment from 'moment';

@Component({
    selector: 'telephony-main',
    templateUrl: './telephony.component.html',
    styles: [],
})
export class TelephonyComponent implements OnInit {
    public isThelephonyModule: boolean = false;
    public isWindowShown: boolean = true;
    public isUnregistered: boolean = false;
    public customer: Customer[];
    // public contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public isHDmoduleActive: boolean = false;

    constructor(
        private activeModules: ActiveModulesService,
        private service: TelephonyService,
        private store: Store,
        private commonRest: CommonService, // private navigateHelper: HelperNavigateService, // private reservationRest: ReservationService, // private visitRest: VisitService, // private storage: StorageService, // private helperNavigate: HelperNavigateService,
    ) // private toast: MfToastService,
    // private termsRest: TermsService,
    {}

    ngOnInit() {
        // const user: User = this.store.selectSnapshot(BaseState.loggedUser);
        // this.service.isUserRegistered(this.contractorId, user.email).subscribe((isExistingUser: boolean) => {
        //     this.isUnregistered = !isExistingUser;
        //     if (isExistingUser) {
        //         this.service.initPhone();
        //     }
        // });
        this.service.initPhone();
        this.service.showWindow
            .pipe(
                mergeMap((phoneNumber: string) => {
                    if (!phoneNumber) {
                        return of(undefined);
                    }
                    // const searchNumber: string = phoneNumber.slice(4, phoneNumber.length);
                    return this.searchCustomer(phoneNumber);
                }),
            )
            .subscribe((customer: Customer[]) => {
                this.isWindowShown = true;
                this.customer = customer;
            });

        this.service.hiddeWindow.subscribe((x) => {
            this.isWindowShown = false;
        });

        this.service.onIncomingCall
            .pipe(
                mergeMap((data: TphIncomingCall) => {
                    const searchNumber: string = '0' + data.from.slice(4, data.from.length);
                    return this.searchCustomer(searchNumber);
                }),
            )
            .subscribe((customer: Customer[]) => {
                this.customer = customer;
            });

        // this.service.onCallEnded
        //     .pipe(
        //         mergeMap((data: TphCallEnded) => {
        //             return this.service.saveCall(this.contractorId, data?.callId);
        //         }),
        //     )
        //     .subscribe((x: any) => {});

        // this.isThelephonyModule = this.activeModules.isAM(AppModulesType.TELEPHONY);
        // this.service.initTelephony.subscribe(() => {
        //     debugger;
        //     this.isThelephonyModule = this.activeModules.isAM(AppModulesType.TELEPHONY);
        // });
    }

    private searchCustomer(searchNumber: string): Observable<Customer[]> {
        return of(undefined);
        //TODO
        // this.commonRest
        //     .searchCustomers(searchNumber)
    }
    ngAfterViewInit() {
        // debugger;
        // if (this.isThelephonyModule) {
        // this.service.showWindow.subscribe((x) => {
        //     this.isWindowShown = true;
        // });
        // this.service.hiddeWindow.subscribe((x) => {
        //     this.isWindowShown = false;
        // });
        // this.service.isLoggedIn();
        // const phone: AircallPhone = new AircallPhone({
        //     domToLoadPhone: '#mf-phone',
        //     onLogin: (settings) => {
        //         // ...
        //         // debugger;
        //     },
        //     onLogout: () => {
        //         // ...
        //         // debugger;
        //     },
        // });
        // phone.on('incoming_call', (callInfos) => {
        //     // ...
        //     this.isWindowShown = true;
        //     debugger;
        // });
        // }
    }

    public closeWindow(): void {
        // this.service.hidde();
        // this.customer = undefined;
        this.isWindowShown = false;
    }

    public onRegister(): void {
        this.isUnregistered = false;
        this.service.initPhone();
    }

    public openCustomer(customer: Customer): void {
        // this.navigateHelper.openCustomer(customer.id);
    }
}
