import { Document } from './../../../../shared/model/document.model';
import { ContractorsService } from './../../../../shared/services/contractors.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '../../../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { HttpEventType, HttpResponse } from '../../../../../../node_modules/@angular/common/http';
import * as moment from 'moment';
import { DocumentsService } from '../../../../shared/services/documents.service';

@Component({
    selector: 'app-term-popup',
    templateUrl: './term-popup.component.html',
    styleUrls: ['./term-popup.component.scss']
})
export class TermPopupComponent implements OnInit {
    @Input()
    public termDetails: any;

    public documentsForm: FormGroup;
    public commentForm: FormGroup;
    public percentDone: number = 0;
    public selectedFilesForUpload: FileList;
    public allUploadedFiles: Document[] = [];
    public comment: string = '';

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private contractorRest: ContractorsService,
        private documentRest: DocumentsService
    ) {}

    ngOnInit() {
        // this.createDocumentForm();
        // this.createCommentForm();
    }

    // public createDocumentForm(): void {
    //     // debugger;
    //     this.documentsForm = this.fb.group({
    //         uploadFile: [''],
    //         comment: ['']
    //     });
    //     // debugger;
    // }

    // public createCommentForm(): void {
    //     // debugger;
    //     this.commentForm = this.fb.group({
    //         comment: ['']
    //     });
    //     // debugger;
    // }

    // public onSubmitAddComment(): void {}

    // public onSubmitAddDocument(): void {
    //     this.documentsForm;
    //     debugger;
    //     if (!this.selectedFilesForUpload || this.selectedFilesForUpload.length == 0) {
    //         console.log('Nobena datoteka ni izbrana!');
    //         this.toastr.warning('Nobena datoteka ni izbrana!');

    //         return;
    //     }

    //     const file: File = this.selectedFilesForUpload[0];
    //     if (!this.documentsForm.value.comment) {
    //         this.documentsForm.value.comment = file.name;
    //     }
    //     this.allUploadedFiles.push(
    //         new Document().deserialize({
    //             description: this.documentsForm.value.comment,
    //             documentAdded: moment().toDate(),
    //             id: 2,
    //             contentType: file.type
    //         })
    //     );
    //     this.documentRest.uploadFile(file, this.documentsForm.value.comment, this.termDetails.contractor.id).subscribe(
    //         event => {
    //             console.log(event);
    //             if (event.type == HttpEventType.UploadProgress) {
    //                 this.percentDone = Math.round((100 * event.loaded) / event.total);
    //                 console.log(`File is ${this.percentDone}% loaded.`);
    //             } else if (event instanceof HttpResponse) {
    //                 console.log('File is completely loaded!');
    //             }
    //             if (event.type == HttpEventType.Response) {
    //                 debugger;
    //                 // this.docviewRest
    //                 //     .addFileToCustomer(
    //                 //         this.storage.getSelectedContractor().id,
    //                 //         this.storage.getSelectedCustomerId(),
    //                 //         event.body
    //                 //     )
    //                 //     .subscribe(
    //                 //         () => {
    //                 //             this.toastr.success('Datoteka uspešno naložena!');
    //                 //             this.isVisibleInput = false;
    //                 //             this.eventStream.next({
    //                 //                 action: 'add',
    //                 //                 data: new Document().deserialize({
    //                 //                     description: this.documentsForm.value.comment,
    //                 //                     documentAdded: moment().toDate(),
    //                 //                     id: event.body,
    //                 //                     contentType: file.type
    //                 //                 })
    //                 //             });
    //                 //             this.documentsForm.reset();
    //                 //             this.percentDone = 0;
    //                 //         },
    //                 //         error => {
    //                 //             this.toastr.error('Težava pri nalaganju datoteke!');
    //                 //             console.error(error);
    //                 //             this.percentDone = 0;
    //                 //         }
    //                 //     );
    //             }
    //         },
    //         err => {
    //             this.toastr.error('Težava pri nalaganju datoteke!');
    //             console.error(err);
    //             this.percentDone = 0;
    //         },
    //         () => {
    //             console.log('Upload done');
    //         }
    //     );
    //     // this.uploadFile(this.selectedFilesForUpload);
    // }

    // public selectFile($event): void {
    //     this.selectedFilesForUpload = $event.target.files;
    // }

    public createReservation(): void {
        this.activeModal.dismiss(true);
    }
}
