import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogoutComponent } from './auth/logout/logout.component';
import { CoaLogoutComponent } from './contractor-app/pages/coa-logout/coa-logout.component';
import { PublicComponent } from './public/public.component';
import {DownloadFilesComponent} from "./public/download-files/download-files.component";

const routes: Routes = [
    { path: 'login', loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule) },
    { path: 'logout', component: LogoutComponent },
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    // {
    //     path: '**',
    //     redirectTo: 'index',
    //     pathMatch: 'full'
    // }
    {
        path: 'public',
        component: PublicComponent,
        children: [
            {
                path: 'renew-password',
                loadChildren: './public/renew-pwd/renew-pwd.module#RenewPwdModule',
            },
            {
                path: 'case-details',
                loadChildren: './public/download-files/download-files.module#DownloadFilesModule',
            },
            {
                path: 'download-files',
                loadChildren: './public/download-files/download-files.module#DownloadFilesModule',
            },
            {
                path: 'logout',
                loadChildren: './public/public-logout/public-logout.module#PublicLogoutModule',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
