<div class="text-center" style="color: #fff">
    <h3 i18n="@@9d0114c6-e990-5293-b62c-c18cc54a4890">Vsi prosti termina izbranega izvajalca</h3>
</div>

<div class="d-flex justify-content-center">
    <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                    <h3 class="m-portlet__head-text" i18n="@@2ce25428-d8f9-52ad-8017-15408265cf0d">
                        Tedenski pregled
                    </h3>
                </div>
            </div>
        </div>
        <div class="m-portlet__body">
            <div class="mf-calendar-navigation d-flex justify-content-center pb-4">
                <div class="pr-2">
                    <button
                        [disabled]="disableBackButton"
                        class="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only m-btn--pill"
                        (click)="previousDate(); (false)"
                    >
                        <i class="la la-angle-left"></i>
                    </button>
                </div>
                <div class="pr-2">
                    <button
                        [disabled]="noFutureTimeSlots"
                        class="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only m-btn--pill"
                        (click)="nextDate(); (false)"
                    >
                        <i class="la la-angle-right"></i>
                    </button>
                </div>
                <div>
                    <input
                        type="text"
                        class="form-control m-input"
                        name="daterangeInput"
                        daterangepicker
                        [options]="dateRangePickeroptions"
                        (selected)="changeDate($event)"
                    />
                </div>
            </div>

            <div class="mf-slot-calendar">
                <div class="d-flex justify-content-center mt-3 modal-body" *ngIf="showLoading">
                    <div class="m-blockui">
                        <span i18n="@@history.popup.loader.text">
                            Nalagam termine...
                        </span>
                        <span>
                            <div class="m-loader m-loader--brand"></div>
                        </span>
                    </div>
                </div>
                <div class="d-flex">
                    <ng-container *ngIf="!showLoading">
                        <div
                            class="m-alert m-alert--icon alert alert-danger mt-2"
                            role="alert"
                            *ngIf="noFutureTimeSlots"
                        >
                            <div class="m-alert__icon">
                                <i class="la la-exclamation-circle mr-2"></i>
                            </div>
                            <div class="m-alert__text">
                                <div i18n>V prihodnosti ni razpisanih terminov</div>
                                <button type="button" class="btn btn-light btn-sm m-btn mt-2" (click)="currentDate()">
                                    Pojdi na trenutni teden
                                </button>
                            </div>
                        </div>

                        <div
                            class="m-alert m-alert--icon alert alert-warning mt-2"
                            role="alert"
                            *ngIf="allSlots?.timeSlots.length === 0 && !noFutureTimeSlots"
                        >
                            <div class="m-alert__icon">
                                <i class="la la-warning mr-2"></i>
                            </div>
                            <div class="m-alert__text">
                                <div i18n>V izbranem tednu ni prostih terminov.</div>
                                <button type="button" class="btn btn-light btn-sm m-btn mt-2" (click)="findNextDate()">
                                    Poišči naslednji teden s prostimi termini
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <div class="list-group pr-2" *ngFor="let day of allSlots?.timeSlots">
                        <div class="list-group-item list-group-item-action active">
                            {{ day.date | date: 'dd.MM.yyyy' }}
                            <br />
                            {{ day.date | date: 'EE' }}
                        </div>
                        <div *ngIf="day?.slots.length === 0" class="list-group-item list-group-item-action">
                            Ni prostih terminov
                        </div>
                        <button
                            [disabled]="data?.isReadOnly"
                            class="list-group-item list-group-item-action"
                            *ngFor="let slot of day?.slots"
                            (click)="makePreReservationNew(slot); (false)"
                        >
                            <span
                                *ngIf="slot?.bsids"
                                ngbTooltip="Termin zakupljen s strani zavarovalnice"
                                container="body"
                            >
                                <i class="la la-star text-warning"> </i
                            ></span>
                            {{ slot.start | date: 'HH:mm' }}
                        </button>
                    </div>
                </div>
                <!-- {{allSlots|json}} -->
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="ml-auto btn m-btn--pill btn-danger m-btn m-btn--custom"
        (click)="activeModal.close('Close click')"
        i18n="@@d0d82354-44ed-5657-83a7-7acdff04ba03"
    >
        Prekliči
    </button>
    <button
        type="button"
        class="mr-auto btn m-btn--pill btn-success m-btn m-btn--custom"
        i18n="@@1334b73b-8260-58ba-b5e1-e4fc12747d24"
    >
        Potrdi
    </button>
</div>
<!-- <div class="mf-popup-background"></div> -->
