import { Serializable } from './deserialize.service';

export class Comment implements Serializable<Comment> {
    id: string;
    commentAdded: Date;
    comment: string;
    comentatorName: string;
    origin: string;

    deserialize(input) {
        // debugger;
        this.id = input.id;
        this.commentAdded = input.commentAdded;
        this.comment = input.comment;
        this.comentatorName = input.comentatorName;
        this.origin = input.origin;
        return this;
    }
}

export class CommentCase implements Serializable<CommentCase> {
    id: number;
    commentAdded: Date;
    comment: string;
    comentatorName: string;
    fromAmb: boolean;
    fromApp: any;

    deserialize(input) {
        // debugger;
        this.id = input.id;
        this.commentAdded = input.commentAdded;
        this.comment = input.comment;
        this.comentatorName = input.comentatorName;
        this.fromAmb = input.fromAmb;
        this.fromApp = input.fromApp;
        return this;
    }
}
// public class Comment {
//     public String id;
//     public Date commentAdded;
//     public String comment;
