import { FormGroup, ValidatorFn } from '@angular/forms';

export function AtLeastOneFieldValidator(bsId: number): ValidatorFn {
    return (group: FormGroup): { [key: string]: boolean } | null => {
        // debugger;
        if (bsId == 8 || bsId == 10) {
            return null;
        }
        let isAtLeastOne = false;
        if (group && group.controls) {
            for (const control in group.controls) {
                if (
                    group.controls.hasOwnProperty(control) &&
                    group.controls[control].valid &&
                    group.controls[control].value
                ) {
                    isAtLeastOne = true;
                    break;
                }
            }
        }
        return isAtLeastOne ? null : { required: true };
    };
}

// function ageRangeValidator(min: number, max: number): ValidatorFn {
//     return (control: AbstractControl): { [key: string]: boolean } | null => {
//         if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
//             return { 'ageRange': true };
//         }
//         return null;
//     };
// }

// export function AtLeastOneFieldValidator(group: FormGroup): { [key: string]: any } {
//     // debugger;
//     let isAtLeastOne = false;
//     if (group && group.controls) {
//         for (const control in group.controls) {
//             if (
//                 group.controls.hasOwnProperty(control) &&
//                 group.controls[control].valid &&
//                 group.controls[control].value
//             ) {
//                 isAtLeastOne = true;
//                 break;
//             }
//         }
//     }
//     return isAtLeastOne ? null : { required: true };
// }
