import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../../shared/services/storage.service';
import * as _ from 'lodash';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private storage: StorageService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const c = this.storage.getUserData();
        if (this.storage.getUserData() && this.storage.getUserRoles() && this.storage.getUserRoles().length > 0) {
            return true;
        }
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url, hasQueryParams: !_.isEmpty(route.queryParams) } });
        return false;

        // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // return this._userService.verify().map(
        //     data => {
        //         if (data !== null) {
        //             // logged in so return true
        //             return true;
        //         }
        //         // error when verify so redirect to login page with the return url
        //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //         return false;
        //     },
        //     error => {
        //         // error when verify so redirect to login page with the return url
        //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //         return false;
        //     });
    }
}
