import { ClearState } from './../../theme/pages/_base/base-state/_actions/base.actions';
import { Store } from '@ngxs/store';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { Helpers } from '../../helpers';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {
    constructor(
        private _router: Router,
        private _authService: AuthenticationService,
        private permissionsService: NgxPermissionsService,
        private store: Store,
    ) {}

    ngOnInit(): void {
        Helpers.setLoading(true);
        // reset login status
        this._authService.logout();
        this.permissionsService.flushPermissions();
        this._router.navigate(['/login']);
        this.store.dispatch(new ClearState());
    }
}
