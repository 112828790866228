import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ReplaySubject, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { OneSignalNotification } from '../model/oneSignalNotification.model';

let OneSignal: any;

@Injectable()
export class OneSignalService {
    public isSubscribed: ReplaySubject<boolean> = new ReplaySubject(1);

    public receivedNotifications: Subject<OneSignalNotification> = new Subject();
    public clickedNotifications: Subject<OneSignalNotification> = new Subject();

    public oneSignalAddScript = false;
    public oneSignalInitScript = false;
    public listenerInitialized = false;
    public clickListenerInitialized = false;

    constructor(private storage: StorageService) {
        OneSignal = window['OneSignal'] || [];
    }

    // Call this method to start the onesignal process.
    public init(forceInitUser: boolean = false) {
        if (environment.onesignal.defaultUrl !== window.location.origin) {
            console.log('OneSignal disabled for current environment and origin combination');
            return;
        }

        if (this.storage.getUserData()) {
            if (this.oneSignalInitScript) {
                console.log('OneSignal Already Initialized');
                if (forceInitUser) {
                    const sub$ = this.checkIfSubscribed().subscribe((isSubscribed) => {
                        if (isSubscribed) {
                            this.initUser();
                        }
                        setTimeout(() => {
                            sub$.unsubscribe();
                        }, 500);
                    });
                }
            } else {
                if (!this.oneSignalAddScript) {
                    this.addScript('https://cdn.onesignal.com/sdks/OneSignalSDK.js', (callback) => {
                        this.initOneSignal();
                    });
                }
                //this.initOneSignal();
            }

            let intCounter = 0;
            const int$ = setInterval(() => {
                console.log(typeof window['OneSignal']);
                intCounter++;
                if (typeof window['OneSignal'] === 'function') {
                    OneSignal = window['OneSignal'];
                    clearInterval(int$);
                }
                if (intCounter > 40) {
                    clearInterval(int$);
                }
            }, 500);
        }
    }

    addScript(fileSrc, callback) {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = callback;
        script.src = fileSrc;
        head.appendChild(script);
    }

    initOneSignal() {
        this.oneSignalInitScript = true;
        OneSignal = window['OneSignal'] || [];
        console.log('Init OneSignal');
        console.log(OneSignal);
        OneSignal.push(() => {
            OneSignal.init({
                appId: environment.onesignal.appId,
                autoRegister: false,
                autoResubscribe: true,
                allowLocalhostAsSecureOrigin: environment.onesignal.allowLocalhostAsSecureOrigin,
                safari_web_id: environment.onesignal.safariWebId,
                notifyButton: {
                    enable: false,
                },
                welcomeNotification: {
                    message: 'Uspešno ste se prijavili na prejemanje obvestil.',
                    // "url": "" /* Leave commented for the notification to not open a window on Chrome and Firefox (on Safari, it opens to your webpage) */
                },
                promptOptions: {
                    /* These prompt options values configure both the HTTP prompt and the HTTP popup. */
                    /* actionMessage limited to 90 characters */
                    actionMessage: 'Potrdite prejem obvestil iz aplikacije Asistent na vašem namizju.',
                    /* acceptButtonText limited to 15 characters */
                    acceptButtonText: 'Potrdi',
                    /* cancelButtonText limited to 15 characters */
                    cancelButtonText: 'Prekliči',
                    showCredit: false,
                },
                notificationClickHandlerMatch: 'origin',
                notificationClickHandlerAction: 'focus',
            });
        });
        console.log('OneSignal Initialized');

        OneSignal.push(() => {
            OneSignal.setDefaultNotificationUrl(environment.onesignal.defaultUrl);
            OneSignal.setDefaultTitle(environment.onesignal.defaultTitle);
            OneSignal.on('subscriptionChange', (isSubscribed) => {
                if (isSubscribed) {
                    this.initUser();
                    this.listenForNotification();
                    this.isSubscribed.next(true);
                } else {
                    this.isSubscribed.next(false);
                }
                console.log("The user's subscription state is now:", isSubscribed);
            });
        });
        const ifSubscribed$ = this.checkIfSubscribed().subscribe((isSubscribed) => {
            if (isSubscribed) {
                this.initUser();
                this.listenForNotification();
            }
            ifSubscribed$.unsubscribe();
        });
    }

    toggleSubscription() {
        const response = new Subject();
        OneSignal.push(() => {
            OneSignal.registerForPushNotifications();
        });
        OneSignal.push([
            'getNotificationPermission',
            (permission) => {
                console.log('Site Notification Permission:', permission);
                if (permission === 'denied') {
                    response.error(
                        'Obvestil ni bilo mogoče vključiti, ker ste v vašem brkalniku nastavili, da ne želite prejemati obvestil',
                    );
                    response.complete();
                } else {
                    OneSignal.isPushNotificationsEnabled(
                        (isEnabled) => {
                            if (isEnabled) {
                                console.log('Push notifications are enabled! Switching to disable');
                                this.unsubscribeUser();
                            } else {
                                console.log('Push notifications are not enabled yet. Switching to enable');
                                this.subscribeUser().subscribe(response);
                            }
                        },
                        (error) => {
                            console.log('Push permission not granted. Switching to enable');
                            this.subscribeUser().subscribe(response);
                        },
                    );
                }
            },
        ]);

        return response;
    }

    subscribeUser() {
        const response = new Subject();
        OneSignal.push(() => {
            console.log('Register For Push');
            //OneSignal.showSlidedownPrompt();
            OneSignal.registerForPushNotifications();
            OneSignal.setSubscription(true);

            OneSignal.once('permissionPromptDisplay', () => {
                console.log('The prompt displayed');
                OneSignal.once('notificationPermissionChange', (permission) => {
                    if (permission.to === 'denied') {
                        response.error(
                            'Obvestil ni bilo mogoče vključiti, ker ste v vašem brkalniku zavrnili prejemanje obvestil.',
                        );
                        response.complete();
                    } else {
                        response.complete();
                    }
                });
            });
        });
        return response;
    }

    unsubscribeUser() {
        OneSignal.push(() => {
            OneSignal.setSubscription(false);
        });
    }

    listenForNotification() {
        this.listenForNotificationClicks();
        if (!this.listenerInitialized) {
            console.log('Initalize Listener');
            OneSignal.push(() => {
                OneSignal.on('notificationDisplay', (event) => {
                    console.log('OneSignal notification displayed: ', event);
                    this.receivedNotifications.next(new OneSignalNotification().deserialize(event));
                });
            });
            this.listenerInitialized = true;
        }
    }

    listenForNotificationClicks() {
        if (!this.clickListenerInitialized) {
            console.log('Start click Listener');
            OneSignal.push([
                'addListenerForNotificationOpened',
                (data) => {
                    console.log('Received Notification Opened: ', data);
                    this.clickListenerInitialized = false;
                    this.listenForNotificationClicks();
                    this.clickedNotifications.next(new OneSignalNotification().deserialize(data));
                },
            ]);
            this.clickListenerInitialized = true;
        }
    }

    initUser() {
        const tags = {
            // email: this.storage.getUserData().email,
            userId: this.storage.getUserData().id,
            // userFullName: this.storage.getUserData().name,
            // bsName: this.storage.getBusinessSubject().name,
            bsId: this.storage.getBusinessSubject().id,
        };

        const roles = this.storage.getUserRoles();
        if (roles) {
            roles.forEach((role: string) => {
                tags[role.toLowerCase()] = true;
            });

            const tagsToRemove = [];
            OneSignal.push(() => {
                OneSignal.getTags((existingTags) => {
                    const existingKeys = Object.keys(existingTags);
                    const newKeys = Object.keys(tags);
                    existingKeys.forEach((existingKey) => {
                        if (!newKeys.find((key) => key === existingKey)) {
                            tagsToRemove.push(existingKey);
                        }
                    });
                    if (tagsToRemove.length > 0) {
                        OneSignal.deleteTags(tagsToRemove);
                    }
                });
            });
            OneSignal.push(['sendTags', tags]);
        }

        OneSignal.push(() => {
            OneSignal.setExternalUserId(this.storage.getUserData().id);
        });
    }

    getUserID() {
        OneSignal.getUserId().then((userId) => {
            console.log('User ID is', userId);
        });
    }

    checkIfSubscribed() {
        OneSignal.push(() => {
            OneSignal.isPushNotificationsEnabled(
                (isEnabled) => {
                    if (isEnabled) {
                        console.log('Push notifications are enabled!');
                        this.isSubscribed.next(true);
                    } else {
                        console.log('Push notifications are not enabled yet.');
                        this.isSubscribed.next(false);
                    }
                },
                (error) => {
                    console.log('Push permission not granted');
                    this.isSubscribed.next(false);
                },
            );
        });
        return this.isSubscribed;
    }
}
