import { UrlCase } from './../../../../../shared/model/auth/url-case.model';
import { ConfirmPrereservations } from './../../../../../shared/model/authorityCase/confirmPrereservations.model';
import { UrlData } from './../../../../../shared/model/auth/url-data.model';
import { AuthorizationTriage } from './../../../../../shared/model/authorityCase/authorizationTriage.model';
import { SharedDocument } from './../../../../../shared/model/settings/sharedDocument.model';
import { AuthorityType } from './../../../../../shared/enums/authorityType.enum';
import { Slot } from './../../../../../shared/model/slot.model';
import { PreReservation } from './../../../../../shared/model/preReservation.model';
import { Contractor } from './../../../../../shared/model/contractor.model';
import { Customer } from './../../../../../shared/model/customer.model';
import { AuthorityCaseStatus } from './../../../../../shared/enums/authorityCaseStatus.enum';
import { AuthorityCaseRequest } from './../../../../../shared/model/authorityCase/authorityCaseRequest.model';
import { AuthorizationRequest } from './../../../../../shared/model/authorization/authorizationRequest.model';
import { AuthorityCaseAuthorization } from '../../../../../shared/model/authorityCase/authorityCaseAuthorization.model';

export class ClearState {
    public static readonly type = 'ClearState';
    // constructor(public readonly services: Service[]) {}
}
export class NewAuthorityCase {
    static readonly type = '[AUTHORITY] New Authority Case';

    constructor(public payload: AuthorityCaseRequest) {}
}

export class OpenAuthorityCase {
    static readonly type = '[AUTHORITY] Open Authority Case';

    constructor(public payload: AuthorizationTriage) {}
}

export class SetSecondsLeft {
    static readonly type = '[CASE] Seconds Left';

    constructor(public payload: number) {}
}

export class SetLoading {
    static readonly type = '[AUTHORITY] Set loading';

    constructor(public status: boolean, public message: string = '') {}
}

export class SetUrlData {
    static readonly type = '[URLDATA] SetData';
    constructor(public payload: UrlData) {}
}

export class NewCase {
    static readonly type = '[CASE] New';

    constructor(public payload: AuthorizationRequest) {}
}

export class NewCaseFromExistingAuthority {
    static readonly type = '[CASE] New case from existing authority';
    constructor() {}
}

export class UpdateAuthorization {
    static readonly type = '[Authorization] Update';

    constructor(public authorizationId: string, public payload: AuthorizationRequest) {}
}

export class EditCase {
    static readonly type = '[CASE] Edit';

    constructor(public payload: AuthorityCaseAuthorization) {}
}

export class PrereservationCreated {
    static readonly type = '[CASE] PrereservationCreated';

    constructor(
        public contractor: Contractor,
        public prereservation: PreReservation,
        public slot: Slot,
        public contractorData: any,
    ) {}
}

export class Reschedule {
    static readonly type = '[CASE] Reschedule';

    constructor(public payload: any) {}
}

export class SetNumOfServiceCase {
    static readonly type = '[CASE] Edit number of servies';

    constructor(public payload: number) {}
}

export class ChangeCoverage {
    static readonly type = '[CASE] Change case coverage';

    constructor(public payload: any) {}
}
export class SetSelectedOrgUnit {
    static readonly type = 'Change selected contractor ORG unit';

    constructor(public payload: any) {}
}

export class SetStaus {
    static readonly type = '[CASE] Set Status';

    constructor(public payload: AuthorityCaseStatus) {}
}

export class SetCaseId {
    static readonly type = '[CASE] Set case id';

    constructor(public caseId: string) {}
}

export class SetAuthorityType {
    static readonly type = '[AUTHORITY] Set type';

    constructor(public payload: AuthorityType) {}
}

export class SetCustomer {
    static readonly type = '[CUSTOMER] Set Customer';

    constructor(public payload: Customer) {}
}

// export class ConfirmPrereservation {
//     static readonly type = '[CASE] ConfirmPrereservation';

//     constructor(public contractor: Contractor, public prereservation: PreReservation) {}
// }

export class ConfirmPrereservation {
    static readonly type = '[CASE] ConfirmPrereservation';

    constructor() {}
}
export class ConfirmAllPrereservations {
    static readonly type = '[CASE] ConfirmAllPrereservations';
    constructor(public data: ConfirmPrereservations) {}
}

export class ConfirmWaitingQueue {
    static readonly type = '[CASE] ConfirmWaitingQueue';

    constructor(public contractor: Contractor) {}
}

export class ConfirmSeparatedContractor {
    static readonly type = '[CASE] ConfirmSeparatedContractor';

    constructor(public contractor: Contractor) {}
}

export class CancelAuthorization {
    static readonly type = '[CASE] CancelAuthorization';

    constructor() {}
}

export class CreateQueueBooking {
    static readonly type = '[CASE] CreateQueueBooking';

    constructor() {}
}

export class OpenCase {
    static readonly type = '[CASE] open';

    constructor(public payload: any) {}
}

export class LoadCaseStatuses {
    static readonly type = '[CASE] load statuses';

    constructor() {}
}

export class LoadAuthorizationLog {
    static readonly type = '[CASE] load authorization log';

    constructor() {}
}

//TRIAGE
export class NewTriage {
    static readonly type = '[TRIAGE] New';

    constructor() {}
}

export class TriageSetContractor {
    static readonly type = '[TRIAGE] Set contractor';

    constructor(public payload: Contractor) {}
}

export class OpenTriage {
    static readonly type = '[TRIAGE] open';

    constructor(public payload: any) {}
}

export class AddComment {
    static readonly type = '[Attachment] Add comment';

    constructor(public comment: string) {}
}

export class AddDocument {
    static readonly type = '[Attachment] Add document';

    constructor(public file: File) {}
}

export class AddCustomerDocument {
    static readonly type = '[Attachment] Add customer document';

    constructor(public file: File) {}
}

export class AddCustomerDocumentsSharedAndUploaded {
    static readonly type = '[Attachment] Add customer documents shared and uploaded 2FA';

    constructor(public files: File[], public sharedfiles: SharedDocument[], public isSafe: boolean = true) {}
}

// export class AddUnsafeCustomerDocumentsSharedAndUploaded {
//     static readonly type = '[Attachment] Add unsafe customer documents shared and uploaded';

//     constructor(public files: File[], public sharedfiles: SharedDocument[]) {}
// }

export class AddCustomerSharedDocuments {
    static readonly type = '[Attachment] Add shared customer document';

    constructor(public files: SharedDocument[]) {}
}

export class SetInitComment {
    static readonly type = '[Attachment] Set init comment';

    constructor(public comment: any) {}
}

export class SetInitDocuments {
    static readonly type = '[Attachment] Set init documents';

    constructor(public files: File[]) {}
}

export class GetTriageComments {
    static readonly type = '[Attachment] Get triage comments';

    constructor() {}
}

export class GetTriageDocuments {
    static readonly type = '[Attachment] Get triage documents';

    constructor() {}
}

export class GetTriageContractorComments {
    static readonly type = '[Attachment] Get triage contractor comments';

    constructor() {}
}

export class GetTriageContractorDocuments {
    static readonly type = '[Attachment] Get triage contractor documents';

    constructor() {}
}

export class GetAuthorizationComments {
    static readonly type = '[Attachment] Get authorization comments';

    constructor() {}
}

export class GetAuthorizationDocuments {
    static readonly type = '[Attachment] Get authorization documents';

    constructor() {}
}

export class GetAuthorizationCustomerDocuments {
    static readonly type = '[Attachment] Get authorization customer documents';

    constructor() {}
}

export class GetAuthorizationContractorComments {
    static readonly type = '[Attachment] Get authorization contractor comments';

    constructor() {}
}

export class GetAuthorizationContractorDocuments {
    static readonly type = '[Attachment] Get authorization contractor documents';

    constructor() {}
}

export class MergeAttachments {
    static readonly type = '[Attachment] Merge attachments';

    constructor() {}
}

export class SetAdditionalData {
    static readonly type = '[Case] Set Additional Data';

    constructor(public payload: any) {}
}

export class SetToken {
    static readonly type = '[Common] Set Token';
    constructor(public token: string) {}
}

// export class RemoveTutorial {
//     static readonly type = '[TUTORIAL] Remove'

//     constructor(public payload: string) {}
// }

export class SetExternalUrlCase {
    static readonly type = '[Case] Set external url case';
    constructor(public payload: UrlCase) {}
}
