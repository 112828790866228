<div class="modal-header">
    <h4 class="modal-title" i18n="@@7ea02b78-4da4-5431-a7a6-fe1d90c5d370">
        Dodajanje nove stranke
    </h4>
</div>
<div class="modal-body">
    <!-- (ngSubmit)="onSubmitUpdateCustomerDetails();" -->
    <form [formGroup]="customerDetailsForm" novalidate>
        <div class="m-list-search">
            <div class="m-list-search__results">
                <!-- <span class="m-list-search__result-category m-list-search__result-category--first">
                                    Documents
                                </span> -->
                <!-- <div class="m-list-search__result-item">
                                <span class="m-list-search__result-item-icon">
                                    <i class="flaticon-profile-1 m--font-inverse-light"></i>
                                </span>
                                <span class="m-list-search__result-item-text">
                                    <strong>{{termData?.customer.name}} {{termData?.customer.surname}}</strong>
                                    <br/> {{termData?.customer.address}},
                                    <br /> {{termData?.customer.post}} {{termData?.customer.city}}
                                </span>
                            </div> -->
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i class="la la-user m--font-inverse-light" ngbTooltip="Ime stranke"></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="name"
                            type="text"
                            class="form-control m-input capitalize"
                            placeholder="Ime"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                            i18n-placeholder="@@eeb5bcb9-642d-57c0-8a21-169ffe8bfb4e"
                        />
                    </span>
                </div>
                <!-- {{customerDetailsForm.controls['name'].errors|json}} -->
                <div *ngIf="customerDetailsForm.controls['name'].errors && isSubmited" class="m--font-danger pl-5">
                    <small
                        [hidden]="!customerDetailsForm.controls['name'].errors.required"
                        i18n="@@c79d4d34-9d69-5522-a2f7-db512676d5d4"
                        >Ime je obvezno polje.
                    </small>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <!-- <i class="flaticon-profile-1 m--font-inverse-light"></i> -->
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="surname"
                            type="text"
                            class="form-control m-input capitalize"
                            placeholder="Priimek"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                            i18n-placeholder="@@7e86bc8a-8d10-53f2-9cb9-70688bc70a07"
                        />
                    </span>
                </div>
                <div *ngIf="customerDetailsForm.controls['surname'].errors && isSubmited" class="m--font-danger pl-5">
                    <small
                        [hidden]="!customerDetailsForm.controls['surname'].errors.required"
                        i18n="@@745d3289-f1b9-5c35-9959-e26e8b74e8c4"
                        >Priimek je obvezno polje.
                    </small>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i class="la la-intersex  m--font-inverse-light" ngbTooltip="Spol stranke"></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <div class="m-radio-inline">
                            <label class="m-radio m-radio--state-brand">
                                <input
                                    type="radio"
                                    formControlName="gender"
                                    value="FEMALE"
                                    i18n="@@1e9f0be5-4249-5570-93df-7ee5c153b902"
                                />
                                Ženski
                                <span></span>
                            </label>
                            <label class="m-radio m-radio--state-brand">
                                <input
                                    type="radio"
                                    formControlName="gender"
                                    value="MALE"
                                    i18n="@@b3adc583-b89f-550e-8564-05455793c84f"
                                />
                                Moški
                                <span></span>
                            </label>
                        </div>

                        <!-- <input type="radio" formControlName="sex" value="f"> Ženski
                        <input type="radio" formControlName="sex" value="m"> Moški -->
                        <!-- <input formControlName="sex" type="text" class="form-control m-input" placeholder="Spol" /> -->
                    </span>
                </div>
                <div *ngIf="customerDetailsForm.controls['gender'].errors && isSubmited" class="m--font-danger pl-5">
                    <small
                        [hidden]="!customerDetailsForm.controls['gender'].errors.required"
                        i18n="@@375a321a-7516-52bf-b7e9-ea2d5da1e64f"
                        >Spol je obvezno polje.
                    </small>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i class="la la-birthday-cake m--font-inverse-light" ngbTooltip="Datum rojstva stranke"></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="birthDate"
                            type="text"
                            [textMask]="{ mask: dateOfBirthMask, keepCharPositions: true }"
                            placeholder="dd.mm.llll"
                            class="form-control m-input"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                        />
                    </span>
                </div>
                <div *ngIf="customerDetailsForm.controls['birthDate'].errors && isSubmited" class="m--font-danger pl-5">
                    <small
                        [hidden]="!customerDetailsForm.controls['birthDate'].errors.required"
                        i18n="@@00771f5b-90c9-5106-b1f8-e839787ea500"
                        >Datum rojstva je obvezno polje.
                    </small>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i class="la la-map-marker m--font-inverse-light" ngbTooltip="Naslov stranke"></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="address"
                            type="text"
                            class="form-control m-input"
                            placeholder="Naslov"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                            i18n-placeholder="@@d85b57a0-ce6d-554e-9aba-1b9ef88cd0c9"
                        />
                    </span>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <!-- <i class="flaticon-placeholder m--font-inverse-light"></i> -->
                    </span>
                    <span class="m-list-search__result-item-text">
                        <div class="row">
                            <div class="col-xl-6 col-md-6">
                                <input
                                    formControlName="post"
                                    type="text"
                                    class="form-control m-input"
                                    placeholder="Poštna številka"
                                    [ngClass]="{ 'form-control-sm': isSmallInput }"
                                    i18n-placeholder="@@5e635dfe-c476-5285-a536-252db8f48815"
                                />
                            </div>
                            <div class="col-xl-6 col-md-6">
                                <input
                                    formControlName="city"
                                    type="text"
                                    class="form-control m-input"
                                    placeholder="Pošta"
                                    [ngClass]="{ 'form-control-sm': isSmallInput }"
                                    i18n-placeholder="@@c0ec72ef-c8ea-5c2f-9591-949f99efc1ca"
                                />
                            </div>
                        </div>
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span class="la la-envelope m--font-inverse-light" ngbTooltip="Email stranke"></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="email"
                            type="text"
                            class="form-control m-input"
                            placeholder="Email"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                            i18n-placeholder="@@b31a521a-c8b4-5f5a-a0c2-44678e054557"
                        />
                    </span>
                </div>
                <!-- <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                        </span>
    
                        <label class="m-checkbox m-checkbox--state-brand">
                            <input type="checkbox" formControlName="sendMail"> Želi obveščanje
                            <span></span>
                        </label>
    
                    </div> -->
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span class="la la-phone m--font-inverse-light" ngbTooltip="Telefon stranke"></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="phone"
                            type="text"
                            placeholder="Telefon"
                            class="form-control m-input"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                            i18n-placeholder="@@191fa2b2-5ad7-5962-bce5-fea2d0870f54"
                        />
                    </span>
                </div>
                <div *ngIf="customerDetailsForm.controls['phone'].errors && isSubmited" class="m--font-danger pl-5">
                    <small
                        [hidden]="!customerDetailsForm.controls['phone'].errors.pattern"
                        i18n="@@df7c11e4-ba4b-5a93-b572-2e79212df86c"
                    >
                        Vsi znaki niso številke!
                    </small>
                    <small
                        [hidden]="!customerDetailsForm.controls['phone'].errors.required"
                        i18n="@@ba797024-dfc4-5496-90d8-deb098fe2eb4"
                        >Telefon je obvezno polje.
                    </small>
                    <small [hidden]="!customerDetailsForm.controls['phone'].errors.minlength"
                        >Dolžina telefonske številke je prekratka! Vnesite vsaj 9 števil.
                    </small>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span
                            i18n-ngbTooltip=""
                            ngbTooltip="Državljanstvo stranke"
                            class="la la-flag-o m--font-inverse-light"
                        ></span>
                    </span>

                    <span class="m-list-search__result-item-text">
                        <div class="row">
                            <div class="col-12">
                                <ng-select
                                    [items]="countryList"
                                    bindLabel="country"
                                    bindValue="country"
                                    [addTag]="false"
                                    [clearable]="false"
                                    i18n-placeholder="@@drzavljanstvo"
                                    placeholder="Državljanstvo"
                                    (change)="fillcountry($event)"
                                    formControlName="country"
                                >
                                    <ng-template ng-label-tmp let-item="item">
                                        <flag-icon country="{{ item.code | lowercase }}"></flag-icon>
                                        {{ item.country | titlecase }}
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        <flag-icon country="{{ item.code | lowercase }}"></flag-icon>
                                        {{ item.country | titlecase }}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </span>
                </div>

                <div formGroupName="customerId">
                    <!-- EMSO -->
                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                            <span
                                i18n-ngbTooltip
                                ngbTooltip="EMŠO stranke"
                                class="mf-text-icon"
                                i18n="@@6cdd4f3c-d151-5ed8-bdc5-e898628a1da4"
                                >EMŠO</span
                            >
                            <!-- <span class="la	la-asterisk m--font-inverse-light" i18n-ngbTooltip ngbTooltip="EMŠO"></span> -->
                        </span>
                        <span class="m-list-search__result-item-text">
                            <input
                                formControlName="emso"
                                type="text"
                                i18n-placeholder="@@0512b15e-a0b4-567f-831f-b234139fa2b2"
                                placeholder="EMŠO"
                                class="form-control m-input"
                            />
                        </span>
                    </div>

                    <div
                        *ngIf="customerDetailsForm.controls['customerId']['controls']['emso']?.errors && isSubmited"
                        class="m--font-danger pl-5"
                    >
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['emso'].errors.minlength"
                            i18n="@@866614bd-b6e3-5b88-a53a-eecbbcedef27"
                        >
                            Dolžina EMŠO je prekratek! Vnesite 13 števil.
                        </small>
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['emso'].errors.maxlength"
                            i18n="@@bb46dd9d-2c26-5d7c-8d02-7a8097cc9780"
                        >
                            Dolžina EMŠO številke je predolga! Vnesite 13 števil.
                        </small>
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['emso'].errors.pattern"
                            i18n="@@b85a4ad5-1f02-581e-9395-72cc313c648a"
                        >
                            Vsi znaki niso številke!
                        </small>
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['emso'].errors.required"
                            i18n="@@775f071a-f5d0-5ff6-b958-fc591603d616"
                        >
                            EMŠO je obvezno polje.
                        </small>
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['emso'].errors.incorrect"
                        >
                            {{ customerDetailsForm.controls['customerId']['controls']['emso'].errors.message }}
                        </small>
                    </div>
                    <!-- DAVCNA -->
                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                            <span i18n-ngbTooltip ngbTooltip="Davčna št. stranke" class="mf-text-icon">DŠ</span>
                            <!-- <span class="la	la-asterisk m--font-inverse-light" i18n-ngbTooltip ngbTooltip="EMŠO"></span> -->
                        </span>
                        <span class="m-list-search__result-item-text">
                            <input
                                formControlName="taxNo"
                                type="text"
                                placeholder="Davčna"
                                class="form-control m-input"
                            />
                        </span>
                    </div>

                    <div
                        *ngIf="customerDetailsForm.controls['customerId']['controls']['taxNo']?.errors && isSubmited"
                        class="m--font-danger pl-5"
                    >
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['taxNo'].errors.minlength"
                        >
                            Dolžina davčna št. je prekratka! Vnesite 8 števil.
                        </small>
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['taxNo'].errors.maxlength"
                        >
                            Dolžina davčne št. je predolga! Vnesite 8 števil.
                        </small>
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['taxNo'].errors.pattern"
                        >
                            Vsi znaki niso številke!
                        </small>
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['taxNo'].errors.required"
                        >
                            Davčna št. je obvezno polje.
                        </small>
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['taxNo'].errors.incorrect"
                        >
                            {{ customerDetailsForm.controls['customerId']['controls']['taxNo'].errors.message }}
                        </small>
                    </div>
                    <!-- KZZ -->
                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                            <span ngbTooltip="KZZ" class="mf-text-icon">KZZ</span>
                            <!-- <span class="la	la-asterisk m--font-inverse-light" i18n-ngbTooltip ngbTooltip="EMŠO"></span> -->
                        </span>
                        <span class="m-list-search__result-item-text">
                            <input formControlName="kzz" type="text" placeholder="KZZ" class="form-control m-input" />
                        </span>
                    </div>

                    <div
                        *ngIf="customerDetailsForm.controls['customerId']['controls']['kzz']?.errors && isSubmited"
                        class="m--font-danger pl-5"
                    >
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['kzz'].errors.minlength"
                        >
                            Dolžina KZZ je prekratek! Vnesite 9 števil.
                        </small>
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['kzz'].errors.maxlength"
                        >
                            Dolžina KZZ številke je predolga! Vnesite 9 števil.
                        </small>
                        <small [hidden]="!customerDetailsForm.controls['customerId']['controls']['kzz'].errors.pattern">
                            Vsi znaki niso številke!
                        </small>
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['kzz'].errors.required"
                        >
                            KZZ je obvezno polje.
                        </small>
                        <small
                            [hidden]="!customerDetailsForm.controls['customerId']['controls']['kzz'].errors.incorrect"
                        >
                            {{ customerDetailsForm.controls['customerId']['controls']['kzz'].errors.message }}
                        </small>
                    </div>

                    <div
                        *ngIf="customerDetailsForm.controls['customerId'].errors && isSubmited"
                        class="m--font-danger pl-5"
                    >
                        <small>Obvezen je vnos vsaj enega polja (KZZ, EMŠO ali davčna številka)!</small>
                    </div>
                </div>
            </div>
        </div>
        <!-- {{termData|json}} -->
    </form>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="ml-auto mr-auto btn m-btn--pill btn-danger m-btn m-btn--custom"
        (click)="dismiss('Close click')"
        tabindex="8"
        i18n="@@d2552f4e-a8b7-5daf-9c89-f1f0ac30a2df"
    >
        Prekliči
    </button>
    <button
        (click)="createCustomer()"
        type="buttons"
        class="mr-auto btn m-btn--pill btn-accent m-btn m-btn--custom"
        tabindex="6"
        i18n="@@615c16be-d366-5ec4-a79a-7db71946bb97"
    >
        Potrdi
    </button>
</div>
