import { CustomerCommentEdit } from './../model/customer/customer-comment-edit.model';
import { CustomerComment } from './../model/customer/customer-comment.model';
import { Comment } from './../model/comment.model';
import { AuthorizationOrder } from './../model/authorization/authorizationOrder.model';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';
import { Observable } from 'rxjs';
import { Customer } from './../model/customer.model';
import { Injectable } from '@angular/core';
import { Country } from '../model/country.model';
import {Consent} from "../model/consent.model";
import {DEBUG} from "@angular/compiler-cli/ngcc/src/logging/console_logger";

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    public getBsCustomer(bsid: number, customerId: any): Observable<Customer | any>{
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/bs/${bsid}/customers/${customerId}`);
    }
    //createBusinessSubjectCustomer()
    public createBusinessSubjectCustomer(bsid: number, data: Customer): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/customers`, data);
    }

    public getCustomerConsent(bsId: number, customerId: number): Observable<Consent> {
        return this.httpClient.get(
            this.helper.getAssistanceUrl(3) + '/cust/bs/' + bsId + '/customers/' + customerId + '/consent',
        );
    }

    public updateCustomerConsent(bsId: number, customerId: number, consent: Consent): Observable<Consent> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl(3) + '/cust/bs/' + bsId + '/customers/' + customerId + '/consent',
            consent,
        );
    }

    public sendConsentMail(bsId: number, customerId: number): Observable<Consent> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl(3) + '/cust/bs/' + bsId + '/customers/' + customerId + '/consent/mail',
        );
    }

    //getBusinessSubjectCustomer()
    public getBusinessSubjectCustomer(bsid: number): Observable<Customer[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/customers`);
    }

    public getCustomerInfo(bsid: number, customerId: number): Observable<Customer> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/customers/${customerId}`);
    }

    public updateCustomer(bsid: number, customerId: any, customer: Customer): Observable<Customer> {
        return this.httpClient.put(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/customers/${customerId}`, customer);
    }

    public deleteCustomer(bsid: number, customerId: any, customer: Customer): Observable<Customer> {
        return this.httpClient.delete(
            this.helper.getAssistanceUrl(2) + `/bs/${bsid}/customers/${customerId}`,
            customer,
        );
    }

    public getBusinessSubjectCasesForCustomer(bsid: number, customerId: number): Observable<AuthorizationOrder[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/cases/customers/${customerId}`);
    }
    //getCustomerBSID()
    public getCustomerBSID(bsid: number, customerId: number): Observable<string> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/customers/${customerId}/external`);
    }

    public getCustomerComments(bsid: number, customerId: number): Observable<CustomerComment[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/customers/${customerId}/comments`);
    }

    public getCountries(): Observable<Country[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/countries`);
    }

    //editCustomerComment()
    public editCustomerComment(customerId: any, commentId: string, ccomment: CustomerCommentEdit): Observable<any> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl(2) + `/customers/${customerId}/comments/${commentId}`,
            ccomment,
            {
                responseType: 'text',
            },
        );
    }
}
