import { AppModulesType } from './../enums/appModulesType.enum';
import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
@Injectable({
    providedIn: 'root',
})
export class ActiveModulesService {
    constructor(private storage: StorageService) {}

    public getAM(): any {
        return this.storage.getBsGuiSettings();
    }

    public isAM(module: AppModulesType): boolean {
        return _.get(this.storage.getBsGuiSettings(), 'settings.active', []).includes(module);
    }
}
