import { BsGuiAnalytics } from './bsGuiAnalytics.model';
import { Serializable } from '../deserialize.service';

export class BsGuiSettings implements Serializable<BsGuiSettings> {
    analytics: BsGuiAnalytics;
    settings: {
        active: string[];
    };

    constructor() {
        this.analytics = new BsGuiAnalytics();
    }

    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }

        if (input.analytics) {
            this.analytics = new BsGuiAnalytics().deserialize(input.analytics);
        }

        if (input.settings) {
            this.settings = {
                active: input.settings.active,
            };
        }

        return this;
    }
}
