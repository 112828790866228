import { Contractor } from './../contractor.model';
import { AuthorityCase } from './../authorityCase/authorityCase.model';
import { Serializable } from '../deserialize.service';
import { Customer } from '../customer.model';

export class Triage implements Serializable<Triage> {
    public id: number;
    public created: Date;
    public caseAuthority: AuthorityCase;
    public contractor: Contractor;
    public customer: Customer;
    public canceledTime: string;
    public concludedTime: string;
    public appUserName: string;
    public status: string;
    public serviceId: number;
    public serviceName: string;
    public price: number;

    deserialize(input: any) {
        this.created = input.created;
        this.id = input.id;
        this.caseAuthority = input.caseAuthority;
        this.contractor = input.contractor;
        this.customer = input.customer;
        this.canceledTime = input.canceledTime;
        this.concludedTime = input.concludedTime;
        this.appUserName = input.appUserName;
        this.status = input.status;
        this.serviceId = input.serviceId;
        this.serviceName = input.serviceName;
        this.price = input.price;
        return this;
    }
}
