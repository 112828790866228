import {StorageService} from './shared/services/storage.service';
import {ToastrService} from 'ngx-toastr';
import {Component, OnInit, ViewEncapsulation, ViewContainerRef, Renderer2, Inject} from '@angular/core';
import {Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Helpers} from './helpers';
import {RulesService} from './shared/services/rules.service';
import {OneSignalService} from './shared/services/onesignal.service';
import {HttpClientService} from './shared/services/http-client.service';
import {HelperService} from './shared/services';
import {DOCUMENT} from '@angular/common';
import {environment} from '../environments/environment';
import * as _ from 'lodash';

@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    title = 'app';
    globalBodyClass =
        'm-page--loading-non-block m-page--wide m-header--fixed m-header--fixed-mobile m-footer--push m-aside--offcanvas-default';

    public supportedBrowser: boolean = true;
    public currentUrl: string = null;

    public version: string = environment.VERSION;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        public toastr: ToastrService,
        public vcr: ViewContainerRef,
        private rules: RulesService,
        private oneSignal: OneSignalService,
        private httpService: HttpClientService,
        private helper: HelperService,
        private _renderer: Renderer2,
        @Inject(DOCUMENT) private _document,
        private storage: StorageService,
    ) {
        // this.toastr.setRootViewContainerRef(vcr);
        const majorVersion = parseInt(this.version.slice(0, this.version.indexOf('.')));
        //const preventRedirect = this.storage.isPreventRedirect();
        if (majorVersion === 2) {
            this.httpService.get('/assets/v3enabled.json').subscribe((resp) => {
                if (!this.storage.getBusinessSubject()) {
                    return;
                }

                if (resp && resp.forceV3) {
                    const forceV3Enabled = resp.forceV3.find((itm) => {
                        return itm.bsId === this.storage.getBusinessSubject().id;
                    });
                    if (forceV3Enabled && forceV3Enabled.enabled) {

                        let redirectUrl = '/v3/';

                        const queryParams: any = {};
                        location.search.substr(1).split("&").forEach(function (item) {
                            queryParams[item.split("=")[0]] = item.split("=")[1];
                        });


                        if (queryParams.token) {
                            redirectUrl += `login?token=${queryParams.token}`;
                        }

                        if (queryParams.data) {
                            redirectUrl += `&data=${queryParams.data}`;
                        }

                        window.location.href = redirectUrl;
                    }
                }
            }, () => {
            });
        }
    }

    ngOnInit() {
        // this.initTawk();
        const ieVersion = this.helper.isIE();
        if (ieVersion && ieVersion <= 11) {
            this.supportedBrowser = false;
            this.currentUrl = window.location.href;
        }

        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                Helpers.setLoading(true);
                Helpers.bodyClass(this.globalBodyClass);
            }
            if (route instanceof NavigationEnd) {
                Helpers.setLoading(false);
            }
        });

        this.rules.setRules();
        this.oneSignal.init();
    }

    public copyLink(link: string) {
        let nav: any;
        nav = window.navigator;
        const text = link.trim();
        if (!text) {
            //klikneš na ikono, namesto besede
            return;
        }
        nav.clipboard.writeText(text).then(function () {
        });
        this.toastr.info('Povezava dodana v odložišče.');
    }

    // private initTawk(): void {
    //     const s = this._renderer.createElement('script');
    //     const user: User = this.storge.getUserData();
    //     const bs: BusinessSubjects = this.storge.getBusinessSubject();
    //     s.text = `var Tawk_API = Tawk_API || {};
    //     Tawk_API.visitor = {
    //         name: '${_.get(user, 'name', 'unknown user')}',
    //         email: '${_.get(user, 'email', 'unknown email')}',
    //         businessSubject: '${_.get(bs, 'name', 'unknown bs')}',
    //     };
    //     var Tawk_LoadStart = new Date();

    //     (function () {
    //       var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    //       s1.async = true;
    //       s1.src = 'https://embed.tawk.to/${environment.TAWK_KEY}/default';
    //       s1.charset = 'UTF-8';
    //       s1.setAttribute('crossorigin', '*');
    //       s0.parentNode.insertBefore(s1, s0);

    //     })();`;

    //     this._renderer.appendChild(this._document.body, s);
    // }
}
