import { Observable, of, throwError } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { HelperService } from './helper.service';
import { Injectable } from '@angular/core';
import { TriageResponse, Comment, Document, HistoryLog } from '../model';
import { Triage } from '../model/triage/triage.model';
import { concatMap, delay, map, retryWhen, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpEvent, HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class TriageService {
    constructor(private helper: HelperService, private httpClient: HttpClientService) {}
    //createTriage()

    public getTriage(bsid: number, triageId: string | number): Observable<any> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/triage/${triageId}`);
    }

    public createTriage(bsid: number, authorityid: number, triageRequest: Triage): Observable<any> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl() + `/bs/${bsid}/authority/${authorityid}/triage`,
            triageRequest,
        );
    }

    //getBusinessSubjectTriage()
    public getBusinessSubjectTriage(bsid: string | number): Observable<Triage[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/triage`);
    }

    public addTriageComments(buid: number, triageId: number, data: Comment): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + `/bs/${buid}/triage/${triageId}/comments`, data, {
            responseType: 'text',
        });
    }

    public addTriageDocument(bsid: number, triageId: number, documentId: string): Observable<any> {
        return this.httpClient
            .post(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/triage/${triageId}/documents/${documentId}`)
            .pipe(
                tap((ev: HttpEvent<any>) => {
                    if (ev instanceof HttpResponse) {
                        console.log('###processing response', ev);
                    }
                }),
                retryWhen((errors) =>
                    errors.pipe(
                        concatMap((error, count) => {
                            if (count < 5 && error.status === 400) {
                                return of(error.status);
                            }

                            return throwError(error);
                        }),
                        delay(100),
                    ),
                ),
            );
    }

    public getTriageComments(buid: string | number, triageId: number): Observable<Comment[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${buid}/triage/${triageId}/comments`).pipe(
            map((el) => {
                if (el.comments === null) {
                    return undefined;
                }
                return _.get(el, 'comments', []);
            }),
        );
    }

    public getTriageContractorComments(bsid: number, triageId: number): Observable<Comment[]> {
        return this.httpClient
            .get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/triage/${triageId}/contractor/comments`)
            .pipe(
                map((el) => {
                    if (_.isNil(el.comments)) {
                        return undefined;
                    }
                    return _.get(el, 'comments', []);
                }),
            );
    }

    public getTriageDocuments(bsid: number, triageId: number): Observable<Document[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/triage/${triageId}/documents`).pipe(
            map((el) => {
                if (_.isNil(el.documents)) {
                    return [];
                }
                return el.documents.map((doc) => new Document().deserialize(doc));
            }),
        );
    }

    public getTriageContractorDocuments(bsid: number, triageId: number): Observable<Document[]> {
        return this.httpClient
            .get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/triage/${triageId}/contractor/documents`)
            .pipe(
                map((el) => {
                    if (_.isNil(el.documents)) {
                        return [];
                    }
                    return el.documents.map((doc) => new Document().deserialize(doc));
                }),
            );
    }

    public concludeTriage(bsid: number, triageId: number): Observable<Triage> {
        return this.httpClient.put(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/triage/${triageId}/conclude`);
    }

    public deleteTriage(bsid: number, triageId: number): Observable<Triage> {
        return this.httpClient.delete(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/triage/${triageId}`);
    }

    public rejectTriage(bsid: number, triageId: number): Observable<Triage> {
        return this.httpClient.put(this.helper.getAssistanceUrl() + `/bs/${bsid}/triage/${triageId}/reject`);
    }

    public getTriageHistoryLog(bsid: string | number, triageId: number): Observable<HistoryLog[]> {
        return this.httpClient
            .get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/triage/${triageId}/log`)
            .pipe(map((el) => el.map((el2) => new HistoryLog().deserialize(el2))));
    }
}
