<!-- begin::Page loader -->
<div class="m-page-loader m-page-loader--base m-page-loader--non-block" style="margin-left: -80px; margin-top: -20px;">
    <div class="m-blockui">
        <span i18n="@@503fe9c0-9142-5098-849f-7684711cb867">
            Nalagam...
        </span>
        <span>
            <div class="m-loader m-loader--brand"></div>
        </span>
    </div>
</div>

<ng-container *ngIf="!supportedBrowser">
    <div
         class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2">
        <div class="m-grid__item m-grid__item--fluid m-login__wrapper">
            <div class="m-login__container" style="width: 470px">
                <div class="m-login__logo">
                    <a href="#">
                        <img src="./assets/app/media/img/logos/logo-white.svg" width="300" />
                        <!-- christmas -->
                        <!-- <img src="./assets/app/media/img/logos/logo-white-c.svg" width="300" /> -->
                    </a>
                </div>
                <div class="m-alert m-alert--icon m-alert--outline alert alert-danger" role="alert">
                    <div class="m-alert__icon">
                        <i class="la la-warning"></i>
                    </div>
                    <div class="m-alert__text">
                        <h5>
                            Uporabljate nepodprto različico brskalnika.
                        </h5>
                        Prosimo vas, da to povezavo prekopirate in jo odprete v enem izmed podprtih brskalnikov:
                        <a (click)="copyLink(currentUrl)">{{ currentUrl }}</a>

                        <br /><br />
                        <small>Predlagamo vam uporabo najnovejše različice brskalnika Chrome, Firefox, Safari ali
                            Edge</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- end::Page loader -->
<!-- begin:: Page -->
<router-outlet *ngIf="supportedBrowser"></router-outlet>
<!-- end:: Page -->
<!-- <app-scroll-top></app-scroll-top> -->
<!-- <app-tooltips></app-tooltips> -->
<!--begin::Base Scripts -->
<!--end::Base Scripts -->
<!--begin::Page Vendors -->
<!--end::Page Vendors -->
<!--begin::Page Snippets -->
<!--end::Page Snippets -->