import { CoaLogoutComponent } from './pages/coa-logout/coa-logout.component';
import { AuthenticationService } from './../auth/_services/authentication.service';
import { AuthService } from './../shared/services/auth.service';
import { AuthGuard } from './utils/auth.guard';
import { LayoutModule } from './../theme/layouts/layout.module';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractorAppComponent } from './contractor-app.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AuthRoutingModule } from './auth-routing.routing';
import { AuthModule } from '../auth/auth.module';
import { AlertService } from '../auth/_services';
//import { BaseRequestOptions } from '@angular/http';
import { HelperService, StorageService } from '../shared/services';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from '../token.interceptor.service';
import { TokenInterceptorCoaService } from './token.interceptor-coa.service';

const routes: Routes = [
    {
        path: 'contractor-app',
        component: ContractorAppComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'case-list',
                loadChildren: () =>
                    import('./pages/coa-case-list/coa-case-list.module').then((m) => m.CoaCaseListModule),
            },
            {
                path: 'case-list/:caseid',
                loadChildren: () => import('./pages/coa-customer/coa-customer.module').then((m) => m.CoaCustomerModule),
            },
        ],
    },
    {
        path: 'contractor-app/login',
        loadChildren: () => import('./pages/coa-login/coa-login.module').then((m) => m.CoaLoginModule),
    },
    { path: 'contractor-app/logout', component: CoaLogoutComponent },

    // {
    //     path: 'contractor-app/logout',
    //     loadChildren: './pages/coa-login/coa-login.module#CoaLoginModule'
    // },
    {
        path: '**',
        redirectTo: 'index',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        LayoutModule,
        CommonModule,
        RouterModule,
        AuthRoutingModule,
        NgxDatatableModule,
        RouterModule.forChild(routes),
    ],
    declarations: [ContractorAppComponent, CoaLogoutComponent],
    exports: [RouterModule],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        //apibackendsimulation\r\nBaseRequestOptions,
        HelperService,
        StorageService,
        AuthService,
        //\r\n//provide
    ],
})
export class ContractorAppModule {}
