import { Serializable } from '../deserialize.service';

export class DocumentSign implements Serializable<DocumentSign> {
    public signer: string;
    public date: string;
    public contractor: string;

    deserialize(input: any) {
        this.signer = input.signer;
        this.date = input.date;
        this.contractor = input.contractor;
        return this;
    }
}
