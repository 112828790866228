export enum AuthorityCaseStatus {
    AUTHORITY_CREATED = 'AUTHORITY_CRATED_1',
    TRIAGE_UNANSWERED = 'TRIAGE_UNANSWERED',
    TRIAGE_SELECTED = 'TRIAGE_SELECTED',
    TRIAGE_CONTRACTOR_SELECTED = 'TRIAGE_CONTRACTOR_SELECTED',
    TRIAGE_CREATED = 'TRIAGE_CREATED',
    TRIAGE_CANCELED = 'TRIAGE_CANCELED',
    TRIAGE_CONCLUDED = 'TRIAGE_CONCLUDED',
    TRIAGE_ANSWERED = 'TRIAGE_ANSWERED',
    TRIAGE_REJECTED = 'TRIAGE_REJECTED',
    TRIAGE_REQUEST_SENT = 'TRIAGE_REQUEST_SENT',
    SELECTED = 'SELECTED_2',
    CREATED = 'CREATED_4',
    CONTRACTOR_SELECTED = 'CONTRACTOR_SELECTED_3',
    PRERESERVATION = 'PRERESERVATION_5',
    CONFIRMED = 'CONFIRMED_6',
    CANCELED = 'CANCELED_7',
}
