import { ActiveModulesService } from './../shared/services/active-modules.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Helpers } from '../helpers';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { StorageService } from '../shared/services';
import { debug } from 'util';
import { Intercom } from 'ng-intercom';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import * as _ from 'lodash';
import { AppModulesType } from '../shared/enums/appModulesType.enum';

declare let mApp: any;
declare let mUtil: any;
declare let mLayout: any;

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './theme.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnInit {
    constructor(
        private _script: ScriptLoaderService,
        private _router: Router,
        private storage: StorageService,
        private intercom: Intercom,
        private am: ActiveModulesService
    ) {}
    public isTelephonyActivated: boolean = false;

    ngOnInit() {
        const userdata = this.storage.getUserData();
        this.isTelephonyActivated = this.am.isAM(AppModulesType.TELEPHONY)
        const companies = _.get(userdata, 'businessSubjects', []).map((comp) => {
            comp.id = 'business-subject-' + comp.id;
            return comp;
        });

        this.intercom.boot({
            app_id: environment.INTERCOM_KEY,
            name: _.get(userdata, 'name', ''), // Full name
            email: _.get(userdata, 'email', ''), // Email address
            created_at: moment().unix(), // Signup date as a Unix timestamp,
            companies: companies,
            Products: 'Asistent',
            APP_version: environment.VERSION,
            phone: _.get(userdata, 'phone', ''),
            widget: {
                activator: '#intercom',
            },
        });

        this._script
            .loadScripts(
                'body',
                ['assets/vendors/base/vendors.bundle.js', 'assets/demo/demo5/base/scripts.bundle.js'],
                true,
            )
            .then((result) => {
                Helpers.setLoading(false);
                // optional js to be loaded once
                // this._script.loadScripts('head', ['assets/vendors/custom/fullcalendar/fullcalendar.bundle.js'], true);
            });
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                // (<any>mLayout).closeMobileAsideMenuOffcanvas();
                // (<any>mLayout).closeMobileHorMenuOffcanvas();
                // (<any>mApp).scrollTop();
                Helpers.setLoading(true);
                // hide visible popover
                (<any>$('[data-toggle="m-popover"]')).popover('hide');
            }
            if (route instanceof NavigationEnd) {
                // init required js
                (<any>mApp).init();
                (<any>mUtil).init();
                Helpers.setLoading(false);
                // content m-wrapper animation
                const animation = 'm-animate-fade-in-up';
                $('.m-wrapper')
                    .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(e) {
                        $('.m-wrapper').removeClass(animation);
                    })
                    .removeClass(animation)
                    .addClass(animation);
            }
        });
    }
}
