<div class="row modal-header">
    <div class="col-8 ml-auto mr-auto justify-content-center">
        <div style="text-align: center;" class="m-2" i18n="@@history.popup.title.text">
            <h4>
                Zgodovina <span *ngIf="type === 'CASE' || type === 'CASE_FROM_TRIAGE'"> avtorizacije:</span>
                <span *ngIf="type === 'TRIAGE'"> eTriaže:</span>
            </h4>
            <h3>
                <span *ngIf="type === 'CASE' || type === 'CASE_FROM_TRIAGE'">
                    <b> {{ caseId }} </b>
                </span>
                <span *ngIf="type === 'TRIAGE'">
                    <b> {{ triageId }} </b>
                </span>
            </h3>
        </div>
    </div>
</div>
<perfect-scrollbar style="max-height: 800px" *ngIf="!loading" class="modal-bodyy">
    <div class="row">
        <div class="m-timeline-1 m-timeline-1--fixed">
            <div class="m-timeline-1__items">
                <div class="m-timeline-1__marker"></div>

                <div
                    class="m-timeline-1__item"
                    *ngFor="let item of timeline; first as isFirst; odd as isOdd; even as isEven"
                    [ngClass]="{
                        'm-timeline-1__item--first': isFirst,
                        'm-timeline-1__item--right ': isOdd,
                        'm-timeline-1__item--left align-right': isEven
                    }"
                >
                    <app-timeline-element [item]="item"></app-timeline-element>
                </div>
            </div>
        </div>
    </div>
</perfect-scrollbar>
<div class="d-flex justify-content-center mt-3 modal-body" *ngIf="loading">
    <div class="m-blockui">
        <span i18n="@@history.popup.loader.text">
            Nalagam...
        </span>
        <span>
            <div class="m-loader m-loader--brand"></div>
        </span>
    </div>
</div>
<div class="d-flex modal-footer">
    <div class="ml-auto mr-auto ">
        <button
            type="button"
            class="btn m-btn--pill btn-danger m-btn m-btn--custom"
            (click)="activeModal.close()"
            i18n="@@history.popup.close.button"
        >
            Zapri
        </button>
    </div>
</div>
