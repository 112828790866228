<div class="m-portlet mf-portlet-gray__gray-background">
    <div class="m-portlet__head mt-5">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text" i18n="@@3806622411390976">Izbira terminov</h3>
            </div>
        </div>
    </div>
    <div class="m-portlet__body">
        <div class="d-flex justify-content-center">
            <div class="">
                <div class="m-2">Izvajalec: {{ termData.contractor.name || '/' }}</div>
                <div class="m-2">Podizvajalec: {{ termData.subcontractor.name || '/' }}</div>
                <div class="m-2">Termin: {{ termData.slot.start | date: 'dd.MM.yyyy H:mm' }}</div>
            </div>
        </div>

        <hr />
        <div class="mf-calendar-navigation d-flex justify-content-center align-items-center">
            <div class="pr-2" i18n="@@6817922093678592">
                Število terminov:
            </div>
            <div class="p-2">
                <select class="form-control m-input" [(ngModel)]="numberOfTerms">
                    <option [ngValue]="1"> 1</option>
                    <option [ngValue]="2"> 2</option>
                    <option [ngValue]="3"> 3</option>
                    <option [ngValue]="4"> 4</option>
                    <option [ngValue]="5"> 5</option>
                    <option [ngValue]="6"> 6</option>
                    <option [ngValue]="7"> 7</option>
                    <option [ngValue]="8"> 8</option>
                    <option [ngValue]="9"> 9</option>
                    <option [ngValue]="10"> 10</option>
                    <option [ngValue]="11"> 11</option>
                    <option [ngValue]="12"> 12</option>
                    <option [ngValue]="13"> 13</option>
                    <option [ngValue]="14"> 14</option>
                    <option [ngValue]="15"> 15</option>
                </select>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer d-flex justify-content-center pt-2">
    <button type="button" class="btn m-btn--pill btn-danger m-btn m-btn--custom" (click)="activeModal.close()">
        Prekliči
    </button>
    <button
        type="button"
        class="btn m-btn--pill btn-success m-btn m-btn--custom"
        (click)="activeModal.dismiss(numberOfTerms)"
    >
        Potrdi
    </button>
</div>
