<div
    class="m-quick-sidebar m-quick-sidebar--skin-light p-0 pb-2"
    appunwraptag=""
    [ngClass]="{ 'm-quick-sidebar--on': isOpen }"
    appunwraptag=""
    style="overflow-y:hidden"
>
    <div class="mf-quick-sidebar-title text-center mb-2">
        <span
            ngbTooltip="Označi vsa kot prebrana"
            id="notificationMarkAsRead"
            class="float-left"
            (click)="markAllAsRead()"
            placement="right"
        >
            <i class="la la-check-circle-o"></i>
        </span>
        <span i18n="@@layouts.quicksidebar.notifications.title.caption">
            Obvestila
        </span>
        <span id="m_quick_sidebar_close" class="mf-quick-sidebar-title_close" (click)="closeSidebar()">
            <i class="la la-close"></i>
        </span>
    </div>
    <div class="m-quick-sidebar__content h-100">
        <perfect-scrollbar [autoPropagation]="true">
            <div class="tab-content">
                <div
                    class="tab-pane active m-scrollable"
                    role="tabpanel"
                    id="m_quick_sidebar_tabs_messenger"
                    role="tabpanel"
                >
                    <div class="m-messenger m-messenger--message-arrow m-messenger--skin-light">
                        <div class="m-list-timeline m-list-timeline--skin-light"></div>

                        <div class="m-messenger__messages">
                            <div
                                *ngFor="let notification of notificationsListSlice; index as i"
                                class="m-messenger__wrapper scrollable-content ml-1"
                                style="width:97%"
                            >
                                <div class="m-messenger__message m-messenger__message--in w-100 mb-2">
                                    <div class="m-messenger__message-content ml-1">
                                        <div class="m-messenger__message-text">
                                            <app-header-notification-element
                                                [index]="i"
                                                [prereservationId]="notification?.prereservationId"
                                                [notificationDate]="notification?.notificationDate"
                                                [title]="notification?.title"
                                                [messages]="notification?.messages"
                                                [name]="notification?.name"
                                                [backgroundColor]="notification?.backgroundColor"
                                                [borderColor]="notification?.borderColor"
                                                [link]="notification?.link"
                                                [data]="notification?.data"
                                                [seen]="notification?.seen"
                                                [dataInFooter]="notification?.dataInFooter"
                                                [redirect]="notification?.redirect"
                                                [notificationId]="notification?.id"
                                            ></app-header-notification-element>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="notificationsList.length === 0" class="ml-3">
                                <span>
                                    Ni obvestil
                                </span>
                            </div>
                        </div>
                    </div>

                    <div
                        class="mf-quick-sidebar-loadmore d-flex w-100 p-2 "
                        *ngIf="sliceIndex < notificationsList.length"
                    >
                        <a
                            href="#"
                            class="ml-auto mr-auto btn btn-outline-brand m-btn btn-sm m-btn--icon m-btn--pill"
                            (click)="loadNext()"
                        >
                            <span>
                                <i class="la la-arrow-down"></i>
                                <span i18n="@@layouts.quicksidebar.notifications.loadmore.button.caption">
                                    Naloži več obvestil
                                </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>
<div *ngIf="isOpen" class="m-quick-sidebar-overlay" (click)="closeSidebar()"></div>
