import { Helpers } from './../../../helpers';
import { AuthenticationService } from './../../../auth/_services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';



@Component({
    selector: 'app-coa-logout',
    templateUrl: './coa-logout.component.html',
    styles: []
  })

export class CoaLogoutComponent implements OnInit {

    constructor(private _router: Router,
        private _authService: AuthenticationService) {
    }

    ngOnInit(): void {
        Helpers.setLoading(true);
        // reset login status
        this._authService.logout();
        this._router.navigate(['/contractor-app/login']);
    }

}







