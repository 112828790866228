<div>
    <p class="text-center">
        <ng-container *ngIf="additionalText">{{ additionalText }}<br /></ng-container>
        Na spodaj prikazani sliki kliknite na črko "<strong class="text-dark">{{ selectChar }}</strong
        >"
    </p>

    <div class="captcha-img-placeholder">
        <div class="m-loader m-loader--brand" *ngIf="!captchaImage"></div>
        <img [src]="captchaImage" *ngIf="captchaImage" (click)="captchaSolver($event)" />
    </div>
</div>
