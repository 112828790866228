<div [style.background-color]="backgroundColor" class="d-flex rounded-right" (click)="messageRead()">
    <div style="border: 4px solid; border-radius: 10px" [style.border-color]="borderColor" *ngIf="!seen"></div>
    <div class="p-2 w-100">
        <span class="m-list-timeline__text">
            <div class="w-100 d-flex justify-content-between">
                <strong [style.color]="borderColor"> {{ title }}</strong>
                <small style="cursor: pointer" (click)="redirectSwitch()" *ngIf="link !== null"
                    >PRIKAZ<i class="la la-angle-right align-bottom"></i
                ></small>
            </div>
            <div>
                <small
                    class="align-top mb-1"
                    style="cursor: pointer;"
                    (click)="copyPreId($event)"
                    title="Kopiraj šifro"
                >
                    <i *ngIf="prereservationId" class="la la-copy"></i>
                    <span
                        ><u>{{ prereservationId }} </u>
                    </span></small
                >
            </div>
            <div
                class="text-dark bg-light p-2 rounded"
                *ngIf="messages !== null && !(messages?.length == 1 && messages[0]?.message?.length == 0)"
            >
                <div *ngFor="let msg of messages">
                    <i [ngClass]="msg.icon" style="font-size: 15px;"></i>
                    <span [ngStyle]="msg.messageStyle" *ngIf="msg.datePipe">
                        {{ msg.message | date: 'dd.MM.yyyy, HH:mm' }}</span
                    >
                    <span [ngStyle]="msg.messageStyle" *ngIf="!msg.datePipe"> {{ msg.message }}</span>
                </div>
            </div>
            <div class="w-100 d-flex justify-content-between mt-3">
                <div>
                    <small [style.color]="borderColor" class="">
                        <div *ngIf="dataInFooter === 'business'" class="mt-1">
                            <i class="la la-hospital-o" style="font-size: 15px; margin-right: 3px;"></i>
                            <small>{{ data.contractorName }}</small>
                        </div>
                        <div *ngIf="dataInFooter === 'customer'" class="mt-1">
                            <i class="la la-user" style="font-size: 15px; margin-right: 3px;"></i>
                            <small>{{ name }}</small>
                        </div>
                    </small>
                </div>
                <div>
                    <small [style.color]="borderColor">{{ notificationDate | date: 'dd.MM.yyyy, HH:mm' }}</small>
                </div>
            </div>
        </span>
    </div>
</div>
